<template>
<div class="container">
    <div class="special-cat-products products-carousel" v-if="loadingdata">

    <!-- Shimmer Loading -->

  <div class="product-list-wrap">
    <div class="product-item">
      <div class="carousel-item-single">
        <card-shimmer  :is-loading="loadingdata"/>
      </div>
     </div>
    <div class="product-item">
      <div class="carousel-item-single">
        <card-shimmer  :is-loading="loadingdata"/>
      </div>
     </div>
    <div class="product-item">
      <div class="carousel-item-single">
        <card-shimmer  :is-loading="loadingdata"/>
      </div>
     </div>
    <div class="product-item">
      <div class="carousel-item-single">
        <card-shimmer  :is-loading="loadingdata"/>
      </div>
     </div>
    <div class="product-item">
      <div class="carousel-item-single">
        <card-shimmer  :is-loading="loadingdata"/>
      </div>
     </div>
    <div class="product-item">
      <div class="carousel-item-single">
        <card-shimmer  :is-loading="loadingdata"/>
      </div>
     </div>
    <div class="product-item">
      <div class="carousel-item-single">
        <card-shimmer  :is-loading="loadingdata"/>
      </div>
     </div>
    <div class="product-item">
      <div class="carousel-item-single">
        <card-shimmer  :is-loading="loadingdata"/>
      </div>
     </div>
    <div class="product-item">
      <div class="carousel-item-single">
        <card-shimmer  :is-loading="loadingdata"/>
      </div>
     </div>
    <div class="product-item">
      <div class="carousel-item-single">
        <card-shimmer  :is-loading="loadingdata"/>
      </div>
     </div>
  </div>


  </div>

  
  <div class="special-cat-products products-carousel">
  <div class="product-list-wrap service-list-wrap">
    <div class="product-item"
     v-for="(Listdata, index) in Listdata.slice(0, 20)"
     :key="index"
     >
      <div class="carousel-item-single">
        <div class="pro-img">
          <router-link class="link" :to="current_path+'/'+Listdata.slug">
          <img :src="base_url+Listdata.image" /></router-link>
        </div>
        <div class="pro-info">
          <router-link class="link" :to="current_path+'/'+Listdata.slug">
          <p class="pro-name">{{Listdata.name}}</p></router-link>
          <div class="bottom-sec">
            <span class="brand-n">{{Listdata.location}}</span><br>
            <div class="rating" v-if="Listdata.rating != null">
              <star-rating 
              :rating="parseFloat(Listdata.rating)" 
              :read-only="true" 
              :increment="0.5" 
              :star-size="13"
              inactive-color="#E1E1E1" 
              active-color="#A5A5A5"
              :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"></star-rating>
              </div>
              <span class="brand-n">{{Listdata.distance}} km</span>
          </div>
        </div>
      </div>
     </div>
  </div>
</div>
  
  <!-- <h1 class="brand-special">{{Listdata.brand}}</h1> -->
    <!-- <div v-for="(Listdata) in Listdata" :key="Listdata.id" class="user">
      {{ Listdata.title }}
  </div> -->
</div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
  import StarRating from 'vue-star-rating'

export default {
  name: 'ProductList',
  props: {
    Listdata: {
        type:[Array, Object],
        required: true
    },
     loadingdata:{

    }
  },
  components: {
      StarRating
  },
  data (){
    return{
        base_url:'https://admin.badmintonsouq.com',
        current_path:''
    }
  },
  mounted(){
      this.current_path = this.$route.fullPath
  }
}
</script>
