<template>
  <div class="login-page">
    <div class="container">
      <div class="login-form pay-response mb-3 mt-3">
              <div class="vect-img">
                <img src=".././assets/2505487-5540.png" alt="" class="img">
              </div>
        <h1 id="success">Order in Process! Please Wait....</h1>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Success',
  data (){

    return{
      temp_order_id:''
    }
  },
  created(){
    this.checkOutOrder();
  },
  methods:{
    checkOutOrder()
    {
      this.token = this.$store.state.auth.token
      this.temp_order_id = this.$route.query.order_id
      axios.post('https://admin.badmintonsouq.com/api/v1/postpay-checkout-order', {temp_order_id:this.temp_order_id},{
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
        if(response.data.data.is_checkout_approved == true){
          window.location.href= '/payment-success';
        }
      })
      .catch(function (error) {
          console.log(error);
      })
    }
  }
}
</script>

<style>
#success{
  color:green;
}
</style>