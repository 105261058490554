<template>
    <div class="list-page-wrap">
        <div class="breadcrumb" style="background: url('https://picsum.photos/id/1035/1920/400')">
            <div class="container">
                <h1 class="title">Privacy Policy</h1>
                <!-- <p class="text">The racket was developed with the new "triple speed system frame" which combines exceptional power and great handling, and overall improve execution with power and more control. Designed with compact frame, which increases the speed in the swing, and overall improve execution with power and more control.</p> -->
            </div>
        </div>
        <div class="container mb-5 mt-5">
            <div class="about">
                <h1>This is a privacy policy page</h1>
            </div>
        </div>
    </div>
</template>

<script>

export default({
  name: 'Privacy Policy',
  data (){

    return{

    }
  }
})
</script>