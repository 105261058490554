<template>
<div class="container">
  <div class="special-cat-products products-carousel service-carousel">

    <!-- Shimmer Skelton -->
    <swiper v-if="loadingdata"
     :slidesPerView="2" 
     :spaceBetween="10" 
     :autoplay='{
        "delay": 2500,
        "disableOnInteraction": false
      }' 
      :navigation="true"
      :breakpoints='{
        "640": {
          "slidesPerView": 2,
          "spaceBetween": 10
        },
        "768": {
          "slidesPerView": 4,
          "spaceBetween": 20
        },
        "1024": {
          "slidesPerView": 5,
          "spaceBetween": 20
        }
      }' class="product-slider"> 
    <swiper-slide>
    <div class="carousel-item-single">
      <card-shimmer :is-loading="loadingdata" />
    </div>
    </swiper-slide>
    <swiper-slide>
    <div class="carousel-item-single">
      <card-shimmer :is-loading="loadingdata" />
    </div>
    </swiper-slide>
    <swiper-slide>
    <div class="carousel-item-single">
      <card-shimmer :is-loading="loadingdata" />
    </div>
    </swiper-slide>
    <swiper-slide>
    <div class="carousel-item-single">
      <card-shimmer :is-loading="loadingdata" />
    </div>
    </swiper-slide>
    <swiper-slide>
    <div class="carousel-item-single">
      <card-shimmer :is-loading="loadingdata" />
    </div>
    </swiper-slide>
  </swiper>
    <!-- End Shimmer Skelton -->

    <swiper
     :slidesPerView="2" 
     :spaceBetween="10" 
     :autoplay='{
        "delay": 2500,
        "disableOnInteraction": false
      }' 
      :navigation="true"
      :breakpoints='{
        "640": {
          "slidesPerView": 2,
          "spaceBetween": 10
        },
        "768": {
          "slidesPerView": 4,
          "spaceBetween": 20
        },
        "1024": {
          "slidesPerView": 5,
          "spaceBetween": 20
        }
      }' class="product-slider"> 
    <swiper-slide 
     v-for="(Listdata, index) in Listdata"
     :key="index"
    >
    <div class="carousel-item-single">
      <div class="pro-img">
          <a :href="$router.resolve({path: Listdata.slug}).href" class="link">
          <img :src="base_url+Listdata.image" /></a>
      </div>
      <div class="pro-info">
          <a :href="$router.resolve({path: Listdata.slug}).href" class="link">
          <p class="pro-name">{{Listdata.name}}</p></a>
          <div class="bottom-sec">
            <span class="brand-n">{{Listdata.location}}</span>
            <div class="rating">
              <star-rating 
              :rating="Listdata.rating" 
              :read-only="true" 
              :increment="0.5" 
              :star-size="13"
              inactive-color="#E1E1E1" 
              active-color="#A5A5A5"
              :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"></star-rating>
              </div>
          </div>
      </div>
    </div>
    </swiper-slide>


    
  </swiper>




  <!-- <swiper
     :slidesPerView="1" 
     :spaceBetween="10" 
     :autoplay='{
        "delay": 2500,
        "disableOnInteraction": false
      }' 
      :navigation="true"
      :breakpoints='{
        "640": {
          "slidesPerView": 2,
          "spaceBetween": 10
        },
        "768": {
          "slidesPerView": 4,
          "spaceBetween": 20
        },
        "1024": {
          "slidesPerView": 5,
          "spaceBetween": 20
        }
      }' class="product-slider"> 
    <swiper-slide 
     v-for="slide in slides"
     :key="slide"
    >
    <div class="carousel-item-single">
      <div class="pro-img">
        <img :src="slide.image" />
        <span class="offer">{{slide.offer}}</span>
      </div>
      <div class="pro-info">
        <span class="brand-n">{{slide.brand}}</span>
        <p class="pro-name">{{slide.title}}</p>
        <div class="rating">
          <star-rating 
          :rating="slide.rating" 
          :read-only="true" 
          :increment="0.5" 
          :star-size="15"
          inactive-color="#E1E1E1" 
          active-color="#A5A5A5"
          :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"></star-rating>
          </div>
        <div class="price">
          <p class="old-p">{{slide.oldprice}}</p>
          <p class="new-p">{{slide.price}}</p>
        </div>
        <button class="btn add-to-cart"> <i class='bx bxs-cart-alt'></i> Add to Cart</button>
      </div>
    </div>
    </swiper-slide>


    
  </swiper> -->
  </div>
  
  <!-- <h1 class="brand-special">{{Listdata.brand}}</h1> -->
    <!-- <div v-for="(Listdata) in Listdata" :key="Listdata.id" class="user">
      {{ Listdata.title }}
  </div> -->
</div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import StarRating from 'vue-star-rating'
   // import styles bundle
  import 'swiper/swiper-bundle.min.css';
  // Import Swiper styles
  // import 'swiper/swiper.min.css';
  
// import Swiper core and required modules
import SwiperCore, {
  Autoplay,Navigation
} from 'swiper';

// install Swiper modules
SwiperCore.use([Autoplay,Navigation]);

export default {
  name: 'ProductCarousel',
  props: {
    // users: {
    //   type: Array,
    //   required: true,
    // },
    Listdata: {
        type:[Array, Object],
        required: true
    },
    loadingdata:{

    }
    // index: Number,
    // brand: String,
    // title: String,
    // price: String,
    // oldprice: String,
    // image: String,
    // rating: String,
    // stock: String
  },
  components: {
    // HelloWorld
      Swiper,
      SwiperSlide,
      StarRating
  },
  data (){
    return{
      base_url:'https://admin.badmintonsouq.com',
      service_slug:''
    }
  },
  mounted(){
    this.url_slug      = this.$route.fullPath.split("/");
    this.service_slug  = this.url_slug[1]
  },
}
</script>
