<template>
  <div class="list-page-wrap organize-page-wrap">
    <!-- <h1>This is an ProductList</h1> -->
    <div class="breadcrumb" style="background: url('https://picsum.photos/id/1035/1920/400')">
      <div class="container">
        <h1 class="title">Tournament Organizers</h1>
        <!-- <p class="text">The racket was developed with the new "triple speed system frame" which combines exceptional power and great handling, and overall improve execution with power and more control. Designed with compact frame, which increases the speed in the swing, and overall improve execution with power and more control.</p> -->
      </div>
    </div>
    <div class="container">
      <div class="sub-categories">
        <div class="sub-item">
          <div class="img-wrap">
            <img src="https://picsum.photos/id/1024/1920/500" alt="" class="sub-img">
            <div class="content">
              <h3 class="title">Planing for a tournament Reach Us/ Click Here Our Experts can assist you...</h3>
              <a href="/contact" class="link-btn">Contact Us <i class="bx bx-right-arrow-alt"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  <div class="shop-departments">
    <div class="container">
      <div class="top-sec">
        <h3 class="title">Shop by departments.</h3>
      </div>
      
      <ul class="services-categories">
        <li class="item" v-for="organizer in organizers" :key="organizer.id">
          <div class="img-box">
              <img :src="base_url+organizer.image" alt="">
              <div class="content">
                <a :href="$router.resolve({path: '/category/trophies'}).href" class="link" v-if="organizer.name == 'Trophies/Awards'">
                </a>
                <p class="cname">{{organizer.name}}</p>
                <a href="#" data-bs-toggle="modal" data-bs-target="#OrganizeModal" class="link-btn" @click.prevent="sendData(organizer.id)"><span v-if="organizer.name == 'Trophies/Awards'">Shop Now</span><span v-else>Contact Us </span><i class="bx bx-right-arrow-alt"></i></a>
              </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
    <div class="brand-carousel">
      <!--<brandsCarousel :Listdata="brands"/>-->
    </div>
  </div>
  
  <!-- Modal -->

<div class="modal fade auth-modal organize-modal" id="OrganizeModal" tabindex="-1" aria-labelledby="OrganizeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="AuthModalLabel">Enter your requirements</h5>
        <button type="button" class="btn-close" id="OrganizeModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
            <div class="login-form">
              <form @submit.prevent="submitForm">
                <p id="error">{{error_message}}</p>
                <div class="row form-group" v-if="token == null">
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Name" v-model="form.name">
                  </div>
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Phone" v-model="form.phone">
                  </div>
                </div>
                <div class="mb-3 icon-input" v-if="token == null">
                  <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Email" v-model="form.email">
                </div>
                <div class="mb-3 icon-input">
                  <GMapAutocomplete
                  placeholder="Type your location"
                  @place_changed="setPlace"
                  class="form-control"
                  :value = "form.location"
                  :options="{
                      componentRestrictions: {'country': ['ae']}
                  }"
                  >
                  </GMapAutocomplete>
                  <i class='icon bx bx-current-location'></i>
                </div>
                <div class="mb-3 icon-input">
                  <!-- <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Date"> -->
                    <v-date-picker v-model="form.date">
                      <template v-slot="{ inputValue, togglePopover }">
                        <input
                          class="form-control"
                          placeholder="Date"
                          :value="inputValue"
                            @click="togglePopover"
                            readonly
                        />
                      </template>
                    </v-date-picker>
                  <i class='icon bx bx-calendar' ></i>
                </div>
                <div class="row form-group" v-if="form.organizer_id == 1">
                  <div class="mb-3 icon-input col-md-6">
                    <!-- <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Time"> -->
                    
                      <v-date-picker mode="time" v-model="form.time" :timezone="timezone">
                        <template v-slot="{ inputValue, togglePopover }">
                          <input
                            class="form-control"
                            placeholder="Time"
                            :value="inputValue"
                             @click="togglePopover"
                             readonly
                          />
                        </template>
                      </v-date-picker>

                    <!-- <v-date-picker mode="time" v-model="date" :timezone="timezone" /> -->
                    <i class='icon bx bx-time-five' ></i>
                  </div>
                  <div class="mb-3 col-md-6">
                    <input type="number" class="form-control" id="exampleFormControlInput1" placeholder="Quantity" v-model="form.quantity">
                  </div>
                </div>
                <div class="mb-3">
                  <textarea class="form-control" id="exampleFormControlTextarea1" v-if="form.organizer_id == 1" v-model="form.comments" placeholder="comments" rows="4"></textarea>
                  <textarea class="form-control" id="exampleFormControlTextarea1" v-else v-model="form.requirement" placeholder="your requirements" rows="4"></textarea>
                </div>
                <!--<a href="#" data-bs-toggle="modal" data-bs-target="#SuccessModal">-->
                <button class="btn btn-primary login-btn">
                  <span v-if="!showLoad">
                    Submit
                    <i class="bx bx-right-arrow-alt"></i>
                  </span>
                  <div v-if="showLoad" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
                <!--</a>-->
              </form>
            </div>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>



<!-- Success Modal -->
<div class="modal fade auth-modal success-modal" id="SuccessModal" tabindex="-1" aria-labelledby="OrganizeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body">
            <div class="message-content">
              <img class="vector" src="../assets/success.png" alt="">
              <p class="text">Thanks for contacting us, our expert team will contact you soon. </p>
              <a href="#"   data-bs-dismiss="modal" aria-label="Close" class="btn btn-primary login-btn">OK <i class="bx bx-right-arrow-alt"></i></a>
            </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
// import brandsCarousel from '@/components/brandsCarousel.vue'
import axios from 'axios'

export default({
  name: 'OrganizeList',
  components: {
      // brandsCarousel,
  },
  data (){

    return{
      showLoad:false,
      brands :[],
      organizers:[],
      base_url:'https://admin.badmintonsouq.com',
      form:{
        name:'',
        phone:'',
        email:'',
        user_id:'',
        date:'',
        time:new Date(),
        quantity:'',
        comments:'',
        organizer_id:'',
        requirement:'',
        location:''
      },
      token:'',
      error_message:''
    }
  },
  mounted(){
    this.token    = this.$store.state.auth.token
    if(this.token){
      this.form.user_id = this.$store.state.auth.user.user_id
    }
  },
  created(){
    // this.getBrands();
    this.getOrganizers();
    this.currentLocation();
  },
  methods: {
    currentLocation(){
      if(this.$store.state.auth.address == null)
      {
        this.latitude = this.$store.state.auth.latitude
        this.longitude = this.$store.state.auth.longitude
        axios.get(
            "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
              this.latitude +
              "," +
              this.longitude +
              "&key=AIzaSyC9K84fNw6iBAmoUa3OD_w4YOqDRNx7Tvo"
          )
          .then((response) => {
            this.form.location = response.data.results[0].formatted_address
          })
          .catch(function (error) {
            console.log(error);
        })
      }
      else{
        this.form.location = this.$store.state.auth.address
      }
    },
    // getBrands(){
    //   axios.post('https://admin.badmintonsouq.com/api/v1/get-partners', {})
    //   .then((response) => {
    //       this.brands = response.data.data.partners
    //   })
    //   .catch(function (error) {
    //       console.log(error);
    //   })
    // },
    getOrganizers(){
      axios.post('https://admin.badmintonsouq.com/api/v1/get-tournament-organizers', {})
      .then((response) => {
          this.organizers = response.data.data.organizers
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    sendData(organizer_id){
      this.form.organizer_id = organizer_id
    },
    submitForm(){
      this.showLoad = true
      axios.post('https://admin.badmintonsouq.com/api/v1/create-tournament-enquiry', this.form)
      .then((response) => {
          this.showLoad = false
          if(response.data.success == false){
            this.error_message = response.data.message
          }else{
            this.$swal.fire(response.data.message,'',"success")
            .then(() => {
              document.getElementById("OrganizeModalClose").click();
              this.form.name = ''
              this.form.phone = ''
              this.form.email = ''
              this.form.date = ''
              this.form.time = new Date()
              this.form.quantity = ''
              this.form.comments = ''
              this.form.oragnizer_id = ''
              this.form.requirement = ''
            })
          }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    setPlace(place)
    {
      this.form.location  = place.formatted_address
    }
  }
})
</script>

<style>
#error{
  color:red;
}
</style>