<template>
  <div class="deatil-page-wrap account-page-wrap">
    <!-- <h1>This is an ProductList</h1> -->
    <div class="breadcrumb">
      <div class="container">
        <div class="left-sec">
          <a href="#" @click.prevent="this.$router.go(-1)" class="back-link"><i class='bx bx-left-arrow-alt' ></i></a>
          <h1 class="title">My Account</h1>
        </div>
        <ul class="links">
          <li class="link"><a href="/">Home</a></li>
          <li class="link">/ Account</li>
        </ul>
      </div>
    </div>
    <!-- <div class="account-basic-info">
      <div class="container">
      </div>
    </div> -->
    <div class="account-detail-sec">
      <div class="container">
      <div class="account-overview-details">
        <div class="row">
        <div class="col-lg-3">
          <div class="account-basic-info">
        <div class="profile-card">
          <div class="img-wrap">
            <img src="../assets/men.svg" v-if="user.profile_picture == null" alt="" class="pro-img">
            <img :src="user.profile_picture" v-else alt="" class="pro-img">
          </div>
          <div class="info">
            <div style="display:flex;">
              <free-style-shimmer
                :is-loading="showLoading"
                height="30px"
                width="100px"
                border-radius="0"
                color="#bdbdbd"
              />
              <h4 class="name" style="margin-right:10px;">{{user.name}}</h4>
              <a href="#" @click.prevent="userData" class="link" data-bs-toggle="modal" data-bs-target="#profileModal">
                <img src="../assets/edit-icon.svg" style="width:20px;" alt="">
              </a>
            </div>
            <paragraph-shimmer :lines="2" :is-loading="showLoading"/>
            <p class="email">{{user.email}}</p>
            <p class="phone">{{user.phone}}</p>
          </div>
        </div> </div>
          <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">User Information</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Orders</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="pills-return-tab" data-bs-toggle="pill" data-bs-target="#pills-return" type="button" role="tab" aria-controls="pills-return" aria-selected="false">Return Orders</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="pills-stringing-tab" data-bs-toggle="pill" data-bs-target="#pills-stringing" type="button" role="tab" aria-controls="pills-stringing" aria-selected="false">Stringings</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Address Book</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" @click.prevent="logout" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Logout</button>
            </li>
          </ul>
        </div>
        <div class="col-lg-9">
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
            <div class="description">
              <!--<p class="text">Superior badminton rackets that deliver lots of power on the badminton court!</p>
              <h4 class="title">Play like the pros - Forza Aero Power 1088-S</h4>
              <p class="text light-color">Do you want to play like Mia Blichfeldt? This is the badminton racket that Mia Blichfeldt dominates the badminton court with! Although it's Mia Blichfeldt's racket, it can be used by both men and women players!
<br>
This badminton racket delivers plenty of power without compromising on control and feel. The racket has a combination of a stiff flexibility, even balance and weighs88 grams. Furthermore, this racket is perfect for the exerciser, practiced and the tournament players.
<br>
This badminton racket is made in Taiwan and Forza has incorporated their best materials and technologies in this racket:
<br>
Kevlar ® is an advanced material that is strong and light. Kevlar ®  is used in both the racket's frame and shaft, where it's combined with graphite. This imrpoves the rackets lightness, stregnth and 'powerfulness'.
<br>
Carbon Nano Tubes (CNT) is the technology that improves the durability of the racket, thus making it extremely durable.

</p> -->
<table class="table spec-table table-striped">
  <tbody>
    <tr>
      <td style="white-space: nowrap"><i class='bx bxs-user me-1'></i> Full name
      <h3 class="lrg-value">{{user.name}}</h3> 
      
              <free-style-shimmer
                :is-loading="showLoading"
                height="20px"
                width="250px"
                border-radius="0"
                color="#bdbdbd"
              />
      </td>
    </tr>
    <tr>
      <td style="white-space: nowrap"><i class='bx bxs-envelope me-1' ></i> Email Address
        <h3 class="lrg-value">{{user.email}}</h3>
              <free-style-shimmer
                :is-loading="showLoading"
                height="20px"
                width="350px"
                border-radius="0"
                color="#bdbdbd"
              />
      </td>
    </tr>
    <tr>
      <td style="white-space: nowrap"><i class='bx bxs-mobile me-1' ></i> Mobile Number
        <h3 class="lrg-value">{{user.phone}}</h3>
              <free-style-shimmer
                :is-loading="showLoading"
                height="20px"
                width="200px"
                border-radius="0"
                color="#bdbdbd"
              />
      </td>
    </tr>
    <tr>
      <td style="white-space: nowrap"><i class='bx bxs-map me-1' ></i> Location
        <h3 class="lrg-value">{{location}}</h3>
              <free-style-shimmer
                :is-loading="showLoading"
                height="20px"
                width="400px"
                border-radius="0"
                color="#bdbdbd"
              />
      </td>
    </tr>
  </tbody>
</table>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <div class="cart-list-sec order-list-wrap" v-if="isHidden">
              <div class="products-list top-sec">
                <div class="total-price id-col">
                  <p class="text"> Order ID</p>
                </div>
                <div class="total-price addrss-col">
                  <p class="text"> 	Address</p>
                </div>
                <!--<div class="total-price type-col">
                  <p class="text"> Payment Type</p>
                </div>-->
                <div class="total-price total-col">
                  <p class="text"> Total</p>
                </div>
                <div class="total-price status-col">
                  <p class="text"> Status</p>
                </div>
                <div class="total-price action-col">
                  <p class="text"> Actions</p>
                </div>
              </div>
              <div class="products-list" v-for="order in orders" :key="order.id">
                <div class="total-price id-col">
                  <p class="total">{{order.invoice_no}}</p>
                </div>
                <div class="total-price addrss-col">
                  <p class="total">{{order.user_address.contact_name}}<br>
                  {{order.user_address.house_name}} - {{order.user_address.landmark}} - {{order.user_address.city}} - {{order.user_address.state}}<br>
                  {{order.user_address.mobile_number}}<br>
                  {{((order.user_address.pincode != "null") ? 'PO Box - '+order.user_address.pincode : '')}}
                  </p>
                </div>
                <!--<div class="total-price type-col">
                  <p class="total">{{order.payment_option.title}}</p>
                </div>-->
                <div class="total-price total-col">
                  <p class="total">AED {{order.grand_total}}</p>
                </div>
                <div class="total-price status-col">
                  <p class="total pending" v-if="order.order_status_id == 1">{{order.status.title}}</p>
                  <p class="total delivered" v-if="order.order_status_id == 2 || order.order_status_id == 3">{{order.status.title}}</p>
                  <p class="total cancelled" v-if="order.order_status_id == 4 || order.order_status_id == 5">{{order.status.title}}</p>
                  <!-- pending -->
                  <!-- cancelled -->
                  <!-- According to status you can change the classes -->
                </div>
                <div class="total-price action-col">
                <a @click="showDetails(order)" class="link-btn"><i class='icon bx bxs-show' ></i>Order Details</a>
                </div>
              </div>
              <div class="container mb-5" v-if="total>1 && !filter">
                <v-pagination
                  v-model="fromPage"
                  :pages="total"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="getUser"
                />
              </div>
            </div>
            <!-- <orderList :Listdata="orders" :is="currentComponent" :swap-component="swapComponent"/> -->
            <!-- <orderDetail  :Listdata="orders"/> -->
            <div class="order-detail-wrap" v-if="!isHidden">
              <div class="top-sec">
                <div class="left-sec">
                  <a @click.prevent="isHidden=true" class="back-link"><i class="bx bx-left-arrow-alt"></i></a>
                  <h3 class="title">Order Details</h3>
                </div>
                <a href="#" @click.prevent="isHidden=true" class="link-btn">Back to Order List</a>
              </div>
              <!-- <div v-for="order in orders" :key="order.id"> -->
              <div class="card m-b-30">
                  <div class="card-header">                                
                      <div class="row align-items-center">
                          <div class="col-7">
                              <h5 class="card-title mb-0">Order No : {{selectedOrder.invoice_no}}</h5>
                          </div>
                          <div class="col-5 text-right">
                              <span class="badge delivered badge-success-inverse">{{selectedOrder.status.title}}</span>                                             
                          </div>
                      </div>
                  </div>
                  <div class="card-body">                                
                      <div class="row mb-3 border-bottom">
                          <div class="col-md-6 col-lg-6 col-xl-3">
                              <div class="order-primary-detail mb-4">
                              <h6>Ordered Date & Time</h6>
                              <p class="mb-0">
                              {{"" + new Date(selectedOrder.created_at).getFullYear() +'-'+ ((new Date(selectedOrder.created_at).getMonth() + 1) > 9 ? '' : '0') + (new Date(selectedOrder.created_at).getMonth() + 1) +'-'+ (new Date(selectedOrder.created_at).getDate() > 9 ? '' : '0') + new Date(selectedOrder.created_at).getDate()}}
                              </p>
                              </div>
                          </div>
                          <div class="col-md-6 col-lg-6 col-xl-3">
                              <div class="order-primary-detail mb-4">
                              <h6>Name</h6>
                              <p class="mb-0">{{selectedOrder.user.name}}</p>
                              </div>
                          </div>
                          <div class="col-md-6 col-lg-6 col-xl-3">
                              <div class="order-primary-detail mb-4">
                              <h6>Email ID</h6>
                              <p class="mb-0">{{selectedOrder.user.email}}</p>
                              </div>
                          </div>
                          <div class="col-md-6 col-lg-6 col-xl-3">
                              <div class="order-primary-detail mb-4">
                              <h6>Contact No</h6>
                              <p class="mb-0">{{selectedOrder.user.phone}}</p>
                              </div>
                          </div>
                      </div> 
                      <div class="row">
                          <div class="col-md-6 col-lg-8 col-xl-8">
                              <div class="order-primary-detail mb-4">
                              <h6>Delivery Address </h6>
                              <p class="total">{{selectedOrder.user_address.contact_name}}<br>
                              {{selectedOrder.user_address.house_name}} - {{selectedOrder.user_address.landmark}} - {{selectedOrder.user_address.city}} - {{selectedOrder.user_address.state}}<br>
                              {{selectedOrder.user_address.mobile_number}}<br>
                              {{((selectedOrder.user_address.pincode != "null") ? 'PO Box - '+selectedOrder.user_address.pincode : '')}}
                              </p>
                              </div>
                          </div>
                          <div class="col-md-6 col-lg-4 col-xl-4 ">
                              <div class="order-primary-detail mb-4">
                              <h6>Payment Type</h6>
                              <p>{{selectedOrder.payment_option.title}}</p>
                              </div>
                              <div class="order-primary-detail mb-4">
                                <h6>Payment ID</h6>
                                <p>{{selectedOrder.payment_id}}</p>
                              </div>
                          </div>
                      </div>                                
                  </div>
              </div> 
              <br>
              <div class="card m-b-30">
                  <div class="card-header">
                      <h5 class="card-title">Order Items</h5>
                  </div>
                  <div class="card-body">
                      <div class="table-responsive ">
                          <table class="table table-borderless">
                              <thead>
                                  <tr>
                                      <th scope="col">#</th>
                                      <th scope="col">Product</th>
                                      <th scope="col">Qty</th>
                                      <th scope="col">Price</th>
                                      <th scope="col">Label</th>
                                      <th scope="col">Label Charge</th>
                                      <th scope="col">Stringing Charge</th>
                                      <th scope="col" v-if="selectedOrder.order_status_id == 3 && return_applicable == true">Action</th>
                                      <th scope="col" class="text-right">Total</th>
                                  </tr>
                              </thead>
                              <tbody>
                                      <tr v-for="product in selectedOrder.products" :key="product.id">
                                          <th scope="row">1</th>
                                          <td v-if="product.color_id && product.size_id">{{product.item.title}} <br>
                                            <div :style="{ 'background-color': product.color.color, 'width' : '20px','height' : '20px'} "></div>
                                            {{product.size.size}}<br> 
                                          </td>
                                          <td v-else-if="product.color_id">{{product.item.title}} <br> 
                                            <div :style="{ 'background-color': product.color.color, 'width' : '20px','height' : '20px'} "></div>
                                          </td>
                                          <td v-else-if="product.size_id">{{product.item.title}} <br> {{product.size.size}}</td>
                                          <td v-else>{{product.item.title}}</td>
                                          <td>{{product.quantity}}</td>
                                          <td>AED {{product.cost}}</td>
                                          <td>{{product.addon_label}}</td>
                                          <td>AED {{product.addon_charge}}</td>
                                          <td>AED {{product.stringing_charge}}</td>
                                          <td v-if="selectedOrder.order_status_id == 3 && return_applicable == true">
                                            <button class="btn btn-primary" v-if="product.is_returned == false" @click="chooseItem(product)" data-bs-toggle="modal" data-bs-target="#ReturnModal">Return Product</button>
                                            <p v-else style="color:red;">Product Returned</p>
                                          </td>
                                          <td class="text-right">AED {{product.total_cost}}</td>
                                      </tr>
                              </tbody>
                          </table>
                      </div>
                      <div class="row border-top pt-3">
                          <div class="col-md-12 order-1 order-lg-2 col-lg-8 col-xl-6"></div>
                          <div class="col-md-12 order-1 order-lg-2 col-lg-8 col-xl-6">
                              <div class="order-total table-responsive ">
                                  <table class="table table-borderless text-right">
                                      <tbody>
                                          <tr>
                                              <td>Sub Total :</td>
                                              <td>AED {{selectedOrder.total_cost}}</td>
                                          </tr>
                                          <tr>
                                              <td>Shipping Cost:</td>
                                              <td>AED {{selectedOrder.shipping_cost}}</td>
                                          </tr>
                                          <tr>
                                              <td>VAT(5%) :</td>
                                              <td>{{selectedOrder.tax}}</td>
                                          </tr>
                                          <tr v-if="selectedOrder.coupon_discount_amount != 0">
                                            <td>Coupon Discount</td>
                                            <td>AED -{{selectedOrder.coupon_discount_amount}}</td>
                                          </tr>

                                          <tr v-if="selectedOrder.processing_fee != 0">
                                            <td>Processing Fee</td>
                                            <td>AED {{selectedOrder.processing_fee}}</td>
                                          </tr>

                                          <tr>
                                              <td class="text-black f-w-7 font-18">Amount :</td>
                                              <td class="text-black f-w-7 font-18 color-primary">AED {{selectedOrder.grand_total}}</td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="card m-b-30" v-if="selectedOrder.order_status_id == 1">
                    <div class="card-header">
                      <div class="row align-items-center">
                        <div class="col-7">
                            <p class="card-title">Do you want to cancel this order...?<br>Please read our <a href="http://www.badmintonsouq.com/cancellation-policy">cancellation policy</a></p>
                        </div>
                        <div class="col-5 text-right">
                            <button class="btn btn-primary login-btn" @click="chooseOrder(selectedOrder.id)" data-bs-toggle="modal" data-bs-target="#CancelModal"> 
                              <span>
                                Cancel Order <i class="bx bx-right-arrow-alt"></i>
                              </span>
                            </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br>
              </div>
              <!-- </div> -->
            </div>
          </div>
          <div class="tab-pane fade" id="pills-stringing" role="tabpanel" aria-labelledby="pills-stringing-tab">
            <div class="cart-list-sec order-list-wrap" v-if="isHidden2">
              <div class="products-list top-sec">
                <div class="total-price id-col">
                  <p class="text"> Stringing No.</p>
                </div>
                <div class="total-price addrss-col">
                  <p class="text"> 	Address</p>
                </div>
                <div class="total-price type-col">
                  <p class="text"> Payment Option</p>
                </div>
                <div class="total-price total-col">
                  <p class="text"> Total</p>
                </div>
                <!--<div class="total-price status-col">
                  <p class="text"> Order Status</p>
                </div>-->
                <div class="total-price action-col">
                  <p class="text"> Actions</p>
                </div>
              </div>
              <div class="products-list" v-for="order in stringings" :key="order.id">
                <div class="total-price id-col">
                  <p class="total">{{order.stringing_no}}</p>
                </div>
                <div class="total-price addrss-col">
                  <p class="total">{{order.user_address.contact_name}}<br>
                  {{order.user_address.house_name}} - {{order.user_address.landmark}} - {{order.user_address.city}} - {{order.user_address.state}}<br>
                  {{order.user_address.mobile_number}}<br>
                  {{((order.user_address.pincode != "null") ? 'PO Box - '+order.user_address.pincode : '')}}
                  </p>
                </div>
                <div class="total-price type-col">
                  <p class="total">{{order.payment_option.title}}</p>
                </div>
                <div class="total-price total-col">
                  <p class="total">AED {{order.total_cost}}</p>
                </div>
                <!--<div class="total-price status-col">
                  <p class="total delivered">{{order.status.title}}</p>-->
                  <!-- pending -->
                  <!-- cancelled -->
                  <!-- According to status you can change the classes -->
                <!--</div>-->
                <div class="total-price action-col">
                <a @click.prevent="isHidden2=false; showStringingDetails(order)" class="link-btn"><i class='icon bx bxs-show' ></i>Stringing Details</a>
                </div>
              </div>
              <div class="container mb-5" v-if="total2>1 && !filter">
                <v-pagination
                  v-model="fromPage2"
                  :pages="total2"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="getStringings"
                />
              </div>
            </div>
            <!-- <orderList :Listdata="orders" :is="currentComponent" :swap-component="swapComponent"/> -->
            <!-- <orderDetail  :Listdata="orders"/> -->
            <div class="order-detail-wrap" v-if="!isHidden2">
              <div class="top-sec">
                <div class="left-sec">
                  <a @click.prevent="isHidden2=true" class="back-link"><i class="bx bx-left-arrow-alt"></i></a>
                  <h3 class="title">Stringing Details</h3>
                </div>
                <a href="#" @click.prevent="isHidden2=true" class="link-btn">Back to Stringing List</a>
              </div>
              <!-- <div v-for="order in orders" :key="order.id"> -->
              <div class="card m-b-30">
                  <div class="card-header">                                
                      <div class="row align-items-center">
                          <div class="col-7">
                              <h5 class="card-title mb-0">Stringing No : {{selectedStringing.stringing_no}}</h5>
                          </div>
                          <div class="col-5 text-right">
                              <span class="badge delivered badge-success-inverse">{{selectedStringing.stringing_status.name}}</span>                                             
                          </div>
                      </div>
                  </div>
                  <div class="card-body">                                
                      <div class="row mb-3 border-bottom">
                          <div class="col-md-6 col-lg-6 col-xl-3">
                              <div class="order-primary-detail mb-4">
                              <h6>Ordered Date & Time</h6>
                              <p class="mb-0">
                              {{"" + new Date(selectedStringing.created_at).getFullYear() +'-'+ ((new Date(selectedStringing.created_at).getMonth() + 1) > 9 ? '' : '0') + (new Date(selectedStringing.created_at).getMonth() + 1) +'-'+ (new Date(selectedStringing.created_at).getDate() > 9 ? '' : '0') + new Date(selectedStringing.created_at).getDate()}}</p>
                              </div>
                          </div>
                          <div class="col-md-6 col-lg-6 col-xl-3">
                              <div class="order-primary-detail mb-4">
                              <h6>Name</h6>
                              <p class="mb-0">{{selectedStringing.user.name}}</p>
                              </div>
                          </div>
                          <div class="col-md-6 col-lg-6 col-xl-3">
                              <div class="order-primary-detail mb-4">
                              <h6>Email ID</h6>
                              <p class="mb-0">{{selectedStringing.user.email}}</p>
                              </div>
                          </div>
                          <div class="col-md-6 col-lg-6 col-xl-3">
                              <div class="order-primary-detail mb-4">
                              <h6>Contact No</h6>
                              <p class="mb-0">{{selectedStringing.user.phone}}</p>
                              </div>
                          </div>
                      </div> 
                      <div class="row">
                          <div class="col-md-6 col-lg-8 col-xl-8">
                              <div class="order-primary-detail mb-4">
                              <h6>Delivery Address </h6>
                              <p class="total">{{selectedStringing.user_address.contact_name}}<br>
                              {{selectedStringing.user_address.house_name}} - {{selectedStringing.user_address.landmark}} - {{selectedStringing.user_address.city}} - {{selectedStringing.user_address.state}}<br>
                              {{selectedStringing.user_address.mobile_number}}<br>
                              {{((selectedStringing.user_address.pincode != "null") ? 'PO Box - '+selectedStringing.user_address.pincode : '')}}
                              </p>
                              </div>
                          </div>
                          <div class="col-md-6 col-lg-4 col-xl-4 ">
                              <div class="order-primary-detail mb-4">
                              <h6>Payment Type</h6>
                              <p>{{selectedStringing.payment_option.title}}</p>
                              </div>
                              <div class="order-primary-detail mb-4">
                                <h6>Payment ID</h6>
                                <p>{{selectedStringing.payment_id}}</p>
                              </div>
                          </div>
                          <div class="col-md-6 col-lg-4 col-xl-4 ">
                              <div class="order-primary-detail mb-4">
                              <h6>Additional Requirements</h6>
                              <p>{{comments}}</p>
                              </div>
                          </div>
                      </div>                                
                  </div>
              </div> 
              <br>
              <div class="card m-b-30">
                  <div class="card-header">
                      <h5 class="card-title">Stringing Items</h5>
                  </div>
                  <div class="card-body">
                      <div class="table-responsive ">
                          <table class="table table-borderless">
                              <thead>
                                  <tr>
                                      <th scope="col">Product</th>
                                      <th scope="col" v-if="selectedStringing.brand_id != null">Brand</th>
                                      <th scope="col">Color</th>
                                      <th scope="col">Tension</th>
                                      <th scope="col" class="text-right">Price</th>
                                  </tr>
                              </thead>
                              <tbody>
                                      <tr>
                                          <td>{{selectedStringing.item.title}}</td>
                                          <td v-if="selectedStringing.brand_id != null">{{selectedStringing.brand.title}}</td>
                                          <td v-if="selectedStringing.color_id != 0 && selectedStringing.color_id != null">
                                            <div :style="{ 'background-color': selectedStringing.color.color, 'width' : '20px','height' : '20px'} "></div>
                                          </td>
                                          <td v-else></td>
                                          <td v-if="selectedStringing.tension_id != 0 && selectedStringing.tension_id != null">{{selectedStringing.tension.name}}</td>
                                          <td v-else></td>
                                          <td class="text-right">AED {{selectedStringing.item_cost}}</td>
                                      </tr>
                              </tbody>
                          </table>
                      </div>
                      <div class="row border-top pt-3">
                          <div class="col-md-12 order-1 order-lg-2 col-lg-8 col-xl-6"></div>
                          <div class="col-md-12 order-1 order-lg-2 col-lg-8 col-xl-6">
                              <div class="order-total table-responsive ">
                                  <table class="table table-borderless text-right">
                                      <tbody>
                                          <tr>
                                              <td>Sub Total :</td>
                                              <td>AED {{selectedStringing.item_cost}}</td>
                                          </tr>
                                          <tr>
                                              <td>Stringing Cost:</td>
                                              <td>AED {{selectedStringing.stringing_cost}}</td>
                                          </tr>
                                          <tr>
                                              <td>VAT(5%) :</td>
                                              <td>{{parseFloat(selectedStringing.item_cost*5/100).toFixed(2)}}</td>
                                          </tr>
                                          <tr>
                                              <td class="text-black f-w-7 font-18">Amount :</td>
                                              <td class="text-black f-w-7 font-18 color-primary">AED {{selectedStringing.total_cost}}</td>
                                          </tr>
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <!-- </div> -->
            </div>
          </div>
          <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
            <div class="address-list address-list-account">
              <div class="top-sec">
                <h4 class="title">Address Book ({{addressList.length}})</h4>
                <a href="#" class="link-btn"  data-bs-toggle="modal" data-bs-target="#AddressModal" >Add New Address</a>
              </div>
              <ul class="lists">
                <li class="addrs-item" v-for="address in addressList" :key="address.id">
                <label class="form-check-label" for="flexRadioDefault2">
                  <p class="name">{{address.contact_name}}</p>
                  <p class="text">{{address.house_name}} - {{address.landmark}} - {{address.city}} - {{address.state}}</p>
                  <p class="mobile">{{((address.pincode != "null") ? 'PO Box - '+address.pincode : '')}}</p>
                  <p class="mobile">{{address.mobile_number}}</p>
                  <div class="actions">
                    <a @click="editAddress(address)" data-bs-toggle="modal" data-bs-target="#editAddressModal" class="link-btn edit-btn"><i class='icon bx bxs-edit-alt' ></i>Edit Address</a>&nbsp;&nbsp;
                    <a @click="deleteAddress(address.id)" class="link-btn delete-btn"><i class='icon bx bxs-trash' ></i> Delete Address</a>
                  </div>
                </label>
                </li>
              </ul>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-return" role="tabpanel" aria-labelledby="pills-return-tab">
            <div class="address-list address-list-account">
              <div class="card m-b-30">
                <div class="card-header">
                    <h5 class="card-title">Return Orders ({{return_orders.length}})</h5>
                </div>
                <div class="card-body" v-for="order in return_orders" :key="order.id">
                    <div>
                      Invoice No. <b>{{order.order.invoice_no}}</b>
                      <span class="text-right" style="float:right;">Order Status - <span style="color:green;">{{order.status.title}}</span></span>
                    </div>
                    <div>
                      Return Comments -  {{order.comments}}
                    </div>
                    <div class="card m-b-30">
                      <div class="card-body">
                        <div class="products-list">
                          <div class="product-item">
                            <div class="product-img" v-if="order.variant != null">
                              <img :src="order.variant.image" alt="" width="50" class="image">
                            </div>
                            <div class="product-img" v-else>
                              <img :src="order.item.image" alt="" width="50" class="image">
                            </div>
                            <div class="product-info">
                              <p class="pname">{{order.item.title}}</p>
                              <div style="display:flex;" v-if="order.color != null" >Color - &nbsp;<div :style="{ 'background-color': order.color.color, 'width' : '20px','height' : '20px','border':'1px solid black'} "></div></div>
                              <p class="pname" v-if="order.size != null">Size - {{order.size.size}}</p>
                              <p class="pname">AED {{order.cost}}<br>
                              Qty - {{order.quantity}}</p>
                            </div>
                            <div>
                              <h5 class="text-right" style="float:right;"><b>AED - {{order.total_cost}}</b></h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br>
                    <div>
                      <p class="text">Pickup Address -  {{order.order.user_address.house_name}}, {{order.order.user_address.landmark}}, {{order.order.user_address.city}}, {{order.order.user_address.state}}, {{((order.order.user_address.pincode != "null") ? 'PO Box - '+order.order.user_address.pincode+', ' : '')}}{{order.order.user_address.mobile_number}}</p>
                    </div>
                    <hr>
                </div>
                <div class="container mb-5" v-if="total3>1 && !filter">
                  <v-pagination
                    v-model="fromPage3"
                    :pages="total3"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="getReturnOrders"
                  />
                </div>
              </div>
              <br>
            </div>
          </div>
        </div>
        </div>
        </div>
      </div>
      </div>
    </div>
  </div>
  
 <!-- Add New Address Modal -->

<div class="modal fade auth-modal organize-modal" id="AddressModal" tabindex="-1" aria-labelledby="OrganizeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="AddressModalLabel">Add New Address</h5>
        <button type="button" class="btn-close" id="AddressModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body"> 
            <div class="login-form">
              <form @submit.prevent="addAddress">
                <p id="error">{{error_message}}</p>
                <div class="mb-3 icon-input">
                    <input type="text" class="form-control" v-model="form.contact_name" id="exampleFormControlInput1" placeholder="Name">
                </div>
                <div class="row form-group">
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form.house_name" id="exampleFormControlInput1" placeholder="House Name/Building/Appartment No.">
                  </div>
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form.landmark" id="exampleFormControlInput1" placeholder="Landmark">
                  </div>
                </div>
                <div class="row form-group">
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form.city" id="exampleFormControlInput1" placeholder="City">
                  </div>
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form.state" id="exampleFormControlInput1" placeholder="State">
                  </div>
                </div>
                <div class="row form-group">
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form.mobile_number" id="exampleFormControlInput1" placeholder="Phone">
                  </div>
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form.pincode" id="exampleFormControlInput1" placeholder="PO Box (Optional)">
                  </div>
                </div>
                <!-- <div class="mb-3 icon-input">
                  <input type="text" class="form-control" id="exampleFormControlInput1"  placeholder="Location">
                  <i class='icon bx bx-current-location'></i>
                </div>
                <div class="mb-3">
                  <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Address..." rows="4"></textarea>
                </div>
                <div class="mb-3 icon-input">
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="PO Box (Optional)">
                </div> -->
                <button class="btn btn-primary login-btn">
                  <span v-if="!showLoad">
                    Submit <i class="bx bx-right-arrow-alt"></i>
                  </span>
                  <div v-if="showLoad" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              </form>
            </div>
      </div>
    </div>
  </div>
</div>

<!-- Edit Address Modal -->

<div class="modal fade auth-modal organize-modal" id="editAddressModal" tabindex="-1" aria-labelledby="OrganizeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="AddressModalLabel">Edit Address</h5>
        <button type="button" class="btn-close" id="EditAddressModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
            <div class="login-form">
              <form @submit.prevent="updateAddress">
                <p id="error">{{error_message}}</p>
                <div class="mb-3 icon-input">
                    <input type="text" class="form-control" v-model="form2.contact_name" id="exampleFormControlInput1" placeholder="Name">
                </div>
                <div class="row form-group">
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form2.house_name" id="exampleFormControlInput1" placeholder="House Name/Building/Appartment No.">
                  </div>
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form2.landmark" id="exampleFormControlInput1" placeholder="Landmark">
                  </div>
                </div>
                <div class="row form-group">
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form2.city" id="exampleFormControlInput1" placeholder="City">
                  </div>
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form2.state" id="exampleFormControlInput1" placeholder="State">
                  </div>
                </div>
                <div class="row form-group">
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form2.mobile_number" id="exampleFormControlInput1" placeholder="Phone">
                  </div>
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form2.pincode" id="exampleFormControlInput1" placeholder="PO Box (Optional)">
                  </div>
                </div>
                <!-- <div class="mb-3 icon-input">
                  <input type="text" class="form-control" id="exampleFormControlInput1"  placeholder="Location">
                  <i class='icon bx bx-current-location'></i>
                </div>
                <div class="mb-3">
                  <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Address..." rows="4"></textarea>
                </div>
                <div class="mb-3 icon-input">
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="PO Box (Optional)">
                </div> -->
                <button class="btn btn-primary login-btn">
                  <span v-if="!showLoad">
                    Submit <i class="bx bx-right-arrow-alt"></i>
                  </span>
                  <div v-if="showLoad" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              </form>
            </div>
      </div>
    </div>
  </div>
</div>

 <!-- Modal -->
<div class="modal fade auth-modal organize-modal" id="profileModal" tabindex="-1" aria-labelledby="OrganizeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="AuthModalLabel">Edit Profile</h5>
        <button type="button" class="btn-close" id="ProfileModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
            <div class="login-form">
              <form @submit.prevent="submitProfileForm">
                <p id="error">{{error_message}}</p>
                <div class="mb-3 icon-input">
                  <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Name" v-model="form3.name">
                </div>
                <div class="mb-3 icon-input">
                  <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Email" v-model="form3.email">
                </div>
                <div class="mb-3 icon-input" v-if="profile_picture">
                  <img :src="profile_picture" height="150" alt=""/>
                </div>
                <div class="mb-3 icon-input" v-if="form3.profile_picture">
                  <img :src="imageDisplay" height="150" alt=""/>
                </div>
                <div class="mb-3 icon-input">
                  <label for="FormControlFileUp" class="custom-file-upload">
                    <input type="hidden" @click='pickFile' v-model='form3.imageName'>
                    <input type="file" style="display: none"
                    ref="image"
                    accept="image/*"
                    @change="onFilePicked" class="form-control" id="FormControlFileUp" placeholder="Upload racket Photo">
                    <i class='icon bx bx-upload'></i><span class="placeholder-text">Upload Profile Photo</span>
                  </label>
                </div>
                <button class="btn btn-primary login-btn">
                  <span v-if="!showLoad">
                    Submit <i class="bx bx-right-arrow-alt"></i>
                  </span>
                  <div v-if="showLoad" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              </form>
            </div>
      </div>
    </div>
  </div>
</div>
    <!-- Cancel Order Modal -->
<div class="modal fade auth-modal organize-modal review-modal" id="CancelModal" tabindex="-1" aria-labelledby="CancelModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="AuthModalLabel">Cancel Order</h5>
        <button type="button" class="btn-close" id="CancelModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="login-form">
          <form @submit.prevent="cancelOrder">
            <div class="mb-3">
              <textarea class="form-control" id="exampleFormControlTextarea1" v-model="form4.cancellation_reason" placeholder="Write your cancellation reason" rows="4" required></textarea>
            </div>
            <button class="btn btn-primary login-btn">
              <span v-if="!showLoad">
                Submit
                <i class="bx bx-right-arrow-alt"></i>
              </span>
              <div v-if="showLoad" class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </form>
        </div>
      </div>
    </div> 
  </div>
</div>
    <!-- Return Order Modal -->
<div class="modal fade auth-modal organize-modal review-modal" id="ReturnModal" tabindex="-1" aria-labelledby="ReturnModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="AuthModalLabel">Return Order</h5>
        <button type="button" class="btn-close" id="ReturnModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="login-form">
          <form @submit.prevent="returnOrder">
            <div class="mb-3">
              <input type="number" class="form-control" id="exampleFormControlInput1" min="1" :max="form5.current_qty" placeholder="Choose your returning quantity" v-model="form5.quantity">
            </div>
            <div class="mb-3">
              <textarea class="form-control" id="exampleFormControlTextarea1" v-model="form5.comments" placeholder="Write your returning reason" rows="4" required></textarea>
            </div>
            <button class="btn btn-primary login-btn">
              <span v-if="!showLoad">
                Submit
                <i class="bx bx-right-arrow-alt"></i>
              </span>
              <div v-if="showLoad" class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </form>
        </div>
      </div>
    </div> 
  </div>
</div>
</template>

<script>
// import orderList from '@/components/orderList.vue'
// import orderDetail from '@/components/orderDetail.vue'
// import StarRating from 'vue-star-rating'
// import SingleProductCarousel from '@/components/SingleProductCarousel.vue'
import axios from 'axios'
import { mapActions } from "vuex";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
export default({
  name: 'Account',
  components: {
      // orderList,
      // orderDetail,
      // ProductCarousel,
      // SingleProductCarousel,
      // StarRating
      VPagination
  },
  data (){

    return{
      showLoading: false,
      showLoad:false,
      isHidden: true,
      selectedOrder: null,
      count: 1,
      user:[],
      location:'',
      orders:[],
      token:'',
      base_url:'https://admin.badmintonsouq.com',
      addressList:[],
      form:{
        contact_name:'',
        house_name:'',
        landmark:'',
        city:'',
        state:'',
        mobile_number:'',
        pincode:''
      },
      form2:{
        address_id:'',
        contact_name:'',
        house_name:'',
        landmark:'',
        city:'',
        state:'',
        mobile_number:'',
        pincode:''
      },
      form3:{
        name:'',
        email:'',
        profile_picture:'',
        imageName:''
      },
      form4:{
        order_id:'',
        cancellation_reason:''
      },
      form5:{
        order_id:'',
        item_id:'',
        quantity:'',
        current_qty:'',
        comments:'',
        order_item_id:''
      },
      error_message:'',
      profile_picture:'',
      total:0,
      total2:0,
      total3:0,
      imageDisplay:'',
      fromPage:1,
      fromPage2:1,
      fromPage3:1,
      isHidden2:true,
      perPage: 10,
      stringings:[],
      order_details:[],
      return_applicable:false,
      return_orders:[]
    }
  },
  created(){
    this.getUser();
    this.getReturnOrders();
    this.getUserAddress();
    this.currentLocation();
    this.getStringings();
  },
  // computed: {
  //   showDetails() {
  //       return this.orders.find(orders => orders.id === this.selectedOrder)
  //   }
  // },
  methods: {
    pickFile () {
        this.$refs.image.click ()
    },
		onFilePicked (e) {
			const files = e.target.files
			if(files[0] !== undefined) {
				this.form3.imageName = files[0].name
				if(this.form3.imageName.lastIndexOf('.') <= 0) {
					return
				}
				const fr = new FileReader ()
				fr.readAsDataURL(files[0])
				fr.addEventListener('load', () => {
					this.form3.profile_picture = e.target.files[0]
          this.imageDisplay = fr.result
          this.profile_picture = ''
				})
			} else {
				this.form.imageName = ''
				this.form.profile_picture = ''
        this.imageDisplay = ''
			}
		},
    showDetails(order){
      // alert(order.id)
      this.selectedOrder = order
      axios.post('https://admin.badmintonsouq.com/api/v1/get-order-details', {
        order_id:order.id
      },{
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
          this.return_applicable = response.data.data.order.return_applicable
          this.isHidden = false
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    showStringingDetails(order)
    {
      this.selectedStringing = order;
    },
    currentLocation(){
      if(this.$store.state.auth.address == null)
      {
        this.latitude = this.$store.state.auth.latitude
        this.longitude = this.$store.state.auth.longitude
        axios.get(
            "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
              this.latitude +
              "," +
              this.longitude +
              "&key=AIzaSyC9K84fNw6iBAmoUa3OD_w4YOqDRNx7Tvo"
          )
          .then((response) => {
            this.location = response.data.results[0].formatted_address
          })
          .catch(function (error) {
            console.log(error);
        })
      }
      else{
        this.location = this.$store.state.auth.address
      }
    },
    async getUser(page=1){
      this.showLoading = true;
      this.token = this.$store.state.auth.token
      await axios.post('https://admin.badmintonsouq.com/api/v1/get-user-dashboard', {
        page: page,
        per_page: this.perPage
      },{
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
          this.fromPage = page
          this.user   = response.data.data.user
          this.orders = response.data.data.orders.orders
          this.total  = response.data.data.orders.total_pages != undefined ? response.data.data.orders.total_pages : 0
      })
      .catch(function (error) {
          console.log(error);
        this.showLoading = false;
      })
        this.showLoading = false;
    },
    getStringings(page=1){
      this.token = this.$store.state.auth.token
      axios.post('https://admin.badmintonsouq.com/api/v1/get-user-bat-stringings', {
        page: page,
        per_page: this.perPage
      },{
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
          this.fromPage2 = page
          this.stringings   = response.data.data.bat_stringing
          this.total2  = response.data.data.total_pages != undefined ? response.data.data.total_pages : 0
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    getReturnOrders(page=1){
      this.token = this.$store.state.auth.token
      axios.post('https://admin.badmintonsouq.com/api/v1/order-return-list', {
        page: page,
        per_page: this.perPage
      },{
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
          this.fromPage3 = page
          this.return_orders   = response.data.data.order_returns.product_list
          this.total3  = response.data.data.order_returns.total_pages != undefined ? response.data.data.order_returns.total_pages : 0
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    getUserAddress(){
      this.token = this.$store.state.auth.token
      axios.post('https://admin.badmintonsouq.com/api/v1/address-list', {},{
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
          this.addressList = response.data.data.delivery_address
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    chooseItem(order)
    {
      this.form5.order_item_id = order.id
      this.form5.order_id = order.order_id
      this.form5.item_id  = order.item_id
      this.form5.current_qty = order.quantity
    },
    chooseOrder(order_id)
    {
      this.form4.order_id = order_id
    },
    cancelOrder()
    {
      this.showLoad = true
      axios.post('https://admin.badmintonsouq.com/api/v1/cancel-order', this.form4,{
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
          this.showLoad = false
          if(response.data.success == false){
            this.$toast.show(response.data.message,{
              type: 'error',
              position: 'top-right',
              duration: '2000'
            })
          }else{
            document.getElementById("CancelModalClose").click();
            this.form4.order_id  = ''
            this.form4.cancellation_reason    = ''
            this.$swal.fire(response.data.message,'',"success")
            .then(() => {
              window.location.reload()
            })
            // this.$toast.show(response.data.message,{
            //   type: 'success',
            //   position: 'top-right',
            //   duration: '2000'
            // })
            // .then(() => {
            //   window.location.reload()
            // })
          }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    returnOrder()
    {
      this.showLoad = true
      axios.post('https://admin.badmintonsouq.com/api/v1/order-return', this.form5,{
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
          this.showLoad = false
          if(response.data.success == false){
            this.$toast.show(response.data.message,{
              type: 'error',
              position: 'top-right',
              duration: '2000'
            })
          }else{
            document.getElementById("ReturnModalClose").click();
            this.form5.order_id  = ''
            this.form5.comments  = ''
            this.form5.quantity  = ''
            this.form5.current_qty  = ''
            this.form5.item_id   = '',
            this.form5.order_item_id   = ''
            this.$swal.fire(response.data.message,'',"success")
            .then(() => {
              window.location.reload()
            })
          }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    addAddress(){
      this.showLoad = true
      axios.post('https://admin.badmintonsouq.com/api/v1/create-address', this.form,{
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
          this.showLoad = false
          if(response.data.success == false){
            this.error_message = response.data.message
          }else{
            this.getUserAddress()
            document.getElementById("AddressModalClose").click();
            this.form.contact_name  = ''
            this.form.house_name    = ''
            this.form.landmark      = ''
            this.form.city          = ''
            this.form.state         = ''
            this.form.mobile_number = ''
            this.form.pincode       = ''
            this.$toast.show(response.data.message,{
              type: 'success',
              position: 'top-right',
              duration: '2000'
            })
            // .then(() => {
            //   this.$router.go(0);
            // })
          }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    editAddress(address){
      this.form2.address_id    = address.id,
      this.form2.contact_name  = address.contact_name,
      this.form2.house_name    = address.house_name,
      this.form2.landmark      = address.landmark,
      this.form2.city          = address.city,
      this.form2.state         = address.state,
      this.form2.mobile_number = address.mobile_number,
      this.form2.pincode       = address.pincode
    },
    updateAddress(){
      this.showLoad = true
      axios.post('https://admin.badmintonsouq.com/api/v1/update-address', this.form2,{
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
          this.showLoad = false
          if(response.data.success == false){
            this.error_message = response.data.message
          }else{
            this.getUserAddress()
            document.getElementById("EditAddressModalClose").click();
            this.$toast.show(response.data.message,{
              type: 'success',
              position: 'top-right',
              duration: '2000'
            })
            // .then(() => {
            //   this.$router.go(0);
            // })
          }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    userData()
    {
      this.form3.name = this.user.name
      this.form3.email = this.user.email
      this.profile_picture = this.user.profile_picture
    },
    deleteAddress(address_id){
      axios.post('https://admin.badmintonsouq.com/api/v1/delete-address', {address_id:address_id},{
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
          if(response.data.success == false){
            this.error_message = response.data.message
          }else{
            this.getUserAddress()
            this.$toast.show(response.data.message,{
              type: 'success',
              position: 'top-right',
              duration: '2000'
            })
          }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    ...mapActions(["LogOut"]),
    async logout() {
      try {
          await this.LogOut(this.$store.state.auth.user);
          this.$router.push("/");
          this.$router.go(0);
      } catch (error) {
        console.log(error);
      }
    },
    submitProfileForm(){
      this.showLoad = true
      let fd= new FormData()
      fd.append('name', this.form3.name)
      fd.append('email', this.form3.email)
      fd.append('profile_picture', this.form3.profile_picture)
      fd.append('imageName', this.form3.imageName)
      axios.post('https://admin.badmintonsouq.com/api/v1/update-profile', fd,{
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
          this.showLoad = false
          if(response.data.success == false){
            this.error_message = response.data.message
          }else{
            this.form3.profile_picture = ''
            this.getUser()
            document.getElementById("ProfileModalClose").click();
            this.$toast.show(response.data.message,{
              type: 'success',
              position: 'top-right',
              duration: '2000'
            })
          }
      })
      .catch(function (error) {
          console.log(error);
      })
    }
  }
})
</script>

<style>
#error{
  color:red;
}
</style>
