<template>
  <div class="list-page-wrap">
    <!-- <h1>This is an ProductList</h1> -->
    <div class="breadcrumb" style="background: url('https://picsum.photos/id/1035/1920/400')">
      <div class="container">
        <h1 class="title">{{title}}</h1>
         <!-- <p class="text">The racket was developed with the new "triple speed system frame" which combines exceptional power and great handling, and overall improve execution with power and more control. Designed with compact frame, which increases the speed in the swing, and overall improve execution with power and more control.</p> -->
      </div>
    </div>
    <div class="container" v-if="item_slug == 'shoes' || item_slug == 'accessories' || item_slug == 'apparels' || item_slug == 'bags'">
      <div class="sub-categories">
        <div class="sub-item">
          <a href="" @click.prevent="getProducts(fromPage,'mens','mens', 1)" :class="{ active: active_el == 'mens' }">
            <div class="img-wrap">
              <img src="../assets/mens.jpg" alt="" class="sub-img">
              <h3 class="title">Mens</h3>
            </div>
          </a>
        </div>
        <div class="sub-item">
          <a href="" @click.prevent="getProducts(fromPage,'women','women', 2)" :class="{ active: active_el == 'women' }">
            <div class="img-wrap">
              <img src="../assets/women.jpg" alt="" class="sub-img">
              <h3 class="title">Women</h3>
            </div>
          </a>
        </div>
        <div class="sub-item">
          <a href="" @click.prevent="getProducts(fromPage,'junior','junior', 3)" :class="{ active: active_el == 'junior' }">
            <div class="img-wrap">
              <img src="../assets/junior.jpg" alt="" class="sub-img">
              <h3 class="title">Junior</h3>
            </div>
          </a>
        </div>
      </div>
    </div>

  <!-- Brand Shimmer -->

    <div class="brand-carousel" v-if="showLoading">
      <!-- <div class="container">
        <div class="top-sec">
          <a href="#" class="link-btn">View All</a>
        </div>
      </div> -->
      <div class="container">
        <div class="special-cat-products products-carousel">
          <swiper
          :slidesPerView="3" 
          :spaceBetween="10" 
          :autoplay='{
              "delay": 2500,
              "disableOnInteraction": false
            }' 
            :navigation="false"
            :breakpoints='{
              "640": {
                "slidesPerView": 4,
                "spaceBetween": 10
              },
              "768": {
                "slidesPerView": 6,
                "spaceBetween": 20
              },
              "1024": {
                "slidesPerView": 8,
                "spaceBetween": 20
              }
            }' class="single-image-slider"> 
          <swiper-slide >
          <div class="carousel-item-single">
              <free-style-shimmer
              :is-loading="showLoading"
                height="70px"
                width="100%"
                border-radius="10px"
                color="#bdbdbd"
              />
          </div>
          </swiper-slide>
          <swiper-slide >
          <div class="carousel-item-single">
              <free-style-shimmer
              :is-loading="showLoading"
                height="70px"
                width="100%"
                border-radius="10px"
                color="#bdbdbd"
              />
          </div>
          </swiper-slide>
          <swiper-slide >
          <div class="carousel-item-single">
              <free-style-shimmer
              :is-loading="showLoading"
                height="70px"
                width="100%"
                border-radius="10px"
                color="#bdbdbd"
              />
          </div>
          </swiper-slide>
          <swiper-slide >
          <div class="carousel-item-single">
              <free-style-shimmer
              :is-loading="showLoading"
                height="70px"
                width="100%"
                border-radius="10px"
                color="#bdbdbd"
              />
          </div>
          </swiper-slide>
          <swiper-slide >
          <div class="carousel-item-single">
              <free-style-shimmer
              :is-loading="showLoading"
                height="70px"
                width="100%"
                border-radius="10px"
                color="#bdbdbd"
              />
          </div>
          </swiper-slide>
          <swiper-slide >
          <div class="carousel-item-single">
              <free-style-shimmer
              :is-loading="showLoading"
                height="70px"
                width="100%"
                border-radius="10px"
                color="#bdbdbd"
              />
          </div>
          </swiper-slide>
          <swiper-slide >
          <div class="carousel-item-single">
              <free-style-shimmer
              :is-loading="showLoading"
                height="70px"
                width="100%"
                border-radius="10px"
                color="#bdbdbd"
              />
          </div>
          </swiper-slide>
        </swiper>
        </div>
      </div>
    </div>

    <div class="brand-carousel">
      <!-- <div class="container">
        <div class="top-sec">
          <a href="#" class="link-btn">View All</a>
        </div>
      </div> -->
      <div class="container" v-if="cat_slug != 'brands'">
        <div class="special-cat-products products-carousel">
          <swiper
          :slidesPerView="3" 
          :spaceBetween="10" 
          :autoplay='{
              "delay": 2500,
              "disableOnInteraction": false
            }' 
            :navigation="false"
            :breakpoints='{
              "640": {
                "slidesPerView": 4,
                "spaceBetween": 10
              },
              "768": {
                "slidesPerView": 6,
                "spaceBetween": 20
              },
              "1024": {
                "slidesPerView": 8,
                "spaceBetween": 20
              }
            }' class="single-image-slider"> 
          <swiper-slide 
          v-for="(brand, index) in brand_filter"
          :key="index"
          >
          <div class="carousel-item-single">
            <div class="pro-img">
              <a href="#" @click.prevent="getProducts(fromPage,'brand',brand.id)" class="link">
                <img :src="base_url+brand.image" />
              </a>
            </div>
          </div>
          </swiper-slide>
        </swiper>
        </div>
      </div>
    </div>
    
    <div class="actions-wrap">
      <div class="container">
        <div class="filter-sort-wrap">
          <div class="form-col">
            <label>Sort By</label>
            <select class="form-select" aria-label="Default select example" v-model="select5" v-on:change="getProducts(fromPage,'sort_by',this.select5)">
              <option selected>Sort By</option>
              <option value="low">Price: Low to High</option>
              <option value="high">Price: High to Low</option>
              <option value="new">New Arrival</option>
            </select>
          </div>
          <div class="form-col" v-if="item_slug == 'shoes' || item_slug == 'apparels'">
            <label>Sizes</label>
            <select class="form-select" aria-label="Default select example"  v-model="select0" v-on:change="getProducts(fromPage,'size',this.select0)">
              <option selected>Sizes</option>
              <option v-for="size in sizes" :key="size.id" v-bind:value="size.id">{{size.size}}</option>
            </select>
          </div>
          <!--<div class="form-col" v-if="cat_slug != 'brands'">
            <select class="form-select" aria-label="Default select example" v-model="select1" v-on:change="getProducts(fromPage,'brand',this.select1)">
              <option selected>Brands</option>
              <option v-for="brand in brand_filter" :key="brand.id" v-bind:value="brand.id">{{brand.title}}</option>
            </select>
          </div>-->
          <div class="form-col" v-if="item_slug == 'rackets'">
            <label>Balance</label>
            <select class="form-select" aria-label="Default select example" v-model="select2" v-on:change="getProducts(fromPage,'balance',this.select2)">
              <option selected>Balance</option>
              <option v-for="bal in balance" :key="bal.id" v-bind:value="bal.id">{{bal.title}}</option>
            </select>
          </div>
          <div class="form-col" v-if="item_slug == 'rackets'">
            <label>Flexibility</label>
            <select class="form-select" aria-label="Default select example" v-model="select3" v-on:change="getProducts(fromPage,'flexibility',this.select3)">
              <option selected>Flexibility</option>
              <option v-for="flex in flexibility" :key="flex.id" v-bind:value="flex.id">{{flex.title}}</option>
            </select>
          </div>
          <div class="form-col" v-if="item_slug == 'rackets'">
            <label>Weight</label>
            <select class="form-select" aria-label="Default select example" v-model="select4" v-on:change="getProducts(fromPage,'weight',this.select4)">
              <option selected>Weight</option>
              <option v-for="weigh in weight" :key="weigh.id" v-bind:value="weigh.id">{{weigh.title}}</option>
            </select>
          </div>
          <div class="form-col" v-if="item_slug == 'shuttlecocks'">
            <label>Shuttle Cock Type</label>
            <select class="form-select" aria-label="Default select example" v-model="select6" v-on:change="getProducts(fromPage,'shuttlecocks',this.select6)">
              <option selected>Shuttle Cock Type</option>
              <option v-for="types in shuttle_cock_types" :key="types.id" v-bind:value="types.id">{{types.title}}</option>
            </select>
          </div>
          <div class="form-col" v-if="item_slug == 'accessories'">
            <label>Accessory Type</label>
            <select class="form-select" aria-label="Default select example" v-model="select7" v-on:change="getProducts(fromPage,'accessories',this.select7)">
              <option selected>Accessory Type</option>
              <option v-for="types in accessory_types" :key="types.id" v-bind:value="types.id">{{types.name}}</option>
            </select>
          </div>
          <div class="form-col" v-if="item_slug == 'bags'">
            <label>Bag Type</label>
            <select class="form-select" aria-label="Default select example" v-model="select8" v-on:change="getProducts(fromPage,'bags',this.select8)">
              <option selected>Bag Type</option>
              <option v-for="types in bag_types" :key="types.id" v-bind:value="types.id">{{types.name}}</option>
            </select>
          </div>
          <div class="form-col" v-if="item_slug == 'rackets'">
            <label>Playing Level</label>
            <select class="form-select" aria-label="Default select example" v-model="select9" v-on:change="getProducts(fromPage,'playing_levels',this.select9)">
              <option selected>Playing Level</option>
              <option v-for="level in playing_levels" :key="level.id" v-bind:value="level.id">{{level.name}}</option>
            </select>
          </div>
          <div class="form-col" v-if="item_slug == 'trophies'">
            <label>Trophy Types</label>
            <select class="form-select" aria-label="Default select example" v-model="select10" v-on:change="getProducts(fromPage,'trophies',this.select10)">
              <option selected>Trophy Types</option>
              <option v-for="types in trophy_types" :key="types.id" v-bind:value="types.id">{{types.name}}</option>
            </select>
          </div>
        <!-- </div>
      </div>
    </div>
    <div class="actions-wrap">
      <div class="container">
        <div class="filter-sort-wrap"> -->
          <div class="form-col" v-if="cat_slug == 'category'">
            <label>Min Price</label>
            <input type="number" step="0.01" class="form-control" v-model="min_price" v-on:change="getProducts(fromPage,'min_price',this.min_price)">
          </div>
          <div class="form-col" v-if="cat_slug == 'category'">
            <label>Max Price</label>
            <input type="number" step="0.01" class="form-control" v-model="max_price" v-on:change="getProducts(fromPage,'max_price',this.max_price)">
          </div>
        </div>
      </div>
    </div>

    <ProductList :Listdata="productLists"  :loadingdata ="showLoading"/>

    <div class="container mb-5" v-if="productLists.length == 0" style="text-align:center;"><h3>No Products Found</h3></div>
    <div class="container mb-5" v-if="total>1 && !filter">
      <v-pagination
        v-model="fromPage"
        :pages="total"
        :range-size="1"
        active-color="#DCEDFF"
        @update:modelValue="getProducts"
      />
    </div>
  </div>
</template>

<script>
// import brandsCarousel from '@/components/brandsCarousel.vue'
import ProductList from '@/components/ProductList.vue'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import axios from 'axios'
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, {
  Autoplay,Navigation
} from 'swiper';
SwiperCore.use([Autoplay,Navigation]);
// import {mapMutations } from 'vuex';
// import {
//     LOADING_SPINNER_SHOW_MUTATION,
// } from '../store/storeconstants';
export default({
  name: 'productList',
  components: {
      // brandsCarousel,
      Swiper,
      SwiperSlide,
      ProductList,
      VPagination
  },
  data (){

    return{
      showLoading: false,
      active_el: false,
      title:'',
      brands :[],
      sizes:[],
      productLists :[],
      base_url:'https://admin.badmintonsouq.com',
      cat_slug:'',
      item_slug:'',
      slug:'',
      brand_filter:[],
      balance:[],
      flexibility:[],
      weight:[],
      bag_types:[],
      trophy_types:[],
      accessory_types:[],
      playing_levels:[],
      select0:'Sizes',
      select1:'Brands',
      select2:'Balance',
      select3:'Flexibility',
      select4:'Weight',
      select5:'Sort By',
      select6:'Shuttle Cock Type',
      select7:'Accessory Type',
      select8:'Bag Type',
      select9:'Playing Level',
      select10:'Trophy Types',
      trophy_type_id:'',
      brand_id:'',
      flexibility_id:'',
      balance_id:'',
      weight_id:'',
      bag_type_id:'',
      accessory_type_id:'',
      playing_level_id:'',
      min_price:0.00,
      max_price:-1.0,
      sort_by:'',
      total:0,
      fromPage:1,
      perPage: 20,
      filter:false,
      mens:'',
      junior:'',
      women:'',
      size_id:'',
      shuttle_cock_types:[],
      shuttle_cock_type_id:''
    }
  },
  created(){
    // this.getBrands();
    this.getProducts();
    this.getFilters();
  },
  mounted(){
  },
  methods:{
    // getBrands(){
    //   axios.post('https://admin.badmintonsouq.com/api/v1/get-partners', {})
    //   .then((response) => {
    //       this.brands = response.data.data.partners
    //   })
    //   .catch(function (error) {
    //       console.log(error);
    //   })
    // },
    
    // ...mapMutations({
    //     showLoading: LOADING_SPINNER_SHOW_MUTATION,
    // }),
    async getProducts(page = 1,key,value){
      // this.showLoading(true);
      this.showLoading = true;
      this.url_slug  = this.$route.fullPath.split("/");
      this.cat_slug  = this.url_slug[1]
      this.item_slug = this.url_slug[2]
      if(this.cat_slug != 'category')
      {
        this.min_price = ''
        this.max_price = ''
      }
      if(key == 'size'){
        this.size_id        = value
        if(value == 'Size'){
          this.size_id      = ''
        }
      }
      else if(key == 'brand'){
        this.brand_id       = value
        if(value == 'Brand'){
          this.brand_id      = ''
        }
      }
      else if(key == 'flexibility'){
        this.flexibility_id = value
        if(value == 'Flexibility'){
          this.flexibility_id  = ''
        }
      }
      else if(key == 'balance'){
        this.balance_id     = value
         if(value == 'Balance'){
          this.balance_id   = ''
        }
      }
      else if(key == 'weight'){
        this.weight_id      = value
        if(value == 'Weight'){
          this.weight_id   = ''
        }
      }
      else if(key == 'shuttlecocks'){
        this.shuttle_cock_type_id = value
        if(value == 'Shuttlecocks'){
          this.shuttle_cock_type_id   = ''
        }
      }
      else if(key == 'bags'){
        this.bag_type_id     = value
         if(value == 'Bag Type'){
          this.bag_type_id   = ''
        }
      }
      else if(key == 'accessories'){
        this.accessory_type_id     = value
         if(value == 'Accessory Type'){
          this.accessory_type_id   = ''
        }
      }
      else if(key == 'trophies'){
        this.trophy_type_id     = value
         if(value == 'Trophy Type'){
          this.trophy_type_id   = ''
        }
      }
      else if(key == 'playing_levels'){
        this.playing_level_id     = value
         if(value == 'Playing Level'){
          this.playing_level_id   = ''
        }
      }
      else if(key == 'sort_by'){
        this.sort_by        = value
        if(value == 'Sort By'){
          this.sort_by   = ''
        }
      }
      else if(key == 'mens'){
        this.women       = ''
        this.mens        = value
        this.junior      = ''
        this.active_el = key;
      }
      else if(key == 'women'){
        this.women       = value
        this.mens        = ''
        this.junior      = ''
        this.active_el = key;
      }
      else if(key == 'junior'){
        this.junior      = value
        this.mens        = ''
        this.women       = ''
        this.active_el = key;
      }
      await axios.post('https://admin.badmintonsouq.com/api/v1/get-products', {
        main_slug: this.cat_slug,
        sub_slug: this.item_slug,
        brand_id: this.brand_id,
        flexibility_id: this.flexibility_id,
        shuttle_cock_type_id: this.shuttle_cock_type_id,
        bag_type_id:this.bag_type_id,
        accessory_type_id:this.accessory_type_id,
        trophy_type_id: this.trophy_type_id,
        balance_id: this.balance_id,
        weight_id: this.weight_id,
        playing_level_id:this.playing_level_id,
        price_min:this.min_price,
        price_max:this.max_price,
        sort_by: this.sort_by,
        mens:this.mens,
        women:this.women,
        junior:this.junior,
        size_id:this.size_id,
        page: page,
        per_page: this.perPage
      })
      .then((response) => {
          this.fromPage     = page;
          this.productLists = response.data.data.products.products
          this.title        = response.data.data.title
          this.total        = response.data.data.products.total_pages != undefined ? response.data.data.products.total_pages : 0
      })
      .catch(function (error) {
          console.log(error);
          // this.showLoading(false);
          this.showLoading = false;
      })
      // this.showLoading(false);
      this.showLoading = false;
    },
    getFilters(){
      axios.post('https://admin.badmintonsouq.com/api/v1/get-product-filters', {
        main_slug: this.cat_slug,
        sub_slug: this.item_slug
      })
      .then((response) => {
          this.brand_filter = response.data.data.brands
          this.balance      = response.data.data.balance
          this.flexibility  = response.data.data.flexibility
          this.weight       = response.data.data.weight
          this.shuttle_cock_types = response.data.data.shuttle_cock_types
          this.sizes        = response.data.data.sizes
          this.trophy_types = response.data.data.trophy_types
          this.bag_types    = response.data.data.bag_types
          this.accessory_types = response.data.data.accessory_types
          this.playing_levels = response.data.data.product_levels,
          this.min_price    = response.data.data.price_level.min_price
          this.max_price    = response.data.data.price_level.max_price
      })
      .catch(function (error) {
          console.log(error);
      })
    }
  }
})
</script>
