<template>
  <div class="list-page-wrap contact-page">    
    <!-- <h1>This is an ProductList</h1> -->
    <div class="breadcrumb" style="background: url('https://picsum.photos/id/1035/1920/400')">
      <div class="container">
        <h1 class="title">Contact Us</h1>
        <!-- <p class="text">The racket was developed with the new "triple speed system frame" which combines exceptional power and great handling, and overall improve execution with power and more control. Designed with compact frame, which increases the speed in the swing, and overall improve execution with power and more control.</p> -->
      </div>
    </div>
    <div class="contact-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="map-wrap">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d901.9291351393719!2d55.3720388!3d25.280119199999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5db0b6bef5bb%3A0x6adbff7939fe63c5!2sAl%20Zarouni%20Building!5e0!3m2!1sen!2sin!4v1649399210820!5m2!1sen!2sin" width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="address-wrap">
              <h5 class="title">Call Us</h5>
              <p class="text phone">Phone -  +971 43989455</p> 
              <!--<p class="text time">Lines Open: 9am - 5pm, Monday - Saturday</p>-->
              <p class="text phone">Whatsapp -  +971 505048498</p> 
              <p class="text phone">Address -  Al Zarooni Bulding,Office No. 705, Al Qusais P.O.Box: 238289 , Dubai ,UAE</p>
            </div>
            <div class="address-wrap">
              <h5 class="title">Mail Us</h5>
              <p class="text">info@badmintonsouq.com</p> 
              <!--<p class="text">For service or general enquiries: info@badmintonsouq.com</p>-->
            </div>
            <!-- <div class="address-wrap">
              <h5 class="title">Showroom opening times</h5>
              <p class="text">Monday – Saturday: 9am – 5pm</p> 
              <p class="text">Sunday: 9am – 5pm (Open March, April, May only)</p>
              <p class="text">Excludes some Bank Holidays.</p> 
            </div> -->
          </div>
          <div class="col-lg-4">
            <div class="contact-form-wrap">
              <form @submit.prevent="submitContactForm">
                  <p id="error">{{error_message}}</p>
                  <div class="mb-3">
                    <input type="text" class="form-control" id="exampleFormControlInput1" v-model="form.name" placeholder="Your Name">
                  </div>
                  <div class="mb-3">
                    <input type="email" class="form-control" id="exampleFormControlInput1" v-model="form.email" placeholder="Your Email">
                  </div>
                  <div class="mb-3">
                    <input type="text" class="form-control" id="exampleFormControlInput1" v-model="form.phone" placeholder="Your Phone">
                  </div>
                  <div class="mb-3">
                    <input type="text" class="form-control" id="exampleFormControlInput1" v-model="form.subject" placeholder="Your Subject">
                  </div>
                  <div class="mb-3">
                    <textarea class="form-control" id="exampleFormControlTextarea1" v-model="form.message" placeholder="Your Message..." rows="4"></textarea>
                  </div>
                  <button class="btn btn-primary login-btn">
                    <span v-if="!showLoad">
                      Submit
                      <i class="bx bx-right-arrow-alt"></i>
                    </span>
                    <div v-if="showLoad" class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: 'Contact',
  data (){

    return{
      showLoad:false,
      error_message:'',
      form:{
        name:'',
        phone:'',
        email:'',
        subject:'',
        message:''
      }
    } 
  },
  mounted(){
  },
  methods:{
    submitContactForm(){
      this.showLoad = true
      axios.post('https://admin.badmintonsouq.com/api/v1/create-enquiry', this.form)
      .then((response) => {
        this.showLoad = false
        if(response.data.success == false){
          this.error_message = response.data.message
        }else{
          this.$swal.fire(response.data.message,'',"success")
          .then(() => {
            this.$router.go(0);
          })
        }
      })
      .catch(function (error) {
          console.log(error);
      })
    }
  }
}
</script>

<style>
#error{
  color:red;
}
</style>