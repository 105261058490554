<template>
  <div class="login-page">
    <div class="container">
      <div class="login-form pay-response mb-3 mt-3">
              <div class="vect-img">
                <img src=".././assets/2505487-5540.png" alt="" class="img">
              </div>
        <h1 id="success">Payment Success!</h1>
      </div>
      <div class="order-detail-wrap">
        <div class="top-sec">
          <div class="left-sec">
            <h3 class="title">Order Details</h3>
          </div>
        </div>
        <!-- <div v-for="order in orders" :key="order.id"> -->
        <div class="card m-b-30">
            <div class="card-header">                                
                <div class="row align-items-center">
                    <div class="col-7">
                        <h5 class="card-title mb-0">Order No : {{order.invoice_no}}</h5>
                    </div>
                    <div class="col-5 text-right">
                        <span class="badge delivered badge-success-inverse">{{order.status.title}}</span>                                             
                    </div>
                </div>
            </div>
            <div class="card-body">                                
                <div class="row mb-3 border-bottom">
                    <div class="col-md-6 col-lg-6 col-xl-3">
                        <div class="order-primary-detail mb-4">
                        <h6>Ordered Date & Time</h6>
                        <p class="mb-0">{{order.created_at}}</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3">
                        <div class="order-primary-detail mb-4">
                        <h6>Name</h6>
                        <p class="mb-0">{{order.user.name}}</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3">
                        <div class="order-primary-detail mb-4">
                        <h6>Email ID</h6>
                        <p class="mb-0">{{order.user.email}}</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-xl-3">
                        <div class="order-primary-detail mb-4">
                        <h6>Contact No</h6>
                        <p class="mb-0">{{order.user.phone}}</p>
                        </div>
                    </div>
                </div> 
                <div class="row">
                    <div class="col-md-6 col-lg-8 col-xl-8">
                        <div class="order-primary-detail mb-4">
                        <h6>Delivery Address </h6>
                        <p class="total">{{order.user_address.contact_name}}<br>
                        {{order.user_address.house_name}} - {{order.user_address.landmark}} - {{order.user_address.city}} - {{order.user_address.state}}<br>
                        {{order.user_address.mobile_number}}<br>
                        {{((order.user_address.pincode) ? 'PO Box - '+order.user_address.pincode : '')}}
                        </p>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 col-xl-4 ">
                        <div class="order-primary-detail mb-4">
                        <h6>Payment Type</h6>
                        <p>{{order.payment_option.title}}</p>
                        </div>
                        <div class="order-primary-detail mb-4">
                          <h6>Payment ID</h6>
                          <p>{{order.payment_id}}</p>
                        </div>
                    </div>
                </div>                                
            </div>
        </div> 
        <br>
        <div class="card m-b-30">
            <div class="card-header">
                <h5 class="card-title">Order Items</h5>
            </div>
            <div class="card-body">
                <div class="table-responsive ">
                    <table class="table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Product</th>
                                <th scope="col">Qty</th>
                                <th scope="col">Price</th>
                                <th scope="col">Label</th>
                                <th scope="col">Label Charge</th>
                                <th scope="col">Stringing Charge</th>
                                <th scope="col" class="text-right">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                                <tr v-for="product in order.products" :key="product.id">
                                    <th scope="row">1</th>
                                    <td v-if="product.color_id && product.size_id">{{product.item.title}} <br> 
                                      <div :style="{ 'background-color': product.color.color, 'width' : '20px','height' : '20px'} "></div>
                                      {{product.size.size}}
                                    </td>
                                    <td v-else-if="product.color_id">{{product.item.title}} <br> 
                                      <div :style="{ 'background-color': product.color.color, 'width' : '20px','height' : '20px'} "></div>
                                    </td>
                                    <td v-else-if="product.size_id">{{product.item.title}} <br> {{product.size.size}}</td>
                                    <td v-else>{{product.item.title}}</td>
                                    <td>{{product.quantity}}</td>
                                    <td>AED {{product.cost}}</td>
                                    <td>{{product.addon_label}}</td>
                                    <td>AED {{product.addon_charge}}</td>
                                    <td>AED {{product.stringing_charge}}</td>
                                    <td class="text-right">AED {{product.total_cost}}</td>
                                </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row border-top pt-3">
                    <div class="col-md-12 order-1 order-lg-2 col-lg-8 col-xl-6"></div>
                    <div class="col-md-12 order-1 order-lg-2 col-lg-8 col-xl-6">
                        <div class="order-total table-responsive ">
                            <table class="table table-borderless text-right">
                                <tbody>
                                    <tr>
                                        <td>Sub Total :</td>
                                        <td>AED {{order.total_cost}}</td>
                                    </tr>
                                    <tr>
                                        <td>Shipping Cost:</td>
                                        <td>AED {{order.shipping_cost}}</td>
                                    </tr>
                                    <tr>
                                        <td>VAT(5%) :</td>
                                        <td>{{order.tax}}</td>
                                    </tr>

                                    <tr v-if="order.processing_fee">
                                      <td>Postpay Processing Fee</td>
                                      <td>AED {{order.processing_fee}}</td>
                                    </tr>

                                    <tr v-if="order.coupon_discount_amount != 0">
                                      <td>Coupon Discount</td>
                                      <td>AED -{{order.coupon_discount_amount}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-black f-w-7 font-18">Amount :</td>
                                        <td class="text-black f-w-7 font-18 color-primary">AED {{order.grand_total}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Success',
  data (){

    return{
      token:'',
      order:[]
    }
  },
  created(){
    this.getOrder();
  },
  methods:{
    getOrder()
    {
      this.token = this.$store.state.auth.token
      axios.post('https://admin.badmintonsouq.com/api/v1/get-last-order', {},{
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
          this.order    = response.data.data.order
      })
      .catch(function (error) {
          console.log(error);
      })
    }
  }
}
</script>

<style>
#success{
  color:green;
}
</style>