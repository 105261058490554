<template>
  <div class="list-page-wrap">
    <!-- <h1>This is an ProductList</h1> -->
    <div class="breadcrumb" style="background: url('https://picsum.photos/id/1035/1920/400')">
      <div class="container">
        <h1 class="title">{{"Add on Items"}}</h1>
         <!-- <p class="text">The racket was developed with the new "triple speed system frame" which combines exceptional power and great handling, and overall improve execution with power and more control. Designed with compact frame, which increases the speed in the swing, and overall improve execution with power and more control.</p> -->
      </div>
    </div>
    <!--<div class="actions-wrap">
      <div class="container">
        <div class="filter-sort-wrap">
          <div class="form-col">
            <select class="form-select" aria-label="Default select example" v-model="select5" v-on:change="getProducts(fromPage,'sort_by',this.select5)">
              <option selected>Sort By</option>
              <option value="low">Price: Low to High</option>
              <option value="high">Price: High to Low</option>
              <option value="new">New Arrival</option>
            </select>
          </div>
        </div>
      </div>
    </div>-->
    <div class="container mt-5">
      <app-tabs class="w-11/12 lg:w-10/12 mx-auto mb-16" :tabList="tabList">
        <template v-slot:tabPanel-1>
    <div class="container">
      <div class="special-cat-products products-carousel">
        <div class="product-list-wrap">
          <div class="product-item"
          v-for="(Listdata, index) in strings"
          :key="index"
          >
            <div class="carousel-item-single">
              <div class="pro-img">
                <a :href="'/products/'+Listdata.slug+'?addon_reference_id='+addon_reference_id">
                <img :src="Listdata.image" /></a>
                <!-- <span class="offer">{{Listdata.offer}}</span> -->
              </div>
              <div class="pro-info">
                <span class="brand-n">{{Listdata.brand.title}}</span>
                <a :href="'/products/'+Listdata.slug+'?addon_reference_id='+addon_reference_id" style="color:black;">
                <p class="pro-name" v-if="Listdata.title.length<50">{{Listdata.title}}</p>
                <p class="pro-name" v-if="Listdata.title.length>50">{{Listdata.title.substring(0,50)+"..."}}</p>
                </a>
                <div class="rating" v-if="Listdata.rating != null">
                  <star-rating 
                  :rating="parseFloat(Listdata.rating)" 
                  :read-only="true" 
                  :increment="0.5" 
                  :star-size="15"
                  inactive-color="#E1E1E1" 
                  active-color="#A5A5A5"
                  :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"></star-rating>
                  </div>
                <div class="price">
                  <p class="old-p">{{Listdata.mrp}}</p>
                  <p class="new-p">{{Listdata.sale_cost}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mb-5" v-if="strings.length == 0" style="text-align:center;"><h3>No Products Found</h3></div>
    <div class="container mb-5" v-if="total>1 && !filter">
      <v-pagination
        v-model="fromPage1"
        :pages="total1"
        :range-size="1"
        active-color="#DCEDFF"
        @update:modelValue="getStrings"
      />
    </div>
    </template>
        <template v-slot:tabPanel-2>
    <div class="container">
      <div class="special-cat-products products-carousel">
        <div class="product-list-wrap">
          <div class="product-item"
          v-for="(Listdata, index) in grips"
          :key="index"
          >
            <div class="carousel-item-single">
              <div class="pro-img">
                <a :href="'/products/'+Listdata.slug+'?addon_reference_id='+addon_reference_id">
                <img :src="Listdata.image" /></a>
                <!-- <span class="offer">{{Listdata.offer}}</span> -->
              </div>
              <div class="pro-info">
                <span class="brand-n">{{Listdata.brand.title}}</span>
                <a :href="'/products/'+Listdata.slug+'?addon_reference_id='+addon_reference_id" style="color:black;">
                <p class="pro-name" v-if="Listdata.title.length<50">{{Listdata.title}}</p>
                <p class="pro-name" v-if="Listdata.title.length>50">{{Listdata.title.substring(0,50)+"..."}}</p>
                </a>
                <div class="rating" v-if="Listdata.rating != null">
                  <star-rating 
                  :rating="parseFloat(Listdata.rating)" 
                  :read-only="true" 
                  :increment="0.5" 
                  :star-size="15"
                  inactive-color="#E1E1E1" 
                  active-color="#A5A5A5"
                  :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"></star-rating>
                  </div>
                <div class="price">
                  <p class="old-p">{{Listdata.mrp}}</p>
                  <p class="new-p">{{Listdata.sale_cost}}</p>
                </div>
                <div>
                  <button class="btn add-to-cart" @click.prevent="addToCart(Listdata.id)"><i class='bx bxs-cart-alt'></i> Add to Cart</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mb-5" v-if="grips.length == 0" style="text-align:center;"><h3>No Products Found</h3></div>
    <div class="container mb-5" v-if="total>1 && !filter">
      <v-pagination
        v-model="fromPage2"
        :pages="total2"
        :range-size="1"
        active-color="#DCEDFF"
        @update:modelValue="getGrips"
      />
    </div>
        </template>
      </app-tabs>
    </div>
  </div>
</template>

<script>
// import brandsCarousel from '@/components/brandsCarousel.vue'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import axios from 'axios'
// import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.min.css';
import AppTabs from '@/components/Tabs.vue';
import SwiperCore, {
  Autoplay,Navigation
} from 'swiper';
SwiperCore.use([Autoplay,Navigation]);
import { mapActions } from "vuex";
import {mapMutations } from 'vuex';
import {
    LOADING_SPINNER_SHOW_MUTATION,
} from '../store/storeconstants';
export default({
  name: 'String Grip',
  components: {
      // brandsCarousel,
      // Swiper,
      // SwiperSlide,
      VPagination,
      AppTabs
  },
  data (){

    return{
      tabList: ["String", "Grips"],
      title:'',
      strings :[],
      grips:[],
      base_url:'https://admin.badmintonsouq.com',
      total1:0,
      fromPage1:1,
      total2:0,
      fromPage2:1,
      perPage: 20,
      filter:false,
      addon_reference_id:'',
      token:'',
      color_id:'',
      size_id:'',
      addon_charge:0.00,
      addon_label:''
    }
  },
  created(){
    this.getStrings();
    this.getGrips();
  },
  mounted(){
    this.token = this.$store.state.auth.token
    this.addon_reference_id = this.$route.query.addon_reference_id
  },
  methods:{
    ...mapMutations({
        showLoading: LOADING_SPINNER_SHOW_MUTATION,
    }),
    ...mapActions(["CartCount"]),
    getCartCount(){
      this.token = this.$store.state.auth.token
      if(this.token != null)
      {
          axios.post('https://admin.badmintonsouq.com/api/v1/cart-count',{}, {
          headers: {
              Authorization  : 'Bearer ' + this.token
          }
          })
          .then((response) => {
              this.CartCount(response.data.data.count)
          })
          .catch(function (error) {
              console.log(error);
          })
      }
    },
    getStrings(page=1)
      {
        axios.post('https://admin.badmintonsouq.com/api/v1/get-products', {
          main_slug: 'category',
          sub_slug: 'strings',
          is_addon:1,
          string:1,
          grip:0,
          addon_category_id:9,
          page: page,
          per_page: this.perPage
        })
        .then((response) => {
            this.fromPage1     = page;
            this.strings       = response.data.data.products.products
            this.total2        = response.data.data.products.total_pages != undefined ? response.data.data.products.total_pages : 0
        })
        .catch(function (error) {
            console.log(error);
            this.showLoading(false);
        })
        this.showLoading(false);
      },
      getGrips(page=1)
      {
        axios.post('https://admin.badmintonsouq.com/api/v1/get-products', {
          main_slug: 'category',
          sub_slug: 'grips',
          is_addon:1,
          string:0,
          grip:1,
          addon_category_id:10,
          page: page,
          per_page: this.perPage
        })
        .then((response) => {
            this.fromPage2    = page;
            this.grips        = response.data.data.products.products
            this.total2       = response.data.data.products.total_pages != undefined ? response.data.data.products.total_pages : 0
        })
        .catch(function (error) {
            console.log(error);
            this.showLoading(false);
        })
        this.showLoading(false);
      },
      addToCart(product_id){
      axios.post('https://admin.badmintonsouq.com/api/v1/cart/increment', {
          item_id:product_id,
          color_id: this.color_id,
          size_id: this.size_id,
          addon_charge: this.addon_charge,
          addon_label: this.addon_label,
          addon_reference_id:this.addon_reference_id
      },
      {
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
        if(response.data.success == true){
          this.$toast.show(response.data.message,{
            type: 'success',
            position: 'top-right',
            duration: '2000'
          })
          this.getCartCount()
        }else{
          this.$toast.show(response.data.message,{
            type: 'error',
            position: 'top-right',
            duration: '2000'
          })
        }
      })
      .catch(function (error) {
          console.log(error);
      })
    }
  }
})
</script>
