<template>
  <div class="login-page">
    <div class="container">
      <div class="login-form pay-response mb-3 mt-3">
              <div class="vect-img">
                <img src=".././assets/notfound.png" alt="" class="img">
              </div>
        <h1>404</h1>
        <h1>Page Not Found!</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Not Found',
  data (){
  },
  created(){
  },
  methods:{
  }
}
</script>

<style>
#success{
  color:green;
}
</style>