<template>
  <div class="list-page-wrap events-page-wrap">
    <!-- <h1>This is an ProductList</h1> -->
    <div class="breadcrumb" style="background: url('https://picsum.photos/id/1035/1920/400')">
      <div class="container">
        <h1 class="title">Events</h1>
        <!--<p class="text">The racket was developed with the new "triple speed system frame" which combines exceptional power and great handling, and overall improve execution with power and more control. Designed with compact frame, which increases the speed in the swing, and overall improve execution with power and more control.</p>-->
      </div>
    </div>
    <div class="container">
      <div class="sub-categories">
        <div class="sub-item" v-for="event in events" :key="event.id">
          <div class="img-wrap">
            <img :src="base_url+event.image" alt="" class="sub-img">
            <div class="content">
              <div class="left">
                <p class="host">{{event.title}}</p>
                <div class="contact">
                  <p class="phone">phone : {{event.phone}}</p>
                  <p class="email">Email: {{event.email}}</p>
                </div>
              </div>
                <div class="right">
                  <div class="event-info">
                    <p class="location"> {{event.location}} </p>
                    <span class="date">{{new Date(event.date).getDate()}} {{new Date(event.date).toLocaleString('en-us', { month: 'long' })}} {{new Date(event.date).getFullYear()}}</span>
                  </div>
                  <button class="btn btn-primary contact-us-btn" v-if="event.youtube_link != null" @click.prevent="setEvent(event.id)" data-bs-toggle="modal" data-bs-target="#enquiryModal">Match Link<i class='bx bx-right-arrow-alt' ></i></button>
                  <a :href="base_url+event.brochure" target="_blank" download class="download-btn btn">Download <i class='bx bx-down-arrow-alt' ></i></a>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mb-5" v-if="total>1">
      <v-pagination
        v-model="fromPage"
        :pages="total"
        :range-size="1"
        active-color="#DCEDFF"
        @update:modelValue="getEvents"
      />
    </div>
    <div class="brand-carousel">
      <!--<brandsCarousel :Listdata="brands"/>-->
    </div>
  </div>
<!-- Modal -->

<div class="modal fade auth-modal organize-modal" id="enquiryModal" tabindex="-1" aria-labelledby="OrganizeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="AuthModalLabel">Contact Us</h5>
        <button type="button" class="btn-close" id="EventModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
            <div class="login-form">
              <form @submit.prevent="submitEventEnquiryForm">
                <p id="error">{{error_message}}</p>
                <div class="row form-group">
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Name" v-model="form2.name">
                  </div>
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Phone" v-model="form2.phone">
                  </div>
                </div>
                <div class="mb-3 icon-input">
                  <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Email" v-model="form2.email">
                </div>
                <button class="btn btn-primary login-btn">
                  <span v-if="!showLoad">
                    Submit
                    <i class="bx bx-right-arrow-alt"></i>
                  </span>
                  <div v-if="showLoad" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
             
              </form>
            </div>
      </div>
    </div>
  </div>
</div>
  
</template>

<script>
// import brandsCarousel from '@/components/brandsCarousel.vue'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import axios from 'axios'

export default({
  name: 'OrganizeList',
  components: {
      // brandsCarousel,
      VPagination
  },
  data (){

    return{
      showLoad:false,
      brands :[],
      events:[],
      base_url:'https://admin.badmintonsouq.com',
      total:0,
      fromPage:1,
      perPage: 10,
      form2:{
        name:'',
        phone:'',
        email:''
      },
      event_id:''
    }
  },
  created(){
    // this.getBrands();
    this.getEvents();
  },
  methods: {
    // getBrands(){
    //   axios.post('https://admin.badmintonsouq.com/api/v1/get-partners', {})
    //   .then((response) => {
    //       this.brands = response.data.data.partners
    //   })
    //   .catch(function (error) {
    //       console.log(error);
    //   })
    // },
    getEvents(page=1){
      axios.post('https://admin.badmintonsouq.com/api/v1/get-events', {
        page: page,
        per_page: this.perPage
      })
      .then((response) => {
          this.fromPage = page;
          this.events   = response.data.data.events.events
          this.total    = response.data.data.events.total_pages != undefined ? response.data.data.events.total_pages : 0
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    setEvent(event_id){
      this.event_id = event_id
    },
    submitEventEnquiryForm(){
        this.showLoad = true
        axios.post('https://admin.badmintonsouq.com/api/v1/create-event-enquiry', {
          name:this.form2.name,
          email:this.form2.email,
          phone:this.form2.phone,
          event_id:this.event_id
        })
        .then((response) => {
          this.showLoad = false
          if(response.data.success == false){
            this.error_message = response.data.message
          }else{
            this.$swal.fire('Success!','<table class="table spec-table table-striped">'+
                                          '<tbody>'+
                                            '<tr>'+
                                              '<th><i class="bx bxl-youtube" ></i> Match Link</th>'+
                                            '</tr>'+
                                            '<tr>'+
                                              '<td><a href="'+response.data.data.youtube_link+'" target="_blank">'+response.data.data.youtube_link+'</a></td>'+
                                            '</tr>'+
                                          '</tbody>'+
                                        '</table>'
            ,"success")
            .then(() => {
              document.getElementById("EventModalClose").click();
              this.form2.name = ''
              this.form2.phone = ''
              this.form2.email = ''
            })
          }
        })
        .catch(function (error) {
            console.log(error);
        })
      }
  }
})
</script>
