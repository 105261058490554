<template>
  <div class="list-page-wrap">
    <div
      class="breadcrumb"
      style="background: url('https://picsum.photos/id/1035/1920/400')"
    >
      <div class="container">
        <h1 class="title">Sitemap</h1>
        <!-- <p class="text">The racket was developed with the new "triple speed system frame" which combines exceptional power and great handling, and overall improve execution with power and more control. Designed with compact frame, which increases the speed in the swing, and overall improve execution with power and more control.</p> -->
      </div>
    </div>
    <div class="container mb-5 mt-5">
      <div class="about">
          <ul class="sitemap_list">

            <li><a href="https://badmintonsouq.com/" title="Home"> Home</a></li>

            <li>
              <a href="https://badmintonsouq.com/main-category/mens" title="Men"
                >Men</a
              >
            </li>
            <li>
              <a
                href="https://badmintonsouq.com/main-category/junior"
                title="Junior"
                >Junior</a
              >
            </li>
            <li>
              <a
                href="https://badmintonsouq.com/main-category/women"
                title="Woman"
                >Woman</a
              >
            </li>

            <li>
              <a href="https://badmintonsouq.com/brands" title="Brand">Brand</a>
            </li>
            <li>
              <a href="https://badmintonsouq.com/clearance" title="Offers"
                >Offers</a
              >
            </li>
            <li>
              <a
                href="https://badmintonsouq.com/category/rackets"
                title="Rackets"
                >Rackets</a
              >
            </li>
            <li>
              <a
                href="https://badmintonsouq.com/contact"
                title="Help and Support"
                >Help and Support</a
              >
            </li>
            <li>
              <a
                href="https://badmintonsouq.com/category/shuttlecocks"
                title="Shuttlecocks"
                >Shuttlecocks</a
              >
            </li>
            <li>
              <a href="https://badmintonsouq.com/category/shoes" title="Shoes"
                >Shoes</a
              >
            </li>
            <li>
              <a href="https://badmintonsouq.com/category/bags" title="Bags"
                >Bags</a
              >
            </li>
            <li>
              <a
                href="https://badmintonsouq.com/category/apparels"
                title="Apparels"
                >Apparels</a
              >
            </li>
            <li>
              <a
                href="https://badmintonsouq.com/category/accessories"
                title="Accessories"
                >Accessories</a
              >
            </li>
            <li>
              <a
                href="https://badmintonsouq.com/category/equipments"
                title="Equipments"
                >Equipments</a
              >
            </li>
            <li>
              <a
                href="https://badmintonsouq.com/category/strings"
                title="Strings"
                >Strings</a
              >
            </li>
            <li>
              <a href="https://badmintonsouq.com/category/grips" title="Grips"
                >Grips</a
              >
            </li>
            <li>
              <a
                href="https://badmintonsouq.com/category/trophies"
                title="Trophies"
                >Trophies</a
              >
            </li>
            <li>
              <a
                href="https://badmintonsouq.com/coaching-centres"
                title="Courts & Academies"
                >Courts & Academies</a
              >
            </li>
            <li>
              <a
                href="https://badmintonsouq.com/personal-trainers"
                title="Personal Trainers"
                >Personal Trainers</a
              >
            </li>
            <li>
              <a
                href="https://badmintonsouq.com/polling-agents"
                title="Queuing/Polling"
                >Queuing/Polling</a
              >
            </li>
            <li>
              <a
                href="https://badmintonsouq.com/organize"
                title="Tournament Organizers"
                >Tournament Organizers</a
              >
            </li>
            <li>
              <a href="https://badmintonsouq.com/events" title="Events"
                >Events</a
              >
            </li>
          </ul>
      </div>
    </div>
  </div>
</template>
<style>
.sitemap_list{
  column-count: 4;
}

.sitemap_list li{
  display: list-item;
}

.sitemap_list li a{
  text-decoration: none;
  color: black;
  font-size: 20px;
}



</style>
<script>
export default {
  name: "Sitemap",
  data() {
    return {};
  },
};
</script>