<template>
    <div class="list-page-wrap">
        <div class="breadcrumb" style="background: url('https://picsum.photos/id/1035/1920/400')">
            <div class="container">
                <h1 class="title">Brands</h1>
                <!-- <p class="text">The racket was developed with the new "triple speed system frame" which combines exceptional power and great handling, and overall improve execution with power and more control. Designed with compact frame, which increases the speed in the swing, and overall improve execution with power and more control.</p> -->
            </div>
        </div>
        <div class="container">
            <div class="sub-categories">
                <div class="row">
                    <div class="col-lg-4 col-sm-4 col-md-4" v-for="brand in brands" :key="brand">
                        <a :href="$router.resolve({path: '/brands/'+brand.slug}).href">
                        <div class="sub-item" id="sub-item">
                            <div class="img-wrap">
                                <img :src="base_url+brand.image" alt="" class="sub-img">
                                <h3 class="title">{{brand.title}}</h3>
                            </div>
                        </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <!--<brandsCarousel :Listdata="partners"/>-->
        <div class="container mb-5" v-if="total>1">
        <v-pagination
            v-model="fromPage"
            :pages="total"
            :range-size="1"
            active-color="#DCEDFF"
            @update:modelValue="getBrands"
        />
        </div>
    </div>
</template>

<script>
// import brandsCarousel from '@/components/brandsCarousel.vue'
import axios from 'axios'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default({
  name: 'Brands',
  components: {
    //   brandsCarousel,
    VPagination
  },
  data (){

    return{
      brands :[],
      partners:[],
      base_url:'https://admin.badmintonsouq.com',
      total:0,
      fromPage:1,
      perPage: 20,
      slug:''
    }
  },
  created(){
    // this.getPartners();
    this.getBrands();
  },
  mounted(){
    
  },
  methods:{
    // getPartners(){
    //   axios.post('https://admin.badmintonsouq.com/api/v1/get-partners', {})
    //   .then((response) => {
    //       this.partners = response.data.data.partners
    //   })
    //   .catch(function (error) {
    //       console.log(error);
    //   })
    // },
    getBrands(page = 1){
        axios.post('https://admin.badmintonsouq.com/api/v1/get-brands', {
            page: page,
            per_page: this.perPage,
            slug:this.slug
        })
        .then((response) => {
          this.brands       = response.data.data.brands.brands
          this.fromPage     = page;
          this.total        = response.data.data.brands.total_pages != undefined ? response.data.data.brands.total_pages : 0
        })
        .catch(function (error) {
            console.log(error);
        })
    }
  }
})
</script>

<style>
    #sub-item{
        margin-bottom: 2rem;
    }
</style>