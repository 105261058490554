<template>
    <div class="list-page-wrap">
        <div class="breadcrumb" style="background: url('https://picsum.photos/id/1035/1920/400')">
            <div class="container">
                <h1 class="title">Terms & Conditions</h1>
                <!-- <p class="text">The racket was developed with the new "triple speed system frame" which combines exceptional power and great handling, and overall improve execution with power and more control. Designed with compact frame, which increases the speed in the swing, and overall improve execution with power and more control.</p> -->
            </div>
        </div>
        <div class="container mb-5 mt-5">
            <div class="about">
                <b>Before choosing a string and a tension, there are a few key points to understand:</b>
                <ul>
                    <li>Every racket has a ‘sweet-spot’ on the string-bed; this is the area that gives you the most power and speed for your shots.</li>
                    <li>As you increase the tension of your strings, the sweet-spot decreases on the string-bed. As beginners and more casual players are more likely to mis-hit the shuttle and miss the sweet-spot, a higher tension generally results in LESS power for these players.</li>
                    <li>Conversely, as you decrease the tension of your strings, the sweet-spot increases on the string-bed, making lower tensions more suitable for beginners and casual players.</li>
                    <li>As players become more consistent and can hit the sweet-spot reliably, a higher tension will provide control and allow the shuttle to travel faster off smaller, but more powerful sweet spot.</li>
                    <li>Mishits towards the edge of the frame significantly increase the risk of the strings breaking during play.</li>
                    <li>Thicker strings are generally more durable than thinner strings, and are therefore less likely to break.</li>
                    <li>Each racket has a recommended stringing range; stringing outside of this range may damage your racket.</li>
                    <li>Playing with different types of shuttles affects the performance of your strings.</li>
                    <li>There is no particular tension that always works well with a particular string, or vice-versa; choosing a string and tension is dependent on your style of play and your technique.</li>
                    <li>String tension is as important as the string you choose.</li>
                </ul>
                <b>Suggested Tension for Players</b>
                <p>The most common mistake for players is to ask for the strings at too high of a tension, often due to ego or mistakenly believing that higher tensions offer more power. As players have to work harder to generate power at higher tensions, they are more likely to cause themselves injury by using too much force.<br>
                <b>Choosing the correct tension is ultimately a responsibility for each individual player, and there is no single tension that is correct for everyone. However, we have suggested ranges for different levels of player below:</b></p>
                <ul>
                    <li>Casual: 20-23 lbs</li>
                    <li>Regular Club: 24-25 lbs</li>
                    <li>Advanced/County: 26 lbs and above</li>
                </ul>
                <p>The majority of rackets with factory strings are strung between 20 and 23lbs, making them already suitable for the majority of badminton players.<br>
                Playing with plastic shuttles, while cheaper than feathers, degrades your strings considerably quicker and requires you to put in more effort to achieve power. Therefore, we recommend that plastic shuttle users lower their tension by a further 1 or 2lbs to provide the necessary extra power.</p>
                <h5>Stringing Tensions</h5>
                <p>Almost every badminton racket will include a ‘recommended stringing tension range' on the racket, though manufacturers don't offer any further guidance on which tension suits different players. 
                Stringing tensions affect your game as much as a new racket will, and it is crucial to understand how adjusting the string tension affects your performance.</p> 
                <h5>Lower Tensions</h5>
                <b>Choosing a lower tension results in the following changes to your performance:</b>
                <ul>
                    <li>An increased sweet-spot, making it easier to hit the sweet-spot and consistently hit powerful shots.</li>
                    <li>A softer feel on the string-bed, resulting in less feel of the shuttle and therefore less control, but also increased repulsion from the strings, resulting in more easily accessible power.</li>
                    <li>More durability and a slightly decreased risk of breaking the strings from off-centre mis-hits.</li>
                    <li>More shock absorption from the string-bed and decreased vibrations reaching your arm.</li>
                </ul>
                <p>Therefore, lower tensions are more suitable for players that want more power, and for players that don't yet consistently hit the sweet-spot on the string-bed.</p>
                <h5>Higher Tensions</h5>

                <b>Choosing a higher tension results in the following changes to your performance:</b>
                <ul>
                    <li>A decreased sweet-spot, requiring the player to more consistent and accurate with their strokes in order to consistently hit the sweet-spot and generate power.</li>
                    <li>A harder feel on the string-bed, resulting in more feel of the shuttle and therefore more control, but also decreased repulsion from the strings, meaning that the player will have to work much harder to generate power.</li>
                    <li>Less durability and an increased risk of breaking the strings from off-centre mis-hits.</li>
                    <li>Less shock absorption from the string-bed and more vibrations due to the harder feel of the string-bed.</li>
                    <li>As most club players will have to work harder to generate power, there is a much greater chance of causing yourself injury by over-exerting yourself.</li>
                </ul>
                <p>Therefore, higher tensions are suitable for players with advanced technique that can already generate their own power consistently.</p>
                <h5>Terms & Conditions</h5>
                <ul>
                    <li>We don't offer any warranty on any restringing services, as strings wear down from usage.</li>
                    <li>We don't offer any warranty on any bat repair, as rackets may be broken again while usage.</li>
                    <li>We don't re-string rackets with signs of damage to the frame or rackets.</li>
                    <li>If you request your racket to be restrung outside of the tension range recommended by the manufacturer, Badminton Souq will not be responsible or liable for any damage caused to the racket by restringing.</li>
                    <li>We reserve the right to reject any racket any time. If payment has been taken before the racket is rejected, you will be given a refund.</li>
                </ul>
                <b>Thank you,
                Team Badminton Souq</b>
            </div>
        </div>
    </div>
</template>

<script>

export default({
  name: 'Terms & Conditions',
  data (){

    return{

    }
  }
})
</script>