<template>
  <div class="deatil-page-wrap">
    <!-- <h1>This is an ProductList</h1> -->
    <div class="breadcrumb" v-if="!showLoading">
      <div class="container">
        <div class="left-sec">
          <a href="#" @click.prevent="this.$router.go(-1)" class="back-link"><i class='bx bx-left-arrow-alt' ></i></a>
          <h1 class="title">{{product.category.title}}</h1>
        </div>
        <ul class="links">
          <li class="link"><a href="/">Home</a></li>
          <li class="link">/ {{product.category.title}}</li>
        </ul>
      </div>
    </div>
    
     <!-- -->
    <!-- Shimmer Loading -->
    <div class="product-detail-sec"  v-if="showLoading">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="product-image-sec">
              <free-style-shimmer
              :is-loading="showLoading"
                height="500px"
                width="500px"
                border-radius="10px"
                color="#bdbdbd"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="product-detail-info">
                  <free-style-shimmer
                    height="30px"
                    width="40%"
                    border-radius="10px"
                    color="#bdbdbd"
                  />
                  <br>
              <free-style-shimmer
                height="70px"
                width="70%"
                border-radius="10px"
                color="#bdbdbd"
              />
              <br>
               <ParagraphShimmer />
              
              <br>
              <free-style-shimmer
                height="70px"
                width="30%"
                border-radius="10px"
                color="#bdbdbd"
              />
        </div>
      </div>
      </div>
    </div>
    </div>

    <div class="product-detail-sec" v-if="!showLoading">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="product-image-sec">
              <img :src="product.image" alt="" v-if="productImages.length == 0">
              <SingleProductCarousel 
              :Listdata="productImages" />
            </div>
          </div>
            
              <div class="col-lg-6">
                <div class="product-detail-info">
                  <div class="brand-sec">
                    <p class="name">{{product.brand.title}}</p>
                    <img :src="base_url+product.brand.image" alt="" class="brand-image">
                  </div>
                  <h2 class="pro-name">{{product.title}}</h2>
                  <div class="star-rate-sec">
                    <star-rating 
                    :rating="product.rating" 
                    :read-only="true" 
                    :increment="0.5" 
                    :star-size="20"
                    inactive-color="#E1E1E1" 
                    active-color="#000052"
                    :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"></star-rating>
                    <p class="counts">({{product.reviews.length}} rate & reviews)</p>
                  </div>
                  <div class="price-sec">
                    <p class="old-price">AED {{product.mrp}}</p>
                    <p class="new-price">AED {{product.sale_cost}}</p>
                    <!-- <span class="offer-badge">50% Off</span> -->
                  </div>

                  <template v-if="product.is_active === 1">
                  <div class="pro-other-info">
                    <div>
                      <p class="text"><span class="stock" v-if="product.stock_status == 1">In stock</span><span class="stock" v-else>Out of stock</span></p>
                      <p class="text delivery-text" v-if="product.shipping_charge == 0"><i class='bx bxs-truck' ></i> FREE SHIPPING</p>
                      <p class="text delivery-text" v-else><i class='bx bxs-truck' ></i>Shipping Charge: AED {{product.shipping_charge}}</p>
                    </div>
                    <!--<div class="seller-info">
                      <i class='icon bx bxs-building' ></i>
                      <div>
                        <span>Sold by</span>
                        <p class="name">{{product.vendor.name}}</p>
                      </div>
                    </div>-->
                  </div>

                    <div class="product-attr" v-if="product.variant.length != 0">
                      <div class="single-attr" v-if="color_variants != null">
                          <h4 class="title">Select your Color</h4>
                          <div class="attr-items">
                            <div class="form-check form-check-inline" v-for="variant in color_variants" :key="variant.id">
                              <input class="form-check-input" type="radio" v-if="variant.color_id == product.color_id" checked name="inlineRadioOptions1" :id="'color'+variant.id" v-on:click="calculatePrice('color',variant.color_id)">
                              <input class="form-check-input" type="radio" v-else name="inlineRadioOptions1" :id="'color'+variant.id" v-on:click="calculatePrice('color',variant.color_id)">
                              <label class="form-check-label" :for="'color'+variant.id" v-if="variant.image != null">
                                  <img :src="variant.image" alt="" class="thump">
                              </label>
                              <label class="form-check-label" :for="'color'+variant.id" v-else :style="{ 'background-color': variant.color.color}">
                              </label>
                            </div>
                          </div>
                      </div>
                      <div class="single-attr" v-if="size_variants != null">
                          <h4 class="title">Select your Size</h4>
                          <div class="attr-items">
                            <div class="form-check form-check-inline" v-for="variant in size_variants" v-bind:value="variant.size_id" :key="variant.id">
                              <input class="form-check-input" type="radio" name="inlineRadioOptions2" v-if="variant.size_id == product.size_id" checked :id="'size'+variant.id" v-on:click="calculatePrice('size',variant.size_id)">
                              <input class="form-check-input" type="radio" name="inlineRadioOptions2" v-else :id="'size'+variant.id" v-on:click="calculatePrice('size',variant.size_id)">
                              <label class="form-check-label" :for="'size'+variant.id">
                                <p>{{variant.size.size}}</p>
                              </label>
                            </div>
                          </div>
                      </div>
                    </div>
                    <div class="product-actions" v-if="addon_reference_id != null && product.category.title == 'Strings'">
                        <select class="form-select" aria-label="Default select example" v-model="tension_id">
                          <option :key="0" v-bind:value="0" selected>Select your Tension</option>
                          <option v-for="tension in tensions" :key="tension.id" v-bind:value="tension.id">{{tension.name}}</option>
                        </select>
                    </div>
                    <div class="product-actions" v-if="addon_reference_id != null && product.category.title == 'Strings'">
                        <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Aditional Requirements..." rows="4" v-model="additional_requirements"></textarea>
                    </div>
                  
                    <div class="product-actions" v-if="product.category.title != 'Trophies' && product.category.title != 'Rackets' && product.category.title != 'Shoes'">
                      <!--<div class="qty-container">
                        <button class="btn btn-min" @click="count--"><i class='bx bx-minus' ></i></button>
                        <input type="number" class="form-control" id="" placeholder="" :value="count">
                        <button class="btn btn-add" @click="count++"><i class='bx bx-plus' ></i></button>
                      </div> -->
                        <button v-if="token != null" class="btn btn-primary add-to-cart" @click.prevent="addToCart"><i class='bx bxs-cart-alt'></i> Add to Cart <i class='bx bx-right-arrow-alt' ></i></button>
                        <button class="btn btn-primary add-to-cart" v-else data-bs-toggle="modal" data-bs-target="#AuthModal"><i class='bx bxs-cart-alt'></i> Add to Cart <i class='bx bx-right-arrow-alt' ></i></button>
                    </div>
                    <div class="product-actions" v-if="product.category.title == 'Trophies'">
                      <button v-if="token != null" class="btn btn-primary add-to-cart" data-bs-toggle="modal" data-bs-target="#LabelModal1"><i class='bx bxs-cart-alt'></i> Add to Cart <i class='bx bx-right-arrow-alt' ></i></button>
                      <button class="btn btn-primary add-to-cart" v-else data-bs-toggle="modal" data-bs-target="#AuthModal"> <i class='bx bxs-cart-alt'></i> Add to Cart <i class='bx bx-right-arrow-alt' ></i></button>
                    </div>
                    <div class="product-actions" v-if="product.category.title == 'Rackets'">
                      <button v-if="token != null" class="btn btn-primary add-to-cart" data-bs-toggle="modal" data-bs-target="#LabelModal3" @click.prevent="addToCart"><i class='bx bxs-cart-alt'></i> Add to Cart <i class='bx bx-right-arrow-alt' ></i></button>
                      <button class="btn btn-primary add-to-cart" v-else data-bs-toggle="modal" data-bs-target="#AuthModal"> <i class='bx bxs-cart-alt'></i> Add to Cart <i class='bx bx-right-arrow-alt' ></i></button>
                    </div>
                    <div class="product-actions" v-if="product.category.title == 'Shoes'">
                      <button v-if="token != null" class="btn btn-primary add-to-cart" data-bs-toggle="modal" data-bs-target="#LabelModal4" @click.prevent="addToCart"><i class='bx bxs-cart-alt'></i> Add to Cart <i class='bx bx-right-arrow-alt' ></i></button>
                      <button class="btn btn-primary add-to-cart" v-else data-bs-toggle="modal" data-bs-target="#AuthModal"> <i class='bx bxs-cart-alt'></i> Add to Cart <i class='bx bx-right-arrow-alt' ></i></button>
                    </div>

                  </template>
                  <template v-else>
                      <p>The product is no longer active.</p>
                  </template>
                </div>
              </div>
      
        </div>
      <div class="product-overview-details">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Information</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Specification</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Reviews</button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
            <div class="description">
              <div v-html="product.description"></div>
              <table class="table spec-table table-striped" v-if="product.item_parameter.length != 0">
                <tbody>
                  <tr v-for="parameter in product.item_parameter" :key="parameter.id">
                    <td>{{parameter.parameter.title}}</td>
                    <td>{{parameter.value}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
          <div v-html="product.specification"></div>
          <table class="table spec-table table-striped" v-if="product.item_parameter.length != 0">
            <tbody>
              <tr v-for="parameter in product.item_parameter" :key="parameter.id">
                <td>{{parameter.parameter.title}}</td>
                <td>{{parameter.value}}</td>
              </tr>
            </tbody>
          </table>
          </div>
          <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
            <!-- {{product.reviews}} -->
            
              <div class="reviews-list-wrap product-reviews-list">
                <!-- <h4 class="title">RATES & REVIEWS</h4> -->
                <div class="top-sec">
                  <div class="overall-star-rate">
                    <i class='bx bxs-star star-icon'></i>
                    <span class="rating">{{product.rating}}</span>
                    <p class="count">{{product.reviews.length}} (Rating & Reviews)</p>
                  </div>
                  <div class="right">
                    <a href="#" data-bs-toggle="modal" v-if="token != null" data-bs-target="#reviewModal" class="link-btn">Write a Review</a>
                    <a href="#" data-bs-toggle="modal" v-else data-bs-target="#AuthModal" class="link-btn">Write a Review</a>
                    <a @click="getProduct" class="link">More</a>
                  </div>
                </div>
                <ul class="list">
                  <li class="item" v-for="review in product.reviews" :key="review.id">
                  <div class="text">
                    <p>{{review.review}}</p>
                  </div>
                  <div class="bottom-sec">
                    <div class="left">
                      {{review.user.name}} - &nbsp;
                      <star-rating 
                      :rating="review.rating" 
                      :read-only="true" 
                      :increment="0.5" 
                      :star-size="16"
                      :show-rating="false"
                      inactive-color="#E1E1E1" 
                      active-color="#000052"
                      :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"></star-rating>
                    </div>
                    <p class="date">{{new Date(review.date).getDate()}} {{new Date(review.date).toLocaleString('en-us', { month: 'long' })}} {{new Date(review.date).getFullYear()}}</p>
                  </div>
                    
                  </li>
                </ul>
              </div>
            </div>
        </div>
      </div>
      </div>
    </div>


    
    <div class="bestselling">
      <div class="container">
        <div class="top-sec">
          <h3 class="title">You may like also</h3>
        </div>
      </div>
      <ProductCarousel :Listdata="productLists" :loadingdata ="showLoading"/>
    </div>
    
    <div class="brand-carousel">
      <div class="container">
      </div>
      <!--<brandsCarousel :Listdata="partners"/>-->
    </div>
  </div>



  <!-- Rate and Review Modal -->
  <div class="modal fade auth-modal organize-modal review-modal" id="reviewModal" tabindex="-1" aria-labelledby="OrganizeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="AuthModalLabel">Your Review</h5>
          <button type="button" class="btn-close" id="ReviewModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
              <div class="login-form">
                <form @submit.prevent="submitReviewForm">
                  <div class="mb-3">
                    <textarea class="form-control" id="exampleFormControlTextarea1" v-model="form.review" placeholder="comments (name: additional requirements)" rows="4" required></textarea>
                  </div>
                  <div class="mb-3 star-rating">
                    <star-rating text-class="rating-text"
                    :rating="0" 
                    :read-only="fade" 
                    :increment="0.5" 
                    :star-size="30"
                    :show-rating="true"
                    inactive-color="#E1E1E1" 
                    active-color="#000052"
                    v-model:rating="form.rating"
                    :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"></star-rating>
                  </div>
                  <!--<a href="#" data-bs-toggle="modal" data-bs-target="#SuccessModal">-->
                  <button class="btn btn-primary login-btn">
                    <span v-if="!showLoad">
                      Submit
                      <i class="bx bx-right-arrow-alt"></i>
                    </span>
                    <div v-if="showLoad" class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                  <!--</a>-->
                </form>
              </div>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
      </div>
    </div>
  </div>

  <!-- Label Modal -->
  <div class="modal fade auth-modal success-modal" id="LabelModal1" tabindex="-1" aria-labelledby="OrganizeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="AuthModalLabel"></h5>
          <button type="button" class="btn-close" id="LabelModal1Close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
              <div class="message-content">
                <p class="text">Do you want to add labels for your trophies/medals?</p> 
                <div style="display:flex;">
                  <a href="#" data-bs-dismiss="modal" aria-label="Close" class="btn btn-primary login-btn" @click.prevent="addToCart()">No <i class="bx bx-left-arrow-alt"></i></a>
                  <a href="#" data-bs-toggle="modal" data-bs-target="#LabelModal2" @click.prevent="addLabel()" class="btn btn-primary login-btn">Yes <i class="bx bx-right-arrow-alt"></i></a>
                </div>
              </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Racket Modal -->
  <div class="modal fade auth-modal success-modal" id="LabelModal3" tabindex="-1" aria-labelledby="OrganizeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="AuthModalLabel"></h5>
          <button type="button" class="btn-close" id="LabelModal3Close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
              <div class="message-content">
                <p class="text">Wish to do stringing...?</p> 
                <div style="display:flex;">
                  <a href="#" data-bs-dismiss="modal" aria-label="Close" class="btn btn-primary login-btn">No <i class="bx bx-left-arrow-alt"></i></a>
                  <a :href="'/string-grip?addon_reference_id='+product.id" class="btn btn-primary login-btn">Yes <i class="bx bx-right-arrow-alt"></i></a>
                </div>
              </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Shoe Modal -->
  <div class="modal fade auth-modal success-modal" id="LabelModal4" tabindex="-1" aria-labelledby="OrganizeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="AuthModalLabel"></h5>
          <button type="button" class="btn-close" id="LabelModal4Close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
              <div class="message-content">
                <p class="text">Wish to add socks or gel pad...?</p> 
                <div style="display:flex;">
                  <a href="#" data-bs-dismiss="modal" aria-label="Close" class="btn btn-primary login-btn">No <i class="bx bx-left-arrow-alt"></i></a>
                  <a :href="'/socks-gel-pad?addon_reference_id='+product.id" class="btn btn-primary login-btn">Yes <i class="bx bx-right-arrow-alt"></i></a>
                </div>
              </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade auth-modal organize-modal" id="LabelModal2" tabindex="-1" aria-labelledby="OrganizeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="AuthModalLabel">Enter Your Label</h5>
          <button type="button" class="btn-close" id="LabelModal2Close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
              <div class="login-form">
                <form @submit.prevent="addToCart()">
                  <p>(labeling charge will be added)</p>
                  <p>Labeling Charge: {{addon_charge}}</p>
                  <div class="mb-3">
                    <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Label Name" rows="4" v-model="addon_label" required></textarea>
                  </div>
                  <button class="btn btn-primary login-btn" type="submit">
                    <span v-if="!showLoad">
                      Submit <i class="bx bx-right-arrow-alt"></i>
                    </span>
                    <div v-if="showLoad" class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                </form>
              </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
// import brandsCarousel from '@/components/brandsCarousel.vue'
import StarRating from 'vue-star-rating'
import ProductCarousel from '@/components/ProductCarousel.vue'
import SingleProductCarousel from '@/components/SingleProductCarousel.vue'
import axios from 'axios'
import { mapActions } from "vuex";
// import {mapMutations } from 'vuex';
// import {
//     LOADING_SPINNER_SHOW_MUTATION,
// } from '../store/storeconstants';

import _ from 'lodash'
export default({
  name: 'productList',
  components: {
      // brandsCarousel,
      ProductCarousel,
      SingleProductCarousel,
      StarRating
  },
  data (){

    return{
      showLoading: false,
      showLoad:false,
      count: 1,
      partners :[],
      tensions:[],
      size_variants:null,
      color_variants:null,
      product:[],
      productLists:[],
      productImages :[],
      base_url:'https://admin.badmintonsouq.com',
      color_id:'',
      size_id:'',
      addon_charge:0.00,
      addon_label:'',
      token:'',
      form:{
        review:'',
        rating:''
      },
      error_message:'',
      commentsToShow:2,
      addon_reference_id:'',
      additional_requirements:'',
      tension_id:0,
      temp_color_id:null,
      temp_size_id:null
    }
  },
  mounted(){
    this.token              = this.$store.state.auth.token
    this.addon_reference_id = this.$route.query.addon_reference_id
  },
  created(){
    this.getProduct()
    this.getTensions()
    // this.getPartners()
  },
  methods: {
    // ...mapMutations({
    //     showLoading: LOADING_SPINNER_SHOW_MUTATION,
    // }),
    async getProduct() {
      // this.showLoading(true);
      this.showLoading = true;
      await axios.post('https://admin.badmintonsouq.com/api/v1/get-product-details', {
        slug:this.$route.params.slug,
        limit:this.commentsToShow
      })
      .then((response) => {
        // this.showLoading(true);
        // this.showLoading = true;
        this.product = response.data.data.product
        this.productImages = response.data.data.product.gallery
        this.productLists = response.data.data.similar_products

        if(this.product.size_id != null)
        {
          this.temp_size_id  = this.product.size_id
          this.size_variants = _.uniqBy(response.data.data.product.variant, function(u) {
            return u.size_id;
          });
          console.log(this.size_variants)
        }
        if(this.product.color_id != null)
        {
          this.temp_color_id  = this.product.color_id
          this.color_variants = _.uniqBy(response.data.data.product.variant, function(u) {
            return u.color_id;
          });
        }
        if(this.product.variant.length > 0)
        {
          this.size_id = this.product.size_id
          this.color_id = this.product.color_id
        }
        this.commentsToShow += 2
      })
      .catch(function (error) {
          console.log(error);
          // this.showLoading(false);
          this.showLoading = false;
      })
      // this.showLoading(false);
      this.showLoading = false;
    },
    // getPartners(){
    //   axios.post('https://admin.badmintonsouq.com/api/v1/get-partners', {})
    //   .then((response) => {
    //       this.partners = response.data.data.partners
    //   })
    //   .catch(function (error) {
    //       console.log(error);
    //   })
    // },
    calculatePrice(key,value)
    {
      if(key == 'color'){
        // if(this.product.size_id == this.size_id)
        // {
          this.size_id = null
        // }
        this.color_id = value
      }
      else if(key == 'size'){
        // if(this.product.color_id == this.color_id)
        // {
        //   this.color_id = null
        // }
        this.size_id = value
      }
      axios.post('https://admin.badmintonsouq.com/api/v1/calculate-product-price', {
        color_id: this.color_id,
        size_id: this.size_id,
        item_id:this.product.id
      })
      .then((response) => {
          this.product.mrp       = parseFloat(response.data.data.price.mrp).toFixed(2)
          this.product.sale_cost = parseFloat(response.data.data.price.sale_cost).toFixed(2)
          if(response.data.data.colors != null)
          {
            this.product.size_id = null
            this.size_variants   = _.uniqBy(response.data.data.colors, function(u) {
              return u.size_id;
            });
          }
          // if(response.data.data.sizes != null)
          // {
          //   this.product.color_id = null
          //   this.color_variants   = _.uniqBy(response.data.data.sizes, function(u) {
          //     return u.color_id;
          //   });
          // }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    ...mapActions(["CartCount"]),
    getCartCount(){
      this.token = this.$store.state.auth.token
      if(this.token != null)
      {
        axios.post('https://admin.badmintonsouq.com/api/v1/cart-count',{}, {
          headers: {
              Authorization  : 'Bearer ' + this.token
          }
        })
        .then((response) => {
            this.CartCount(response.data.data.count)
        })
        .catch(function (error) {
            console.log(error);
        })
      }
    },
    addLabel()
    {
      document.getElementById("LabelModal1Close").click();
      this.addon_charge = this.product.label_charge
    },
    addToCart(){
      if(this.temp_size_id != null && this.size_id == null){
        this.$toast.show('Select a size',{
          type: 'error',
          position: 'top-right',
          duration: '2000'
        })
      }
      // else if(this.temp_color_id != null && this.color_id == null){
      //   this.$toast.show('Select a color',{
      //     type: 'error',
      //     position: 'top-right',
      //     duration: '2000'
      //   })
      // }
      else{
        axios.post('https://admin.badmintonsouq.com/api/v1/cart/increment', {
            item_id:this.product.id,
            color_id: this.color_id,
            size_id: this.size_id,
            addon_charge: this.addon_charge,
            addon_label: this.addon_label,
            addon_reference_id:this.addon_reference_id,
            tension_id:this.tension_id,
            additional_requirements: this.additional_requirements
        },
        {
          headers: {
              Authorization  : 'Bearer ' + this.token
          }
        })
        .then((response) => {
          if(response.data.success == true){
            this.$toast.show(response.data.message,{
              type: 'success',
              position: 'top-right',
              duration: '2000'
            })
            this.getCartCount()
            this.addon_charge = 0.00
            this.addon_label  = ''
            this.tension_id = 0
            this.additional_requirements = ''
            document.getElementById("LabelModal1Close").click();
            document.getElementById("LabelModal2Close").click();
          }else{
            this.$toast.show(response.data.message,{
              type: 'error',
              position: 'top-right',
              duration: '2000'
            })
          }
        })
        .catch(function (error) {
            console.log(error);
        })
      }
    },
    submitReviewForm(){
      this.showLoad = true
      axios.post('https://admin.badmintonsouq.com/api/v1/create-product-review', {
          item_id:this.product.id,
          review:this.form.review,
          rating:this.form.rating
      },
      {
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
        this.showLoad = false
        if(response.data.success == true){
          this.$toast.show(response.data.message,{
            type: 'success',
            position: 'top-right',
            duration: '2000'
          })
          this.getProduct()
          document.getElementById("ReviewModalClose").click();
          this.form.review = ''
          this.form.rating = ''
        }else{
          this.$toast.show(response.data.message,{
            type: 'error',
            position: 'top-right',
            duration: '2000'
          })
        }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    getTensions(){
      axios.post('https://admin.badmintonsouq.com/api/v1/get-tensions', {})
      .then((response) => {
          this.tensions = response.data.data.tensions
      })
      .catch(function (error) {
          console.log(error);
      })
    }
  }
})
</script>
