<template>
    <div class="list-page-wrap">
        <div class="breadcrumb" style="background: url('https://picsum.photos/id/1035/1920/400')">
            <div class="container">
                <h1 class="title">Goods Return Policy</h1>
                <!-- <p class="text">The racket was developed with the new "triple speed system frame" which combines exceptional power and great handling, and overall improve execution with power and more control. Designed with compact frame, which increases the speed in the swing, and overall improve execution with power and more control.</p> -->
            </div>
        </div>
        <div class="container mb-5 mt-5">
            <div class="about">
                <p>Thank you for shopping at Badminton Souq.</p>
                <p>If you are not fully satisfied with your purchase, we are here to help.</p>
                <h5>Returns</h5>
                <ul>
                    <li>You have 14 calendar days to return an item from the date you receive.</li>
                    <li>At the point of delivery, unbox and check the item whether it is the item you ordered and damage free. If the delivered item is not the item you ordered or damaged, inform the same time to the delivery personnel and do not accept the delivery. They will assist you to arrange a free replacement or a refund.</li>
                    <li>To be eligible for a return or replacement, your item must be unused and in the same condition of how you received it. Your item must be in the original packaging.</li>
                </ul>
                <h5>Refunds</h5>
                <ul>
                    <li>Once we receive your item, we will thoroughly inspect it and notify you that we have received your retuned item.</li>
                    <li>We will immediately notify you on the status of your refund after inspecting the item.</li>
                    <li>If the return is approved, we will initiate the refund to your account.</li>
                    <li>You will receive the credit within a certain time or days, as per your card issuer’s policies.</li>
                </ul>
                <h5>Customized Products</h5>
                <ul>
                    <li>Customized products include products that are personalized, or inscribed; for example, trophies engravings, etching, misspelling, carving, or printing.</li>
                    <li>Due to personal configuration, inscription, or design on these products, not accept returns or exchanges unless the products are damaged/defective upon arrival, or materially different from what was ordered.</li>
                </ul>
                <h5>Shipping</h5>
                <ul>
                    <li>You will be responsible for paying for your own shipping cost for returning your item.</li>
                    <li>Shipping costs are non-refundable you receive a refund, the cost of return shipping will be deducted from your refund.</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

export default({
  name: 'Return Policy',
  data (){

    return{

    }
  }
})
</script>