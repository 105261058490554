<template>
<div class="container">
  <div class="special-cat-products products-carousel" v-if="loadingdata">

    <!-- Shimmer Loading -->

  <div class="product-list-wrap">
    <div class="product-item">
      <div class="carousel-item-single">
        <card-shimmer  :is-loading="loadingdata"/>
      </div>
     </div>
    <div class="product-item">
      <div class="carousel-item-single">
        <card-shimmer  :is-loading="loadingdata"/>
      </div>
     </div>
    <div class="product-item">
      <div class="carousel-item-single">
        <card-shimmer  :is-loading="loadingdata"/>
      </div>
     </div>
    <div class="product-item">
      <div class="carousel-item-single">
        <card-shimmer  :is-loading="loadingdata"/>
      </div>
     </div>
    <div class="product-item">
      <div class="carousel-item-single">
        <card-shimmer  :is-loading="loadingdata"/>
      </div>
     </div>
    <div class="product-item">
      <div class="carousel-item-single">
        <card-shimmer  :is-loading="loadingdata"/>
      </div>
     </div>
    <div class="product-item">
      <div class="carousel-item-single">
        <card-shimmer  :is-loading="loadingdata"/>
      </div>
     </div>
    <div class="product-item">
      <div class="carousel-item-single">
        <card-shimmer  :is-loading="loadingdata"/>
      </div>
     </div>
    <div class="product-item">
      <div class="carousel-item-single">
        <card-shimmer  :is-loading="loadingdata"/>
      </div>
     </div>
    <div class="product-item">
      <div class="carousel-item-single">
        <card-shimmer  :is-loading="loadingdata"/>
      </div>
     </div>
  </div>


  </div>

  <div class="special-cat-products products-carousel">

  <div class="product-list-wrap">
    <div class="product-item"
     v-for="(Listdata, index) in Listdata"
     :key="index"
     >
      <div class="carousel-item-single">
        <div class="pro-img">
          <a :href="$router.resolve({path: '/products/'+Listdata.slug}).href">
          <img :src="Listdata.image" /></a>
          <!-- <span class="offer">{{Listdata.offer}}</span> -->
        </div>
        <div class="pro-info">
          <span class="brand-n">{{Listdata.brand.title}}</span>
          <a :href="$router.resolve({path: '/products/'+Listdata.slug}).href">
          <p class="pro-name" v-if="Listdata.title.length<50">{{Listdata.title}}</p>
          <p class="pro-name" v-if="Listdata.title.length>50">{{Listdata.title.substring(0,50)+"..."}}</p>
          </a>
          <div class="rating" v-if="Listdata.rating != null">
            <star-rating 
            :rating="parseFloat(Listdata.rating)" 
            :read-only="true" 
            :increment="0.5" 
            :star-size="15"
            inactive-color="#E1E1E1" 
            active-color="#A5A5A5"
            :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"></star-rating>
            </div>
          <div class="price">
            <p class="old-p">{{Listdata.mrp}}</p>
            <p class="new-p">{{Listdata.sale_cost}}</p>
          </div>
          <div v-if="Listdata.category.title != 'Shoes' && Listdata.category.title != 'Rackets' && Listdata.category.title != 'Trophies'">
            <button v-if="token != null" class="btn add-to-cart" @click.prevent="addToCart(Listdata.id)"><i class='bx bxs-cart-alt'></i> Add to Cart</button>
            <button class="btn add-to-cart" v-else data-bs-toggle="modal" data-bs-target="#AuthModal"> <i class='bx bxs-cart-alt'></i> Add to Cart</button>
          </div>
          <div v-if="Listdata.category.title == 'Trophies'">
            <button v-if="token != null" class="btn add-to-cart" @click.prevent="askLabel(Listdata)" data-bs-toggle="modal" data-bs-target="#LabelModal1"><i class='bx bxs-cart-alt'></i> Add to Cart</button>
            <button class="btn add-to-cart" v-else data-bs-toggle="modal" data-bs-target="#AuthModal"> <i class='bx bxs-cart-alt'></i> Add to Cart</button>
          </div>
        </div>
      </div>
     </div>
  </div>
  </div>
  <!-- Label Modal -->
  <div class="modal fade auth-modal success-modal" id="LabelModal1" tabindex="-1" aria-labelledby="OrganizeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="AuthModalLabel"></h5>
          <button type="button" class="btn-close" id="LabelModal1Close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
              <div class="message-content">
                <p class="text">Do you want to add labels for your trophies/medals?</p> 
                <div style="display:flex;">
                  <a href="#" title="addToCart" data-bs-dismiss="modal" aria-label="Close" class="btn btn-primary login-btn" @click.prevent="addToCart(item.id)">No <i class="bx bx-left-arrow-alt"></i></a>
                  <a href="#" title="Badminton Souq" data-bs-toggle="modal" data-bs-target="#LabelModal2" @click.prevent="addLabel(item.label_charge)" class="btn btn-primary login-btn">Yes <i class="bx bx-right-arrow-alt"></i></a>
                </div>
              </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <h1 class="brand-special">{{Listdata.brand}}</h1> -->
    <!-- <div v-for="(Listdata) in Listdata" :key="Listdata.id" class="user">
      {{ Listdata.title }}
  </div> -->
  <!-- Modal -->
  <div class="modal fade auth-modal organize-modal" id="LabelModal2" tabindex="-1" aria-labelledby="OrganizeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="AuthModalLabel">Enter Your Label</h5>
        <button type="button" class="btn-close" id="LabelModal2Close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
            <div class="login-form">
              <form @submit.prevent="addToCart(item.id)">
                <p>(labeling charge will be added)</p>
                <p>Labeling Charge: {{addon_charge}}</p>
                <div class="mb-3">
                  <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Label Name" rows="4" v-model="addon_label" required></textarea>
                </div>
                <button class="btn btn-primary login-btn" type="submit">
                  <span v-if="!showLoad">
                    Submit <i class="bx bx-right-arrow-alt"></i>
                  </span>
                  <div v-if="showLoad" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              </form>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import StarRating from 'vue-star-rating'
import axios from 'axios'
import { mapActions } from "vuex";
export default {
  name: 'ProductList',
  props: {
    Listdata: {
        type:[Array, Object],
        required: true
    },
    loadingdata:{

    }
  },
  components: {
    // HelloWorld
      StarRating
  },
  data (){
    return{
      token:'',
      color_id:'',
      size_id:'',
      addon_charge:0.00,
      addon_label:'',
      item:{}
    }
  },
  mounted(){
    this.token = this.$store.state.auth.token
  },
  methods:{
    ...mapActions(["CartCount"]),
    getCartCount(){
      this.token = this.$store.state.auth.token
      if(this.token != null)
      {
        axios.post('https://admin.badmintonsouq.com/api/v1/cart-count',{}, {
          headers: {
              Authorization  : 'Bearer ' + this.token
          }
        })
        .then((response) => {
            this.CartCount(response.data.data.count)
        })
        .catch(function (error) {
            console.log(error);
        })
      }
    },
    askLabel(item)
    {
      this.item = item
    },
    addLabel(addon_charge)
    {
      document.getElementById("LabelModal1Close").click();
      this.addon_charge = addon_charge
    },
    addToCart(product_id){
      axios.post('https://admin.badmintonsouq.com/api/v1/cart/increment', {
          item_id:product_id,
          color_id: this.color_id,
          size_id: this.size_id,
          addon_charge: this.addon_charge,
          addon_label: this.addon_label
      },
      {
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
        if(response.data.success == true){
          this.$toast.show(response.data.message,{
            type: 'success',
            position: 'top-right',
            duration: '2000'
          })
          this.getCartCount()
          this.addon_charge = 0.00
          this.addon_label  = ''
          this.item         = {}
          document.getElementById("LabelModal1Close").click();
          document.getElementById("LabelModal2Close").click();
        }else{
          this.$toast.show(response.data.message,{
            type: 'error',
            position: 'top-right',
            duration: '2000'
          })
        }
      })
      .catch(function (error) {
          console.log(error);
      })
    }
  }
  // data: () => ({
  // }),
}
</script>

<style scoped>
a{
  text-decoration:none;
  color:black;
}
</style>
