<template>
  <div class="deatil-page-wrap service-deatil-page-wrap">
    <!-- <h1>This is an ProductList</h1> -->
    <div class="breadcrumb">
      <div class="container">
        <div class="left-sec">
          <a href="#" @click.prevent="this.$router.go(-1)" class="back-link"><i class='bx bx-left-arrow-alt' ></i></a>
          <h1 class="title">{{title}}</h1>
        </div>
        <ul class="links">
          <li class="link"><a href="#" title="Home">Home</a></li>
          <li class="link">/ {{title}}</li>
        </ul>
      </div>
    </div>
    
    <!-- Shimmer Loading -->
    <div class="product-detail-sec" v-if="showLoading">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="product-image-sec">
              <free-style-shimmer
              :is-loading="showLoading"
                height="500px"
                width="500px"
                border-radius="10px"
                color="#bdbdbd"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="product-detail-info">
                  <free-style-shimmer
                    height="30px"
                    width="40%"
                    border-radius="10px"
                    color="#bdbdbd"
                  />
                  <br>
              <free-style-shimmer
                height="70px"
                width="70%"
                border-radius="10px"
                color="#bdbdbd"
              />
              <br>
               <ParagraphShimmer />
              
              <br>
              <free-style-shimmer
                height="70px"
                width="30%"
                border-radius="10px"
                color="#bdbdbd"
              />
        </div>
      </div>
      </div>
    </div>
    </div>


    <div class="product-detail-sec">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="product-image-sec">
           
              <img :src="base_url +'https://admin.badmintonsouq.com/'+service.image" alt="" v-if="serviceImages.length == 0">
              <SingleServiceCarousel 
              :Listdata="serviceImages" />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="product-detail-info service-detail-info">
              <div class="brand-sec">
                <p class="name"> {{service.location}} </p>
              </div>
              <h2 class="pro-name">{{service.name}}</h2>
              <div class="star-rate-sec">
                <star-rating 
                :rating="service.rating" 
                :read-only="true" 
                :increment="0.5" 
                :star-size="20"
                inactive-color="#E1E1E1" 
                active-color="#000052"
                :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"></star-rating>
                <p class="counts">({{reviews.length}} rate & reviews)</p>
              </div>
              <div class="short-desc">
                {{service.short_description}}
              </div>
              <div class="location-map">
                <iframe :src="'https://maps.google.com/maps?q='+service.latitude+','+service.longitude+'&hl=es&z=14&amp;output=embed'" width="100%" height="150" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                <p class="loc-details"> {{service.location}}</p>
              </div>
              <button class="btn btn-primary contact-us-btn" data-bs-toggle="modal" data-bs-target="#enquiryModal">Contact Us Now<i class='bx bx-right-arrow-alt' ></i></button>
            </div>
          </div>
        </div>
        <div class="product-overview-details service-overview-details">
          <div class="row">
            <div class="col-lg-6">
              <div class="reviews-list-wrap">
                <h4 class="title">RATES & REVIEWS</h4>
                <div class="top-sec">
                  <div class="overall-star-rate">
                    <i class='bx bxs-star star-icon'></i>
                    <span class="rating">{{service.rating}}</span>
                    <p class="count">{{reviews.length}} (Rating & Reviews)</p>
                  </div> 
                  <div class="right">
                    <a href="#" data-bs-toggle="modal" v-if="token != null" data-bs-target="#reviewModal" class="link-btn">Write a Review</a>
                    <a href="#" data-bs-toggle="modal" v-else data-bs-target="#AuthModal" class="link-btn">Write a Review</a>
                    <a @click="getService" class="link">More</a>
                  </div>
                </div>
                <ul class="list">
                  <li class="item" v-for="review in reviews" :key="review.id">
                    <div class="text">
                        <p>{{review.review}}</p>
                    </div>
                    <div class="bottom-sec">
                        <div class="left">
                        {{review.user.name}} - &nbsp;
                    <star-rating 
                    :rating="review.rating" 
                    :read-only="true" 
                    :increment="0.5" 
                    :star-size="16"
                    :show-rating="false"
                    inactive-color="#E1E1E1" 
                    active-color="#000052"
                    :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"></star-rating>
                        </div>
                        <p class="date">{{new Date(review.date).getDate()}} {{new Date(review.date).toLocaleString('en-us', { month: 'long' })}} {{new Date(review.date).getFullYear()}}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            
            <div class="col-lg-6">
              <div class="more-detail-wrap">
                <h4 class="title">More information </h4>
                <div class="contents">
                  <div v-html="service.description"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    
  <div class="bestselling">
    <div class="container">
      <div class="top-sec">
        <h3 class="title">You may like also</h3>
      </div>
    </div>
    <ServiceCarousel :Listdata="ServiceLists"  :loadingdata ="showLoading"/>
  </div>
    
    <div class="brand-carousel">
      <div class="container">
      </div>
      <!--<brandsCarousel :Listdata="brands"/>-->
    </div>
  </div>



  <!-- Rate and Review Modal -->
<div class="modal fade auth-modal organize-modal review-modal" id="reviewModal" tabindex="-1" aria-labelledby="OrganizeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="AuthModalLabel">Your Review</h5>
        <button type="button" class="btn-close" id="ReviewModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
            <div class="login-form">
              <form @submit.prevent="submitReviewForm">
                <p id="error">{{error_message}}</p>
                <div class="mb-3">
                  <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="comments (name: additional requirements)" v-model="form.review" rows="4" required></textarea>
                </div>
                <div class="mb-3 star-rating">
                  <star-rating text-class="rating-text"
                  :rating="0" 
                  :read-only="fade" 
                  :increment="0.5" 
                  :star-size="30"
                  :show-rating="true"
                  inactive-color="#E1E1E1" 
                  active-color="#000052"
                  v-model:rating="form.rating"
                  :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"></star-rating>
                </div>
                <!--<a href="#" data-bs-toggle="modal" data-bs-target="#SuccessModal">-->
                <button class="btn btn-primary login-btn">
                  <span v-if="!showLoad">
                    Submit <i class="bx bx-right-arrow-alt"></i>
                  </span>
                  <div v-if="showLoad" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
                <!--</a>-->
              </form>
            </div>
      </div>
      <!-- <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> -->
    </div>
  </div>
</div>

<!-- Modal -->

<div class="modal fade auth-modal organize-modal" id="enquiryModal" tabindex="-1" aria-labelledby="OrganizeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="AuthModalLabel">Contact Us</h5>
        <button type="button" class="btn-close" id="EnquiryModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
            <div class="login-form">
              <form @submit.prevent="submitServiceEnquiryForm">
                <p id="error">{{error_message}}</p>
                <div class="row form-group">
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Name" v-model="form2.name">
                  </div>
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Phone" v-model="form2.phone">
                  </div>
                </div>
                <div class="mb-3 icon-input">
                  <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="Email" v-model="form2.email">
                </div>
                <button class="btn btn-primary login-btn">
                  <span v-if="!showLoad">
                    Submit <i class="bx bx-right-arrow-alt"></i>
                  </span>
                  <div v-if="showLoad" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              </form>
            </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
// import brandsCarousel from '@/components/brandsCarousel.vue'
import StarRating from 'vue-star-rating'
import ServiceCarousel from '@/components/ServiceCarousel.vue'
import SingleServiceCarousel from '@/components/SingleServiceCarousel.vue'
import axios from 'axios'

// import {mapMutations } from 'vuex';
// import {
//     LOADING_SPINNER_SHOW_MUTATION,
// } from '../store/storeconstants';

export default({
  name: 'productList',
  components: {
      // brandsCarousel,
      ServiceCarousel,
      SingleServiceCarousel,
      StarRating
  },
  data (){

    return{
        showLoading: false,
        showLoad:false,
        count: 1,
        brands :[],
        url_slug:'',
        service_slug:'',
        servicer_slug:'',
        service:[],
        reviews:[],
        title:'',
        sub_title:'',
        ServiceLists :[],
        serviceImages :[],
        base_url:'https://admin.badmintonsouq.com',
        token:'',
        form:{
          review:'',
          rating:''
        },
        form2:{
          name:'',
          phone:'',
          email:''
        },
        error_message:'',
        service_type:'',
        commentsToShow:2
      }
    },
    created(){
        // this.getBrands();
        this.getService();
    },
    mounted(){
      this.token   = this.$store.state.auth.token
    },
    methods:{
      // getBrands(){
      //     axios.post('https://admin.badmintonsouq.com/api/v1/get-partners', {})
      //     .then((response) => {
      //         this.brands = response.data.data.partners
      //     })
      //     .catch(function (error) {
      //         console.log(error);
      //     })
      // },
      
    // ...mapMutations({
    //     showLoading: LOADING_SPINNER_SHOW_MUTATION,
    // }),
      async getService() {
          // this.showLoading(true);
        this.showLoading = true;
          this.url_slug      = this.$route.fullPath.split("/");
          this.service_slug  = this.url_slug[1]
          this.servicer_slug = this.url_slug[2]
          if(this.service_slug == 'coaching-centres'){
              await axios.post('https://admin.badmintonsouq.com/api/v1/get-coaching-centre', {
                  slug:this.servicer_slug,
                  limit:this.commentsToShow
              })
              .then((response) => {
                  this.service = response.data.data.center
                  this.reviews = response.data.data.review
                  this.serviceImages = response.data.data.gallery
                  this.ServiceLists = response.data.data.similar_centers
                  this.title        = 'Coaching Centres/Academies',
                  this.commentsToShow += 2
              })
              .catch(function (error) {
                  console.log(error);
          this.showLoading = false;
              })
          }
          else if(this.service_slug == 'personal-trainers'){
              await axios.post('https://admin.badmintonsouq.com/api/v1/get-personal-trainer', {
                  slug:this.servicer_slug,
                  limit:this.commentsToShow
              })
              .then((response) => {
                  this.service = response.data.data.trainer
                  this.reviews = response.data.data.review
                  this.serviceImages = response.data.data.gallery
                  this.ServiceLists = response.data.data.similar_trainers
                  this.title        = 'Personal Trainers',
                  this.commentsToShow += 2
              })
              .catch(function (error) {
                  console.log(error);
          this.showLoading = false;
              })
          }
          else if(this.service_slug == 'polling-agents'){
              await axios.post('https://admin.badmintonsouq.com/api/v1/get-queuing-agent', {
                  slug:this.servicer_slug,
                  limit:this.commentsToShow
              })
              .then((response) => {
                  this.service = response.data.data.agent
                  this.reviews = response.data.data.review
                  this.serviceImages = response.data.data.gallery
                  this.ServiceLists = response.data.data.similar_agents
                  this.title        = 'Queuing/Polling',
                  this.commentsToShow += 2
              })
              .catch(function (error) {
                  console.log(error);
          this.showLoading = false;
              })
          }
          else{
              await axios.post('https://admin.badmintonsouq.com/api/v1/get-sports-clinic', {
                  slug:this.servicer_slug,
                  limit:this.commentsToShow
              })
              .then((response) => {
                  this.service = response.data.data.clinic
                  this.reviews = response.data.data.review
                  this.serviceImages = response.data.data.gallery
                  this.ServiceLists = response.data.data.similar_clinics
                  this.title        = 'Sports Clinics & Doctors',
                  this.commentsToShow += 2
              })
              .catch(function (error) {
                  console.log(error);
          this.showLoading = false;
              })
          }
          this.showLoading = false;
      },
      submitReviewForm(){
        this.showLoad = true
        this.url_slug      = this.$route.fullPath.split("/");
        this.service_slug  = this.url_slug[1]
        if(this.service_slug == 'coaching-centres'){
          axios.post('https://admin.badmintonsouq.com/api/v1/create-center-review', {
              center_id:this.service.id,
              review:this.form.review,
              rating:this.form.rating
          },
          {
            headers: {
                Authorization  : 'Bearer ' + this.token
            }
          })
          .then((response) => {
            this.showLoad = false
            if(response.data.success == true){
              this.$toast.show(response.data.message,{
                type: 'success',
                position: 'top-right',
                duration: '2000'
              })
              this.getService()
              document.getElementById("ReviewModalClose").click();
              this.form.review = ''
              this.form.rating = ''
            }else{
              this.$toast.show(response.data.message,{
                type: 'error',
                position: 'top-right',
                duration: '2000'
              })
            }
          })
          .catch(function (error) {
              console.log(error);
          })
        }
        else if(this.service_slug == 'personal-trainers'){
            this.showLoad = false
            axios.post('https://admin.badmintonsouq.com/api/v1/create-trainer-review', {
                trainer_id:this.service.id,
                review:this.form.review,
                rating:this.form.rating
            },
            {
              headers: {
                  Authorization  : 'Bearer ' + this.token
              }
            })
            .then((response) => {
              if(response.data.success == true){
                this.$toast.show(response.data.message,{
                  type: 'success',
                  position: 'top-right',
                  duration: '2000'
                })
                this.getService()
                document.getElementById("ReviewModalClose").click();
                this.form.review = ''
                this.form.rating = ''
              }else{
                this.$toast.show(response.data.message,{
                  type: 'error',
                  position: 'top-right',
                  duration: '2000'
                })
              }
            })
            .catch(function (error) {
                console.log(error);
            })
        }
        else if(this.service_slug == 'polling-agents'){
            this.showLoad = false
            axios.post('https://admin.badmintonsouq.com/api/v1/create-agent-review', {
                agent_id:this.service.id,
                review:this.form.review,
                rating:this.form.rating
            },
            {
              headers: {
                  Authorization  : 'Bearer ' + this.token
              }
            })
            .then((response) => {
              if(response.data.success == true){
                this.$toast.show(response.data.message,{
                  type: 'success',
                  position: 'top-right',
                  duration: '2000'
                })
                this.getService()
                document.getElementById("ReviewModalClose").click();
                this.form.review = ''
                this.form.rating = ''
              }else{
                this.$toast.show(response.data.message,{
                  type: 'error',
                  position: 'top-right',
                  duration: '2000'
                })
              }
            })
            .catch(function (error) {
                console.log(error);
            })
        }
        else{
            this.showLoad = false
            axios.post('https://admin.badmintonsouq.com/api/v1/create-clinic-review', {
                clinic_id:this.service.id,
                review:this.form.review,
                rating:this.form.rating
            },
            {
              headers: {
                  Authorization  : 'Bearer ' + this.token
              }
            })
            .then((response) => {
              if(response.data.success == true){
                this.$toast.show(response.data.message,{
                  type: 'success',
                  position: 'top-right',
                  duration: '2000'
                })
                this.getService()
                document.getElementById("ReviewModalClose").click();
                this.form.review = ''
                this.form.rating = ''
              }else{
                this.$toast.show(response.data.message,{
                  type: 'error',
                  position: 'top-right',
                  duration: '2000'
                })
              }
            })
            .catch(function (error) {
                console.log(error);
            })
        }
      },
      submitServiceEnquiryForm(){
        this.showLoad = true
        if(this.service_slug == 'coaching-centres'){
          this.service_type = 'Coaching Centres'
        }else if(this.service_slug == 'personal-trainers'){
          this.service_type = 'Personal Trainers'
        }else if(this.service_slug == 'polling-agents'){
          this.service_type = 'Polling/Queuing Agents'
        }else{
          this.service_type = 'Sports Clinics & Doctors'
        }
        axios.post('https://admin.badmintonsouq.com/api/v1/create-service-enquiry', {
          service_type:this.service_type,
          name:this.form2.name,
          email:this.form2.email,
          phone:this.form2.phone,
          service_id:this.service.id
        })
        .then((response) => {
          this.showLoad = false
          if(response.data.success == false){
            this.error_message = response.data.message
          }else{
            this.$swal.fire('Success!','<table class="table spec-table table-striped">'+
                                        '<tbody>'+
                                          '<tr>'+
                                            '<td><i class="bx bxl-whatsapp" ></i> Whatsapp No.</td>'+
                                            '<td>'+response.data.data.service_whatsapp+'</td>'+
                                          '</tr>'+
                                          '<tr>'+
                                            '<td><i class="bx bxs-envelope" ></i> Email</td>'+
                                            '<td>'+response.data.data.service_email+'</td>'+
                                          '</tr>'+
                                          '<tr>'+
                                            '<td><i class="bx bxs-phone" ></i> Phone</td>'+
                                            '<td>'+response.data.data.service_phone+'</td>'+
                                          '</tr>'+
                                        '</tbody>'+
                                      '</table>'
            ,"success")
            .then(() => {
              document.getElementById("EnquiryModalClose").click();
              this.form2.name = ''
              this.form2.phone = ''
              this.form2.email = ''
            })
          }
        })
        .catch(function (error) {
            console.log(error);
        })
      }
    }
})
</script>

<style>
#error{
  color:red;
}
</style>