<template>
<div class="container">
  <div class="product-image-carousel">

  <swiper 
  :style="{'--swiper-navigation-color': '#fff','--swiper-pagination-color': '#fff'}" 
  :spaceBetween="10" 
  :slidesPerView="1" 
  :navigation="true" 
  :thumbs="{ swiper: thumbsSwiper }" 
  class="single-swiper">
    <swiper-slide
     v-for="(Listdata, index) in Listdata"
     :key="index"
     ><img :src="base_url+Listdata.image" />
     </swiper-slide>
  </swiper>
  <swiper 
  :direction="'vertical'"
  @swiper="setThumbsSwiper" 
  :spaceBetween="10" 
  :slidesPerView="4" 
  :freeMode="true" 
  :watchSlidesProgress="true" 
  class="swiper-thump">
    <swiper-slide
     v-for="(Listdata, index) in Listdata"
     :key="index">
      <img :src="base_url+Listdata.image" />
    </swiper-slide>
  </swiper>

  </div>
  
  <!-- <h1 class="brand-special">{{Listdata.brand}}</h1> -->
    <!-- <div v-for="(Listdata) in Listdata" :key="Listdata.id" class="user">
      {{ Listdata.title }}
  </div> -->
</div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';
  // import StarRating from 'vue-star-rating'
   // import styles bundle
  import 'swiper/swiper-bundle.min.css';
  // Import Swiper styles
  // import 'swiper/swiper.min.css';
  
// import Swiper core and required modules
import SwiperCore, {
  Navigation,Thumbs
} from 'swiper';

// install Swiper modules
SwiperCore.use([Navigation,Thumbs]);

export default {
  name: 'ProductCarousel',
  props: {
    Listdata: {
        type:[Array, Object],
        required: true
    },
  },
  components: {
    // HelloWorld
      Swiper,
      SwiperSlide,
      // StarRating
  },
  // data: () => ({
  // }),
    // setup() {
    //   const onSwiper = (swiper) => {
    //     console.log(swiper);
    //   };
    //   const onSlideChange = () => {
    //     console.log('slide change');
    //   };
    //   return {
    //     onSwiper,
    //     onSlideChange,
    //   };
    // },
    
  data() {
    return {
        thumbsSwiper: null,
         base_url:'https://admin.badmintonsouq.com'
    };
  },
  methods: {
    
    setThumbsSwiper(swiper) {
      // this.$emit("setThumbsSwiper", swiper);
      this.thumbsSwiper = swiper;
    },
  }
}
</script>
