<template>
  <div class="deatil-page-wrap cart-page-wrap">
    <!-- <h1>This is an ProductList</h1> -->
    <div class="breadcrumb">
      <div class="container">
        <div class="left-sec">
          <a href="#" @click.prevent="this.$router.go(-1)" class="back-link"><i class='bx bx-left-arrow-alt' ></i></a>
          <h1 class="title">Checkout</h1>
        </div>
        <ul class="links">
          <li class="link"><a href="/">Home</a></li>
          <li class="link">/ Checkout</li>
        </ul>
      </div>
    </div>
    <div class="cart-detail-sec">
      <div class="container">
        

        <!-- Shimmer Loading -->
        <div class="row" v-if="showLoading">
          <div class="col-lg-8">
            
            <div class="address-sec">
              <div class="address-list">
                <ul class="lists">
                  <li class="addrs-item" style="display: block">
                    <paragraph-shimmer :lines="5"/>
                  </li>
                  <li class="addrs-item" style="display: block">
                    <paragraph-shimmer :lines="5"/>
                  </li>
                </ul>
              </div>
            </div>
            <div class="cart-list-sec">
              <div class="products-list top-sec">
                <div class="product-item" style="display: block;">
                  <paragraph-shimmer :lines="1"/>
                </div>
                <div class="qty-sec">
                  <paragraph-shimmer :lines="1"/>
                </div>
                <div class="total-price">
                  <paragraph-shimmer :lines="1"/>
                </div>
              </div>
              <div class="products-list">
                <div class="product-item">
                  <div class="product-img">
                    <free-style-shimmer
                      height="80px"
                      width="80px"
                      border-radius="10px"
                      color="#bdbdbd"
                    />
                  </div>
                  <div class="product-info" style="width: 50%">
                    <paragraph-shimmer :lines="4"/>
                  </div>
                </div>
                <div class="qty-sec">
                    <free-style-shimmer
                      height="30px"
                      width="80%"
                      border-radius="10px"
                      color="#bdbdbd"
                    />
                </div>
                <div class="total-price">
                    <paragraph-shimmer :lines="1"/>
                </div>
              </div>
              <div class="products-list">
                <div class="product-item">
                  <div class="product-img">
                    <free-style-shimmer
                      height="80px"
                      width="80px"
                      border-radius="10px"
                      color="#bdbdbd"
                    />
                  </div>
                  <div class="product-info" style="width: 50%">
                    <paragraph-shimmer :lines="4"/>
                  </div>
                </div>
                <div class="qty-sec">
                    <free-style-shimmer
                      height="30px"
                      width="80%"
                      border-radius="10px"
                      color="#bdbdbd"
                    />
                </div>
                <div class="total-price">
                    <paragraph-shimmer :lines="1"/>
                </div>
              </div>
              <div class="products-list">
                <div class="product-item">
                  <div class="product-img">
                    <free-style-shimmer
                      height="80px"
                      width="80px"
                      border-radius="10px"
                      color="#bdbdbd"
                    />
                  </div>
                  <div class="product-info" style="width: 50%">
                    <paragraph-shimmer :lines="4"/>
                  </div>
                </div>
                <div class="qty-sec">
                    <free-style-shimmer
                      height="30px"
                      width="80%"
                      border-radius="10px"
                      color="#bdbdbd"
                    />
                </div>
                <div class="total-price">
                    <paragraph-shimmer :lines="1"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="price-deatils" style="background: #f5f5f5;">
              <table class="table price-table">
                <tbody>
                  <tr>
                      <td><paragraph-shimmer :lines="1"/></td>
                      <td><paragraph-shimmer :lines="1"/></td>
                  </tr>
                  <tr>
                    <td><paragraph-shimmer :lines="1"/></td>
                    <td><paragraph-shimmer :lines="1"/></td>
                  </tr>
                  <tr>
                    <td><paragraph-shimmer :lines="1"/></td>
                    <td><paragraph-shimmer :lines="1"/></td>
                  </tr>
                  <tr>
                    <td><paragraph-shimmer :lines="1"/></td>
                    <td><paragraph-shimmer :lines="1"/></td>
                  </tr>
                </tbody>
              </table>
                    <free-style-shimmer
                      height="40px"
                      width="100%"
                      border-radius="0px"
                      color="#bdbdbd"
                    />
                    <br>
                    <paragraph-shimmer :lines="1"/>
            </div>
          </div>
        </div>


        <div class="row" v-else>
          <div class="col-lg-8">
            <div class="address-sec">
              <div class="top-sec">
                <h4 class="title">Delivery Address</h4>
                <a href="#" class="link-btn"   data-bs-toggle="modal" data-bs-target="#AddressModal" >Add New Address</a>
              </div>
              <div class="address-list">
                <p id="error">{{error_message}}</p>
                <ul class="lists">
                  <li class="addrs-item" v-for="address in addressList" :key="address.id">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" :id="address.id" v-on:change="setData(address.id)">
                  <label class="form-check-label" :for="address.id">
                    <p class="name">{{address.contact_name}}</p>
                    <p class="text">{{address.house_name}} - {{address.landmark}} - {{address.city}} - {{address.state}}</p>
                    <p class="mobile">{{((address.pincode) ? 'PO Box - '+address.pincode : '')}}</p>
                    <p class="mobile">{{address.mobile_number}}</p>
                    <div class="actions">
                      <a href="" class="link"></a>
                    </div>
                  </label>
                  </li>
                </ul>
              </div>
            </div>
            <div class="cart-list-sec">
              <div class="products-list top-sec">
                <div class="product-item">
                  <p class="text"> Product</p>
                </div>
                <div class="qty-sec">
                  <p class="text"> 	Quantity</p>
                </div>
                <div class="total-price">
                  <p class="text"> Total</p>
                </div>
              </div>
              <div class="products-list" v-for="cart in carts" :key="cart.id">
                <div class="product-item">
                  <div class="product-img">
                    <img v-if="cart.product.parameters.variant != null" :src="cart.product.parameters.variant.image" alt="" class="image">
                    <img v-else :src="cart.product.image_path" alt="" class="image">
                  </div>
                  <div class="product-info">
                    <a :href="$router.resolve({path: '/products/'+cart.product.slug}).href" style="text-decoration:none; color:black;">
                    <span class="brand">{{cart.product.brand_title}}</span>
                    <p class="pname" v-if="cart.size_id && cart.product.parameters.variant != null">{{cart.product.title}} <br> Size: {{cart.product.parameters.variant.size.size}}</p>
                    <p class="pname" v-else>{{cart.product.title}}</p>
                    </a>
                    <div class="rating">
                      <star-rating 
                      :rating="cart.product.rating" 
                      :read-only="true" 
                      :increment="0.5" 
                      :star-size="15"
                      inactive-color="#E1E1E1" 
                      active-color="#A5A5A5"
                      :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"></star-rating>
                    </div>
                    <div class="price">
                      <p class="old-p" style="text-decoration: line-through; color: grey;">AED {{cart.product.mrp}}</p>
                      <p class="new-p">AED {{cart.product.sale_cost}}</p>
                    </div>
                    <div v-if="cart.addon_label != null">
                      <p>Label Charge- AED {{cart.addon_charge}}<br>
                      Label Text- {{cart.addon_label}}</p>
                    </div>
                    <div v-if="cart.stringing_charge != 0.00">
                      <p>Stringing Charge- AED {{cart.stringing_charge}}</p>
                    </div>
                    <div>
                      <p v-if="cart.stock_status == 0 && cart.stock_quantity == null"><span style="color:red;">Out of Stock, Please Remove the item.</span></p>
                      <p v-if="cart.stock_status == 0 && cart.stock_quantity != null"><span style="color:red;">Out of stock, Only {{cart.stock_quantity}} items available, Please decrease the quantity.</span></p>
                      <p v-if="cart.product.is_exclude_from_coupon != 0"><span style="color:red;">Coupon not applicable to this product</span></p>
                    </div>
                  </div>
                </div>
                <div class="qty-sec">
                  <div class="qty-container">
                    <button class="btn btn-min" @click.prevent="decrementCartItem(cart.id,cart.product.item_id)"><i class='bx bx-minus' ></i></button>
                    <input type="number" class="form-control" id="" placeholder="" :value="cart.product.quantity">
                    <button class="btn btn-add" @click.prevent="incrementCartItem(cart.id,cart.product.item_id)"><i class='bx bx-plus' ></i></button>
                  </div>
                  <a href="" @click.prevent="removeCartItem(cart.id,cart.product.item_id)" class="link">Remove</a>
                </div>
                <div class="total-price">
                  <p class="total">AED {{parseFloat(+cart.product.total + +cart.addon_charge + +cart.stringing_charge).toFixed(2)}}</p>
                </div>
              </div>
            </div>
            <div>
             <p v-if="is_delivery == false">Shop AED {{balance_free_delivery_amount}} more to your cart for <span style="color:green;">FREE DELIVERY</span></p>
             <p v-else>You are eligible for <span style="color:green;">FREE DELIVERY</span></p>
            </div>
            <div v-if="is_coupon_applied == false">
             <h6>Enter a gift card or promotional code</h6>
             <input type="text" class="form-control" id="" placeholder="Enter a valid code" v-model="redeem_code">
             <button class="btn btn-primary" @click.prevent="getCart">Apply</button>
            </div>
            <div v-else>
             <h6>Congrats! You saved AED {{redeem_amount}} from this order</h6>
             <p>{{redeem_code}} - Code applied, AED {{redeem_amount}} has been redeemed</p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="price-deatils">
              <table class="table price-table">
                <tbody>
                  <tr>
                      <td>Sub Total</td>
                      <td>AED {{total_cost}}</td>
                  </tr>
                  <tr>
                    <td>Delivery</td>
                    <td v-if="delivery_charge != '0.00'">AED {{delivery_charge}}</td>
                    <td v-else style="color:green;">FREE</td>
                  </tr>
                  <tr>
                    <td>Tax(VAT 5%)</td>
                    <td>AED {{tax}}</td>
                  </tr>
                  <tr v-if="is_coupon_applied">
                    <td>Coupon Discount</td>
                    <td>AED -{{redeem_amount}}</td>
                  </tr>

                  <tr v-if="is_postpay">
                    <td>Postpay Processing Fee</td>
                    <td>AED {{proceeing_fee}}</td>
                  </tr>

                  <tr>
                    <th>Grand Total</th>
                    <th>AED {{grand_total}}</th>
                  </tr>
                  <tr>
                    <th  colspan="2" style="text-align:left;">
                      <input class="form-check-input" type="radio" checked v-on:change="setPaymentOption(1)" name="paymentOption">&nbsp;&nbsp;
                      <label class="form-check-label">
                       <i class='icon bx bx-money'></i> Cash On Delivery
                      </label>
                    </th>
                  </tr>
                  <tr>
                    <th colspan="2"  style="text-align:left;">
                      <input class="form-check-input" type="radio" v-on:change="setPaymentOption(2)" name="paymentOption">&nbsp;&nbsp;
                      <label class="form-check-label">
                       <i class='icon bx bx-credit-card-front' ></i> Pay Online
                      </label>
                    </th>
                  </tr>
                  <tr>
                    <th colspan="2"  style="text-align:left;">
                      <input class="form-check-input" type="radio" v-on:change="setPaymentOption(3)" name="paymentOption">&nbsp;&nbsp;
                      <label class="form-check-label">
                       <i class='icon bx bx-credit-card-front' ></i> Purchase over 3 Installments
                      </label>
                      <p class="postpay_content">(5% processing fee) 
                        <pre><a href="/postpay-terms-condition.pdf" target="_blank">Postpay Terms and conditions</a></pre>
                      </p>
                    </th>
                  </tr>
                </tbody>
              </table>
              <button class="btn btn-primary checkout-btn" @click.prevent="submitOrder">
                  <span v-if="!showLoad">
                    {{pay_text}} <i class="bx bx-right-arrow-alt"></i>
                  </span>
                  <div v-if="showLoad" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
              </button>
              <div class="payment-note">
                <p class="text"><i class='bx bx-lock-alt' ></i> 100% Secure Payments</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    
  <div class="bestselling">
    <div class="container">
      <div class="top-sec">
        <!--<h3 class="title">You may like also</h3>-->
      </div>
    </div>
    <!--<ProductCarousel :Listdata="productLists"/>-->
  </div>
    
  <div class="brand-carousel">
    <div class="container">
    </div>
    <!--<brandsCarousel :Listdata="brands"/>-->
  </div>
</div>

<!-- Add New Address Modal -->
<div class="modal fade auth-modal organize-modal" id="AddressModal" tabindex="-1" aria-labelledby="OrganizeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="AddressModalLabel">Add New Address</h5>
        <button type="button" class="btn-close" id="AddressModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
            <div class="login-form">
              <form @submit.prevent="addAddress">
                <p id="error">{{modal_error_message}}</p>
                <div class="mb-3 icon-input">
                    <input type="text" class="form-control" v-model="form.contact_name" id="exampleFormControlInput1" placeholder="Name">
                </div>
                <div class="row form-group">
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form.house_name" id="exampleFormControlInput1" placeholder="House Name/Building/Appartment No.">
                  </div>
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form.landmark" id="exampleFormControlInput1" placeholder="Landmark">
                  </div>
                </div>
                <div class="row form-group">
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form.city" id="exampleFormControlInput1" placeholder="City">
                  </div>
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form.state" id="exampleFormControlInput1" placeholder="State">
                  </div>
                </div>
                <div class="row form-group">
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form.mobile_number" id="exampleFormControlInput1" placeholder="Phone">
                  </div>
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form.pincode" id="exampleFormControlInput1" placeholder="PO Box (Optional)">
                  </div>
                </div>
                <!-- <div class="mb-3 icon-input">
                  <input type="text" class="form-control" id="exampleFormControlInput1"  placeholder="Location">
                  <i class='icon bx bx-current-location'></i>
                </div>
                <div class="mb-3">
                  <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Address..." rows="4"></textarea>
                </div>
                <div class="mb-3 icon-input">
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="PO Box (Optional)">
                </div> -->
                <button class="btn btn-primary login-btn">
                  <span v-if="!showLoad">
                    Submit <i class="bx bx-right-arrow-alt"></i>
                  </span>
                  <div v-if="showLoad" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              </form>
            </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
// import brandsCarousel from '@/components/brandsCarousel.vue'
import StarRating from 'vue-star-rating'
// import ProductCarousel from '@/components/ProductCarousel.vue'
import axios from 'axios'
import { mapActions } from "vuex";
export default({
  name: 'productList',
  components: {
      // brandsCarousel,
      // ProductCarousel,
      StarRating
  },
  data (){

    return{
      showLoading: false,
      showLoad:false,
      count: 1,
      count1: 1,
      count2: 1,
      brands :[],
      productLists :[],
      productImages :[],
      cart_count:0,
      carts:[],
      grand_total:0.00,
      delivery_charge:0.00,
      total_cost:0.00,
      token:'',
      addressList:[],
      address_id:'',
      error_message:'',
      form:{
        contact_name:'',
        house_name:'',
        landmark:'',
        city:'',
        state:'',
        mobile_number:'',
        pincode:''
      },
      modal_error_message:'',
      tax:0.00,
      payment_option_id:1,
      pay_text: 'Place Order',
      is_delivery:'',
      balance_free_delivery_amount:'',
      redeem_code:'',
      coupon_applied_message:'',
      redeem_available_product_total:'',
      redeem_amount:'',
      is_coupon_applied:false,
      is_postpay:false,
      proceeing_fee:'',
      grand_total_less_process_fee:'',
      order_by:"WEBSITE"
    }
  },
  created(){
    // this.getBrands();
    // this.getProducts();
    this.getCart();
    this.getUserAddress();
  },
  methods: {
    ...mapActions(["CartCount"]),
    // getProducts(){
    //   axios.post('https://admin.badmintonsouq.com/api/v1/get-best-selling-products', {})
    //   .then((response) => {
    //       this.productLists = response.data.data.best_selling
    //   })
    //   .catch(function (error) {
    //       console.log(error);
    //   })
    // },
    async getCart(){
      this.showLoading = true;
      this.token = this.$store.state.auth.token
      await axios.post('https://admin.badmintonsouq.com/api/v1/get-user-carts',{
        redeem_code:this.redeem_code
      }, {
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
          this.carts = response.data.data.carts
          if(this.carts.length == 0)
          {
            this.$router.push('/')
          }
          this.total_cost                     = parseFloat(response.data.data.total_cost).toFixed(2)
          this.delivery_charge                = parseFloat(response.data.data.delivery_charge).toFixed(2)
          this.grand_total                    = parseFloat(response.data.data.grand_total).toFixed(2)
          this.grand_total_less_process_fee   = parseFloat(response.data.data.grand_total).toFixed(2)
          this.tax                            = parseFloat(response.data.data.total_cost*5/100).toFixed(2)
          this.is_delivery                    = response.data.data.is_delivery_free
          this.balance_free_delivery_amount   = response.data.data.balance_free_delivery_amount
          this.redeem_available_product_total = response.data.data.redeem_available_product_total
          this.coupon_applied_message         = response.data.data.coupon_applied_message
          this.redeem_amount                  = response.data.data.redeem_amount
          this.is_coupon_applied              = response.data.data.is_coupon_applied
          if(this.is_coupon_applied == false && this.coupon_applied_message != ''){
            this.$toast.show(this.coupon_applied_message,{
              type: 'error',
              position: 'top-right',
              duration: '2000'
            })
          }
      })
      .catch(function (error) {
          console.log(error);
        this.showLoading = false;
      })
        this.showLoading = false;
    },
    decrementCartItem(cart_id,product_id)
    {
      axios.post('https://admin.badmintonsouq.com/api/v1/cart/decrement', {
            item_id : product_id,
            cart_id:cart_id
      },
      {
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
          if(response.data.data.count == 0)
          {
            this.$router.push('/cart')
          }
          this.CartCount(response.data.data.count)
          this.getCart()
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    incrementCartItem(cart_id,product_id){
      axios.post('https://admin.badmintonsouq.com/api/v1/cart/increment', {
          item_id: product_id,
          cart_id: cart_id
      },
      {
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
        if(response.data.success == true)
        {
          this.CartCount(response.data.data.count)
          this.getCart()
        }
        else{
          this.$toast.show(response.data.message,{
            type: 'error',
            position: 'top-right',
            duration: '2000'
          })
        }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    removeCartItem(cart_id,product_id){
      axios.post('https://admin.badmintonsouq.com/api/v1/remove-one-product-in-cart', {
          item_id : product_id,
          cart_id: cart_id
      },
      {
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
        if(response.data.success == true)
        {
          if(response.data.data.count == 0)
          {
            this.$router.push('/cart')
          }
          this.CartCount(response.data.data.count)
          this.getCart()
        }
        else{
          this.$swal.fire(response.data.message,'',"error")
        }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    getUserAddress(){
      this.token = this.$store.state.auth.token
      axios.post('https://admin.badmintonsouq.com/api/v1/address-list', {},{
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
          this.addressList = response.data.data.delivery_address
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    setData(address_id){
      this.address_id = address_id
    },
    setPaymentOption(payment_option_id)
    {
      this.payment_option_id            = payment_option_id
      this.is_postpay                   = false;
      this.grand_total                  = this.grand_total_less_process_fee;

      if(this.payment_option_id == 1)
      {
        this.pay_text                     = 'Place order'
      }
      else if(this.payment_option_id == 2 || this.payment_option_id == 3)
      {
        this.pay_text = 'Pay Now'
        if(this.payment_option_id == 3)
        {
          this.is_postpay     = true;
          this.proceeing_fee  = parseFloat(this.grand_total*5/100).toFixed(2);
          this.grand_total    = parseFloat(+this.grand_total + +this.proceeing_fee).toFixed(2);
        }
      }
    },
    submitOrder(){
      if(this.payment_option_id == 1)
      {
        this.showLoad = true
        axios.post('https://admin.badmintonsouq.com/api/v1/checkout-order', {
          user_address_id               : this.address_id,
          payment_option_id             :this.payment_option_id,
          redeem_code                   :this.redeem_code,
          redeem_available_product_total: this.redeem_available_product_total,
          redeem_amount                 :this.redeem_amount,
          order_by                      :this.order_by
        },{
          headers: {
              Authorization  : 'Bearer ' + this.token
          }
        })
        .then((response) => {
          this.showLoad = false
          if(response.data.success == true && response.data.data.is_checkout_approved == true){
            this.$swal.fire(response.data.message,'',"success")
            .then(() => {
              window.location.href = '/account'
            })
          }
          else if(response.data.success == false && response.data.data.is_checkout_approved == false)
          {
            this.$toast.show('Some of the items in cart are out of stock. Please clear out the out of stock items to continue.',{
              type: 'error',
              position: 'top-right',
              duration: '2000'
            })
          }
          else{
            this.error_message = response.data.message
            this.$toast.show(response.data.message,{
              type: 'error',
              position: 'top-right',
              duration: '2000'
            })
          }
        })
        .catch(function (error) {
            console.log(error);
        })
      }
      else if(this.payment_option_id == 2)
      {
        this.showLoad = true
        axios.post('https://admin.badmintonsouq.com/api/v1/generate-temp-order-id', {
          user_address_id                 : this.address_id,
          grand_total                     : this.grand_total,
          redeem_code                     :this.redeem_code,
          redeem_available_product_total  : this.redeem_available_product_total,
          redeem_amount                   :this.redeem_amount,
          order_by                        :this.order_by
        },
        {
          headers: {
              Authorization  : 'Bearer ' + this.token
          }
        })
        .then((response) => {
          this.showLoad = false
          if(response.data.success == true && response.data.data.is_checkout_approved == true)
          {
            window.location.href = '/pay-online/'+response.data.data.temp_order_id
          }
          else if(response.data.success == false && response.data.data.is_checkout_approved == false)
          {
            this.$toast.show('Some of the items in cart are out of stock. Please clear out the out of stock items to continue.',{
              type: 'error',
              position: 'top-right',
              duration: '2000'
            })
          }
          else{
            this.error_message = response.data.message
            this.$toast.show(response.data.message,{
              type: 'error',
              position: 'top-right',
              duration: '2000'
            })
          }
        })
        .catch(function (error) {
            console.log(error);
        })
      }else if(this.payment_option_id == 3)
      {
        this.showLoad = true
        axios.post('https://admin.badmintonsouq.com/api/v1/generate-postpay-order-id', {
          user_address_id                 : this.address_id,
          grand_total                     : this.grand_total_less_process_fee,
          redeem_code                     : this.redeem_code,
          redeem_available_product_total  : this.redeem_available_product_total,
          redeem_amount                   : this.redeem_amount,
          delivery_charge                 : this.delivery_charge,
          order_by                        : this.order_by
        },
        {
          headers: {
              Authorization  : 'Bearer ' + this.token
          }
        })
        .then((response) => {
          this.showLoad = false
          if(response.data.success == true && response.data.data.is_checkout_approved == true)
          {
            window.location.href = response.data.data.redirect_url;
          }
          else if(response.data.success == false && response.data.data.is_checkout_approved == false)
          {
            this.$toast.show('Some of the items in cart are out of stock. Please clear out the out of stock items to continue.',{
              type: 'error',
              position: 'top-right',
              duration: '2000'
            })
          }
          else{
            this.error_message = response.data.message
            this.$toast.show(response.data.message,{
              type: 'error',
              position: 'top-right',
              duration: '2000'
            })
          }
        })
        .catch(function (error) {
            console.log(error);
        })
      }
    },
    addAddress(){
      this.showLoad = true
      axios.post('https://admin.badmintonsouq.com/api/v1/create-address', this.form,{
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
          this.showLoad = false
          if(response.data.success == false){
            this.modal_error_message = response.data.message
          }else{
            this.getUserAddress()
            document.getElementById("AddressModalClose").click();
            this.form.contact_name  = ''
            this.form.house_name    = ''
            this.form.landmark      = ''
            this.form.city          = ''
            this.form.state         = ''
            this.form.mobile_number = ''
            this.form.pincode       = ''
            this.$toast.show(response.data.message,{
              type: 'success',
              position: 'top-right',
              duration: '2000'
            })
          }
      })
      .catch(function (error) {
          console.log(error);
      })
    }
  }
})
</script>
