<template>
  <div class="list-page-wrap events-page-wrap">
    <!-- <h1>This is an ProductList</h1> -->
    <div class="breadcrumb" style="background: url('https://picsum.photos/id/1035/1920/400')">
      <div class="container">
        <h1 class="title">Pay Now</h1>
        <!--<p class="text">The racket was developed with the new "triple speed system frame" which combines exceptional power and great handling, and overall improve execution with power and more control. Designed with compact frame, which increases the speed in the swing, and overall improve execution with power and more control.</p>-->
      </div>
    </div>
    <div class="container">
      <div class="row login-form card-strip mb-5 mt-5 align-items-center">
            <div class="col-lg-6">
              <div class="vect-img">
                <img src=".././assets/card-payment.png" alt="" class="img">
              </div>
            </div>
            <div class="col-lg-6">
                <form  @submit.prevent="sendData" name="frmStripe" id="frmstripe" class="frmstripe">
                    <div class="row mb-3">
                        <div class="col-lg-12 form-group">
                            <label>Card Number</label>
                            <input class="form-control" v-model="form.card_no" size="20" type="text" name="card_no">
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-4 form-group">
                            <label>CVC</label>
                            <input class="form-control" v-model="form.cvc" placeholder="ex. 311" size="3" type="text" name="cvv">
                        </div>
                        <div class="col-lg-4 form-group">
                            <label>Expiration</label>
                            <input class="form-control" placeholder="MM" size="2" v-model="form.exp_month" type="text" name="expiry_month">
                        </div>
                        <div class="col-lg-4 form-group">
                            <label> </label>
                            <input class="form-control" placeholder="YYYY" size="4" v-model="form.exp_year" type="text" name="expiry_year">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 form-group">
                            <button class="form-control btn btn-primary submit-button" type="submit" style="margin-top: 10px;">
                              <span v-if="!showLoading">
                                Pay AED {{form.grand_total}} »
                              </span>
                              <div v-if="showLoading" class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
  components: {
  },
  data () {
    return {
      loading: false,
      showLoading: false,
      url:'',
      form:{
        card_no:'',
        exp_month:'',
        exp_year:'',
        cvc:'',
        grand_total:'',
        temp_uid:'',
        payment_option_id:2
      }
    };
  },
  created()
  {
      this.getTempOrder();
  },
  methods: {
    getTempOrder()
    {
        this.url            = this.$route.fullPath.split("/");
        this.form.temp_uid  = this.url[2]
        axios.post('https://admin.badmintonsouq.com/api/v1/get-temp-stringing',{
            temp_uid:this.form.temp_uid
        }, {
            headers: {
                Authorization  : 'Bearer ' + this.$store.state.auth.token
            }
        })
        .then((response) => {
            this.form.grand_total       = response.data.data.grand_total
        })
        .catch(function (error) {
            console.log(error);
        })
    },
    async sendData(){
      this.showLoading = true;
      await axios.post('https://admin.badmintonsouq.com/api/v1/create-stringing-session',this.form, 
        {
            headers: {
                Authorization  : 'Bearer ' + this.$store.state.auth.token
            }
        })
        .then((response) => {
            if(response.data.success == true)
            {
              if(response.data.data.next_action)
              {
                window.location.href = response.data.data.next_action.redirect_to_url.url
              }
              this.$toast.show(response.data.message,{
                type: 'success',
                position: 'top-right',
                duration: '2000'
              })
              setTimeout(() => window.location.href = '/stringing-success', 2000);
              this.showLoading = false;
            }
            else
            {
              this.$toast.show(response.data.message,{
                type: 'error',
                position: 'top-right',
                duration: '10000'
              })
              this.showLoading = false;
            }
        })
        .catch(function (error) {
            console.log(error);
            this.showLoading = false;
        })
    }
  },
};
</script>