<template>
  <div class="login-page">
    <div class="container">
      <div class="login-form pay-response mb-3">
              <div class="vect-img">
                <img src=".././assets/570180249630.png" alt="" class="img">
              </div>
        <p id="error">Payment Failed!</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Failed',
  data (){

    return{

    }
  },
  mounted(){
  },
  methods:{
  }
}
</script>

<style>
#error{
  color:red;
}
</style>