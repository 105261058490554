<template>
  <div class="list-page-wrap batstring-list-page-wrap">
    <!-- <h1>This is an ProductList</h1> -->
    <div class="breadcrumb" style="background: url('https://picsum.photos/id/1035/1920/400')">
      <div class="container">
        <h1 class="title">{{"Bat String"}}</h1>
         <!-- <p class="text">The racket was developed with the new "triple speed system frame" which combines exceptional power and great handling, and overall improve execution with power and more control. Designed with compact frame, which increases the speed in the swing, and overall improve execution with power and more control.</p> -->
      </div>
    </div>

  <div class="string-section-wrap" v-if="!checkOut">

      <div class="brand-carousel">
        <!-- <div class="container">
          <div class="top-sec">
            <a href="#" class="link-btn">View All</a>
          </div>
        </div> -->
        
          
        <div class="container"   v-if="showLoading">
          <h3 class="mt-3">Choose a Brand</h3>
          <div class="special-cat-products products-carousel">
            <swiper
            :slidesPerView="3" 
            :spaceBetween="10" 
            :autoplay='{
                "delay": 2500,
                "disableOnInteraction": false
              }' 
              :navigation="false"
              :breakpoints='{
                "640": {
                  "slidesPerView": 4,
                  "spaceBetween": 10
                },
                "768": {
                  "slidesPerView": 6,
                  "spaceBetween": 20
                },
                "1024": {
                  "slidesPerView": 8,
                  "spaceBetween": 20
                }
              }' class="single-image-slider"> 
            <swiper-slide >
            <div class="carousel-item-single">
              <free-style-shimmer
                :is-loading="showLoading"
                height="100px"
                width="100px"
                border-radius="10px"
                color="#bdbdbd"
              />
            </div>
            </swiper-slide>
            <swiper-slide >
            <div class="carousel-item-single">
              <free-style-shimmer
                :is-loading="showLoading"
                height="100px"
                width="100px"
                border-radius="10px"
                color="#bdbdbd"
              />
            </div>
            </swiper-slide>
            <swiper-slide >
            <div class="carousel-item-single">
              <free-style-shimmer
                :is-loading="showLoading"
                height="100px"
                width="100px"
                border-radius="10px"
                color="#bdbdbd"
              />
            </div>
            </swiper-slide>
            <swiper-slide >
            <div class="carousel-item-single">
              <free-style-shimmer
                :is-loading="showLoading"
                height="100px"
                width="100px"
                border-radius="10px"
                color="#bdbdbd"
              />
            </div>
            </swiper-slide>
            <swiper-slide >
            <div class="carousel-item-single">
              <free-style-shimmer
                :is-loading="showLoading"
                height="100px"
                width="100px"
                border-radius="10px"
                color="#bdbdbd"
              />
            </div>
            </swiper-slide>
            <swiper-slide >
            <div class="carousel-item-single">
              <free-style-shimmer
                :is-loading="showLoading"
                height="100px"
                width="100px"
                border-radius="10px"
                color="#bdbdbd"
              />
            </div>
            </swiper-slide>
            <swiper-slide >
            <div class="carousel-item-single">
              <free-style-shimmer
                :is-loading="showLoading"
                height="100px"
                width="100px"
                border-radius="10px"
                color="#bdbdbd"
              />
            </div>
            </swiper-slide>
          </swiper>
            </div>
            </div>


        <div class="container">
          <h3 class="mt-3">Choose a Brand</h3>
          <div class="special-cat-products products-carousel">


            <swiper
            :slidesPerView="3" 
            :spaceBetween="10" 
            :autoplay='{
                "delay": 2500,
                "disableOnInteraction": false
              }' 
              :navigation="false"
              :breakpoints='{
                "640": {
                  "slidesPerView": 4,
                  "spaceBetween": 10
                },
                "768": {
                  "slidesPerView": 6,
                  "spaceBetween": 20
                },
                "1024": {
                  "slidesPerView": 8,
                  "spaceBetween": 20
                }
              }' class="single-image-slider"> 
            <swiper-slide 
            v-for="(brand, index) in brand_filter"
            :key="index"
            >
            <div class="carousel-item-single">
              <div class="pro-img">
                <a href="#" @click.prevent="getProducts(brand.id,brand), brandselected = brand.id" class="link" :class="{highlight:brandselected == brand.id}">
                  <img :src="base_url+brand.image" />
                </a>
              </div>
            </div>
            </swiper-slide>
          </swiper>
          </div>
        </div>
      </div>

      <!-- <ProductList :Listdata="productLists"/> -->
      
      <div class="container" v-if="productLists.length != 0">
        <div class="special-cat-products products-carousel">

          <!-- Shimmer Skelton -->
          <swiper v-if="loadingdata"
          :slidesPerView="2" 
          :spaceBetween="10" 
          :autoplay='{
              "delay": 2500,
              "disableOnInteraction": false
            }' 
            :navigation="true"
            :breakpoints='{
              "640": {
                "slidesPerView": 2,
                "spaceBetween": 10
              },
              "768": {
                "slidesPerView": 4,
                "spaceBetween": 20
              },
              "1024": {
                "slidesPerView": 5,
                "spaceBetween": 20
              }
            }' class="product-slider"> 
          <swiper-slide>
          <div class="carousel-item-single">
            <card-shimmer :is-loading="loadingdata" />
          </div>
          </swiper-slide>
          <swiper-slide>
          <div class="carousel-item-single">
            <card-shimmer :is-loading="loadingdata" />
          </div>
          </swiper-slide>
          <swiper-slide>
          <div class="carousel-item-single">
            <card-shimmer :is-loading="loadingdata" />
          </div>
          </swiper-slide>
          <swiper-slide>
          <div class="carousel-item-single">
            <card-shimmer :is-loading="loadingdata" />
          </div>
          </swiper-slide>
          <swiper-slide>
          <div class="carousel-item-single">
            <card-shimmer :is-loading="loadingdata" />
          </div>
          </swiper-slide>
        </swiper>
          <!-- End Shimmer Skelton -->


          <swiper
          :slidesPerView="2" 
          :spaceBetween="10" 
          :autoplay='{
              "delay": 2500,
              "disableOnInteraction": false
            }' 
            :navigation="true"
            :breakpoints='{
              "640": {
                "slidesPerView": 2,
                "spaceBetween": 10
              },
              "768": {
                "slidesPerView": 4,
                "spaceBetween": 20
              },
              "1024": {
                "slidesPerView": 5,
                "spaceBetween": 20
              }
            }' class="product-slider"> 
          <swiper-slide 
          v-for="(Listdata, index) in productLists"
          :key="index"
          >
          <div class="carousel-item-single" @click.prevent="productSelected = Listdata.id" :class="{highlight:productSelected == Listdata.id}">
            <div class="pro-img">
              <a href="#" @click.prevent="getColors(Listdata.id,Listdata)">
              <img :src="Listdata.image" /></a>
              <!-- <span class="offer">{{Listdata.offer}}</span> -->
            </div>
            <div class="pro-info">
              <a href="#" style="color:black;" @click.prevent="getColors(Listdata.id,Listdata)">
                <p class="pro-name" v-if="Listdata.title.length<50">{{Listdata.title}}</p>
                <p class="pro-name" v-if="Listdata.title.length>50">{{Listdata.title.substring(0,50)+"..."}}</p>
              </a>
              <div class="price">
                <p class="old-p">{{Listdata.mrp}}</p>
                <p class="new-p">{{Listdata.sale_cost}}</p>
              </div>
            </div>
          </div>
          </swiper-slide>
        </swiper>
        </div>
      </div>
      <div class="container mb-5 mt-5" v-if="strings" style="text-align:center;"><h3>No Strings Found for the Selected Brand, Try with another brand.</h3></div>
      
      <div class="container">

                <div class="product-attr bartstring-attr" v-if="colors.length != 0">
                  <div class="single-attr">
                      <h4 class="title">Select your Color</h4>
                      <div class="attr-items">
                        <div class="form-check form-check-inline"
                        v-for="(Listdata, index) in colors"
                        :key="index">
                          <input class="form-check-input" type="radio" :id="Listdata.id" @click="chooseColor(Listdata.id,Listdata)">
                          <label class="form-check-label" :for="Listdata.id" @click="colorSelected = Listdata.id" :class="{highlight:colorSelected == Listdata.id}" :style="'width:30px; height:30px; background-color:'+Listdata.color">
                          </label>
                        </div>
                      </div>
                  </div>
                </div>

                <div class="product-actions mb-5" v-if="tensions.length != 0">
                    <select class="form-select" aria-label="Default select example" v-model="tension" v-on:change="setTension">
                      <option :key="0" v-bind:value="{}" selected>Select your Tension</option>
                      <option v-for="tension in tensions" :key="tension.id" v-bind:value="tension">{{tension.name}}</option>
                    </select>
                </div>
              <div class="action-sec" v-if="agree">
                <div class="mb-3 icon-input">
                  <textarea class="form-control" name="" id=""  rows="5" placeholder="Additional Comments and Requirement" v-model="comments"></textarea>
                </div>
                <div class="wrap-act-sec">
                  <div>
                <input class="form-check-input" id="agree" type="checkbox" :checked="is_agree" @click="agreeTerms">&nbsp;&nbsp;
                          <label class="form-check-label" for="accept">
                          Please read and accept the <a href="#">Terms and Condition</a>
                          </label>
                          </div>
                  <button class="btn btn-primary checkout-btn" @click.prevent="confirmCheckout" >
                      <span>
                        {{"Continue to Payment"}} <i class="bx bx-right-arrow-alt"></i>
                      </span>
                  </button>
                  
                </div>
              </div>
      </div>
      
  </div>
    
  <div class="payment-section-string cart-detail-sec" v-else>
      <div class="container">
        <button href="" class="btn btn-primary back-btn" @click="checkOut = false">
          <span><i class="bx bx-left-arrow-alt"></i> Back to Strings</span></button>
        <div class="row">
          <div class="col-lg-8">
            <div class="address-sec">
              <div class="top-sec">
                <h4 class="title">Delivery Address</h4>
                <a href="#" class="link-btn"   data-bs-toggle="modal" data-bs-target="#AddressModal" >Add New Address</a>
              </div>
              <div class="address-list">
                <p id="error">{{error_message}}</p>
                <ul class="lists">
                  <li class="addrs-item" v-for="address in addressList" :key="address.id">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" :id="address.id" v-on:change="setData(address.id)">
                  <label class="form-check-label" :for="address.id">
                    <p class="name">{{address.contact_name}}</p>
                    <p class="text">{{address.house_name}} - {{address.landmark}} - {{address.city}} - {{address.state}}</p>
                    <p class="mobile">{{((address.pincode) ? 'PO Box - '+address.pincode : '')}}</p>
                    <p class="mobile">{{address.mobile_number}}</p>
                    <div class="actions">
                      <a href="" class="link"></a>
                    </div>
                  </label>
                  </li>
                </ul>
              </div>
            </div>
            <div class="cart-list-sec selected-string-list">
              <div class="top-sec">
                <h4 class="title">Selected String</h4>
                <!-- <a href="#" class="link-btn"   data-bs-toggle="modal" data-bs-target="#AddressModal" >Add New Address</a> -->
              </div>
              <div class="products-list top-sec">
                <div class="product-item">
                  <p class="text"> Product</p>
                </div>
                <!-- <div class="qty-sec">
                  <p class="text"> 	Quantity</p>
                </div> -->
                <div class="total-price">
                  <p class="text"> Total</p>
                </div>
              </div>
              <div class="products-list">
                <div class="product-item">
                  <div class="product-img">
                    <!-- <img v-if="cart.product.parameters.variant != null" :src="cart.product.parameters.variant.image" alt="" class="image"> -->
                    <img :src="item.image" alt="" class="image">
                  </div>
                  <div class="product-info">
                    <a href="" style="text-decoration:none; color:black;">
                    <span class="brand">{{brand.title}}</span>
                    <p class="pname">{{item.title}} </p>
                      <p class="pname p-attrb" v-if="tension_id != 0">Tension: {{tension.name}}</p>
                      <p class="pname p-attrb" v-if="color_id != 0">Color: <span class="color-pallet" :style="'background-color:'+color.color"></span></p>
                    </a>
                    <div class="price">
                      <p class="old-p" style="text-decoration: line-through; color: grey;">AED {{item.mrp}}</p>
                      <p class="new-p">AED {{item.sale_cost}}</p>
                    </div>
                  </div>
                </div>
                <!-- <div class="qty-sec">
                  <div class="qty-container">
                    <button class="btn btn-min"><i class='bx bx-minus' ></i></button>
                    <input type="number" class="form-control" id="" placeholder="" value="1">
                    <button class="btn btn-add"><i class='bx bx-plus' ></i></button>
                  </div>
                  <a href="" class="link">Remove</a>
                </div> -->
                <div class="total-price">
                  <p class="total">AED {{item.sale_cost}}</p>
                </div>
              </div>
            </div>
            <div class="cart-list-sec selected-string-list comments-string">
              <div class="top-sec">
                <h4 class="title">Additional Comments and Requirements</h4>
              </div>
              <div class="content-txt">
                <p>{{comments}}</p>
              </div>
            </div>
            <div>
             <!-- <p>You are eligible for <span style="color:green;">FREE DELIVERY</span></p> -->
            </div>
          </div>
          <div class="col-lg-4">
            <div class="price-deatils">
              <table class="table price-table">
                <tbody>
                  <tr>
                      <td>Sub Total</td>
                      <td>AED {{item.sale_cost}}</td>
                  </tr>
                  <tr>
                    <td>Stringing Charge</td>
                    <!-- <td v-if="delivery_charge != '0.00'">AED {{delivery_charge}}</td> -->
                    <td>AED {{stringing_cost}}</td>
                  </tr>
                  <tr>
                    <td>Tax(VAT 5%)</td>
                    <td>AED {{tax}}</td>
                  </tr>
                  <tr>
                    <th>Grand Total</th>
                    <th>AED {{grand_total}}</th>
                  </tr>
                  <tr>
                    <th  colspan="2" style="text-align:left;">
                      <input class="form-check-input" type="radio" checked v-on:change="setPaymentOption(1)" name="paymentOption">&nbsp;&nbsp;
                      <label class="form-check-label">
                       <i class='icon bx bx-money'></i> Cash On Delivery
                      </label>
                    </th>
                  </tr>
                  <tr>
                    <th colspan="2"  style="text-align:left;">
                      <input class="form-check-input" type="radio" v-on:change="setPaymentOption(2)" name="paymentOption">&nbsp;&nbsp;
                      <label class="form-check-label">
                       <i class='icon bx bx-credit-card-front' ></i> Pay Online
                      </label>
                    </th>
                  </tr>
                </tbody>
              </table>
              <button class="btn btn-primary checkout-btn" @click.prevent="submitOrder">
                  <span v-if="!showLoad">
                    {{paytext}} <i class="bx bx-right-arrow-alt"></i>
                  </span>
                  <div v-if="showLoad" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
              </button>
              <div class="payment-note">
                <p class="text"><i class='bx bx-lock-alt' ></i> 100% Secure Payments</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
<!-- Add New Address Modal -->
<div class="modal fade auth-modal organize-modal" id="AddressModal" tabindex="-1" aria-labelledby="OrganizeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="AddressModalLabel">Add New Address</h5>
        <button type="button" class="btn-close" id="AddressModalClose" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
            <div class="login-form">
              <form @submit.prevent="addAddress">
                <p id="error">{{modal_error_message}}</p>
                <div class="mb-3 icon-input">
                    <input type="text" class="form-control" v-model="form.contact_name" id="exampleFormControlInput1" placeholder="Name">
                </div>
                <div class="row form-group">
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form.house_name" id="exampleFormControlInput1" placeholder="House Name/Building/Appartment No.">
                  </div>
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form.landmark" id="exampleFormControlInput1" placeholder="Landmark">
                  </div>
                </div>
                <div class="row form-group">
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form.city" id="exampleFormControlInput1" placeholder="City">
                  </div>
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form.state" id="exampleFormControlInput1" placeholder="State">
                  </div>
                </div>
                <div class="row form-group">
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form.mobile_number" id="exampleFormControlInput1" placeholder="Phone">
                  </div>
                  <div class="mb-3 icon-input col-md-6">
                    <input type="text" class="form-control" v-model="form.pincode" id="exampleFormControlInput1" placeholder="PO Box (Optional)">
                  </div>
                </div>
                <!-- <div class="mb-3 icon-input">
                  <input type="text" class="form-control" id="exampleFormControlInput1"  placeholder="Location">
                  <i class='icon bx bx-current-location'></i>
                </div>
                <div class="mb-3">
                  <textarea class="form-control" id="exampleFormControlTextarea1" placeholder="Address..." rows="4"></textarea>
                </div>
                <div class="mb-3 icon-input">
                    <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="PO Box (Optional)">
                </div> -->
                <button class="btn btn-primary login-btn">
                  <span v-if="!showLoad">
                    Submit <i class="bx bx-right-arrow-alt"></i>
                  </span>
                  <div v-if="showLoad" class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              </form>
            </div>
      </div>
    </div>
  </div>
  </div>
  </div>
</template>

<script>
// import brandsCarousel from '@/components/brandsCarousel.vue'
// import ProductList from '@/components/ProductList.vue'
// import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import axios from 'axios'
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.min.css';
import SwiperCore, {
  Autoplay,Navigation
} from 'swiper';
SwiperCore.use([Autoplay,Navigation]);
// import {mapMutations } from 'vuex';
// import {
//     LOADING_SPINNER_SHOW_MUTATION,
// } from '../store/storeconstants';
export default({
  name: 'productList',
  components: {
      // brandsCarousel,
      Swiper,
      SwiperSlide,
      // VPagination
  },
  data (){

    return{
      showLoading: false,
      brandselected: undefined,
      productSelected: undefined,
      colorSelected: undefined,
      checkOut: false,
      productLists :[],
      base_url:'https://admin.badmintonsouq.com',
      brand_filter:[],
      brand_id:'',
      brand:{},
      item_id:'',
      color:{},
      colors:[],
      color_id:0,
      tension_id:0,
      tensions:[],
      stringing_cost:'',
      strings:false,
      comments:'',
      is_agree:false,
      product:{},
      location:'',
      addressList:[],
      address_id:'',
      tension:{},
      token:'',
      agree:false,
      error_message:'',
      modal_error_message:'',
      tax:'',
      form:{
        contact_name:'',
        house_name:'',
        landmark:'',
        city:'',
        state:'',
        mobile_number:'',
        pincode:''
      },
      grand_total:'',
      paytext:'Place Order',
      payment_option_id:1
    }
  },
  created(){
    this.getBrands();
    // this.getFilters();
  },
  mounted(){
    this.token = this.$store.state.auth.token
    this.location = this.$store.state.auth.address
    if(this.location == null)
    {
      this.location = 'UAE'
    }
  },
  methods:{
    showPayment(){

    },
    async getBrands(){
      this.showLoading = true;
      await axios.post('https://admin.badmintonsouq.com/api/v1/get-brands', {
        slug:'bat-stringing'
      })
      .then((response) => {
          this.brand_filter = response.data.data.brands.brands
      })
      .catch(function (error) {
          this.showLoading = false;
          console.log(error);
      })
      this.showLoading = false;
    },
    // ...mapMutations({
    //     showLoading: LOADING_SPINNER_SHOW_MUTATION,
    // }),
    getProducts(brand_id,brand){
      this.brand_id = brand_id
      this.brand = brand
      axios.post('https://admin.badmintonsouq.com/api/v1/get-brand-strings', {
        brand_id: this.brand_id,
      })
      .then((response) => {
          this.productLists = response.data.data.strings
          this.stringing_cost = response.data.data.stringing_cost
          if(this.productLists.length == 0)
          {
            this.strings = true
          }
          else{
            this.strings = false
          }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    getUserAddress(){
      this.token = this.$store.state.auth.token
      axios.post('https://admin.badmintonsouq.com/api/v1/address-list', {},{
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
          this.addressList = response.data.data.delivery_address
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    setTension()
    {
      this.tension_id = this.tension.id
    },
    getColors(item_id,item){
      this.item_id = item_id
      this.item = item
      axios.post('https://admin.badmintonsouq.com/api/v1/get-string-colors', {
        item_id: this.item_id,
      })
      .then((response) => {
        this.getTensions();
        this.colors = response.data.data.colors
        if(this.colors.length == 0)
        {
          this.color_id = 0
        }
        this.agree = true
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    getTensions(){
      axios.post('https://admin.badmintonsouq.com/api/v1/get-tensions', {})
      .then((response) => {
          this.tensions = response.data.data.tensions
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    chooseColor(color_id,color)
    {
      this.color_id = color_id
      this.color = color
    },
    setData(address_id){
      this.address_id = address_id
    },
    getFilters(){
      axios.post('https://admin.badmintonsouq.com/api/v1/get-product-filters', {
        main_slug: 'category',
        sub_slug: 'strings'
      })
      .then((response) => {
          this.brand_filter = response.data.data.brands
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    addAddress(){
      this.showLoad = true
      axios.post('https://admin.badmintonsouq.com/api/v1/create-address', this.form,{
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
          this.showLoad = false
          if(response.data.success == false){
            this.modal_error_message = response.data.message
          }else{
            this.getUserAddress()
            document.getElementById("AddressModalClose").click();
            this.form.contact_name  = ''
            this.form.house_name    = ''
            this.form.landmark      = ''
            this.form.city          = ''
            this.form.state         = ''
            this.form.mobile_number = ''
            this.form.pincode       = ''
            this.$toast.show(response.data.message,{
              type: 'success',
              position: 'top-right',
              duration: '2000'
            })
          }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    agreeTerms()
    {
      if(this.is_agree == false)
      {
        this.is_agree = true
      }
      else{
        this.is_agree = false
      }
    },
    confirmCheckout()
    {
      if(this.colors.length != 0 && this.color_id == 0)
      {
        this.$swal.fire('Please choose a color','',"error")
      }
      else if(this.is_agree != true)
      {
        this.$swal.fire('Please agree to the terms and conditions','',"error")
      }
      else
      {
        this.getUserAddress()
        this.tax = parseFloat(this.item.sale_cost*5/100).toFixed(2)
        this.grand_total = parseFloat(+this.item.sale_cost*5/100 + +this.stringing_cost + +this.item.sale_cost).toFixed(2)
        this.checkOut = true
      }
    },
    setPaymentOption(payment_option_id)
    {
      this.payment_option_id = payment_option_id
      if(this.payment_option_id == 1)
      {
        this.paytext = 'Place order'
      }
      else if(this.payment_option_id == 2)
      {
        this.paytext = 'Pay Now'
      }
    },
    submitOrder(){
      if(this.payment_option_id == 1)
      {
        this.showLoad = true
        axios.post('https://admin.badmintonsouq.com/api/v1/create-bat-stringing', {
          user_address_id: this.address_id,
          payment_option_id:this.payment_option_id,
          location:this.location,
          brand_id:this.brand_id,
          item_id:this.item_id,
          color_id:this.color_id,
          tension_id:this.tension_id,
          comments:this.comments,
          item_cost:this.item.sale_cost,
          stringing_cost:this.stringing_cost
        },{
          headers: {
              Authorization  : 'Bearer ' + this.token
          }
        })
        .then((response) => {
          this.showLoad = false
          if(response.data.success == true){
            this.$swal.fire(response.data.message,'',"success")
            .then(() => {
              window.location.href = '/account'
            })
          }else{
            this.error_message = response.data.message
          }
        })
        .catch(function (error) {
            console.log(error);
        })
      }
      else if(this.payment_option_id == 2)
      {
        this.showLoad = true
        axios.post('https://admin.badmintonsouq.com/api/v1/generate-temp-bat-stringing', {
          user_address_id: this.address_id,
          payment_option_id:this.payment_option_id,
          location:this.location,
          brand_id:this.brand_id,
          item_id:this.item_id,
          color_id:this.color_id,
          tension_id:this.tension_id,
          comments:this.comments,
          item_cost:this.item.sale_cost,
          stringing_cost:this.stringing_cost
        },
        {
          headers: {
              Authorization  : 'Bearer ' + this.token
          }
        })
        .then((response) => {
          this.showLoad = false
          if(response.data.success == true)
          {
            window.location.href = '/pay-stringing-online/'+response.data.data.temp_uid
          }
          else{
            this.$toast.show(response.data.message,{
              type: 'error',
              position: 'top-right',
              duration: '2000'
            })
          }
        })
        .catch(function (error) {
            console.log(error);
        })
      }
    }
  }
})
</script>
