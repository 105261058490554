<template>
  <div class="deatil-page-wrap cart-page-wrap">
    <!-- <h1>This is an ProductList</h1> -->
    <div class="breadcrumb">
      <div class="container">
        <div class="left-sec">
          <a href="#" @click.prevent="this.$router.go(-1)" class="back-link"><i class='bx bx-left-arrow-alt' ></i></a>
          <h1 class="title">Cart</h1>
        </div>
        <ul class="links">
          <li class="link"><a href="/">Home</a></li>
          <li class="link">/ Cart</li>
        </ul>
      </div>
    </div>
    <div class="cart-detail-sec">
      <div class="container">
        

        <div class="row" v-if="carts.length != 0">
          <div class="col-lg-8">
            <div class="cart-list-sec">
              <div class="products-list top-sec">
                <div class="product-item">
                  <p class="text"> Product</p>
                </div>
                <div class="qty-sec">
                  <p class="text"> 	Quantity</p>
                </div>
                <div class="total-price">
                  <p class="text"> Total</p>
                </div>
              </div>
              <div class="products-list" v-for="cart in carts" :key="cart.id">
                <div class="product-item">
                  <div class="product-img">
                    <img v-if="cart.product.parameters.variant != null" :src="cart.product.parameters.variant.image" alt="" class="image">
                    <img v-else :src="cart.product.image_path" alt="" class="image">
                  </div>
                  <div class="product-info">
                  <a :href="$router.resolve({path: '/products/'+cart.product.slug}).href" style="text-decoration:none; color:black;">
                    <span class="brand">{{cart.product.brand_title}}</span>
                    <p class="pname" v-if="cart.size_id && cart.product.parameters.variant != null">{{cart.product.title}} <br> Size: {{cart.product.parameters.variant.size.size}}</p>
                    <p class="pname" v-else>{{cart.product.title}}</p>
                  </a>
                  <div class="rating">
                    <star-rating 
                    :rating="cart.product.rating" 
                    :read-only="true" 
                    :increment="0.5" 
                    :star-size="15"
                    inactive-color="#E1E1E1" 
                    active-color="#A5A5A5"
                    :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"></star-rating>
                    </div>
                    <div class="price">
                      <p class="old-p" style="text-decoration: line-through; color: grey;">AED {{cart.product.mrp}}</p>
                      <p class="new-p">AED {{cart.product.sale_cost}}</p>
                    </div>
                    <div v-if="cart.addon_label != null">
                      <p>Label Charge- AED {{cart.addon_charge}}<br>
                      Label Text- {{cart.addon_label}}</p>
                    </div>
                    <div v-if="cart.stringing_charge != 0.00">
                      <p>Stringing Charge- AED {{cart.stringing_charge}}</p>
                    </div>
                    <div>
                      <p v-if="cart.stock_status == 0 && cart.stock_quantity == null"><span style="color:red;">Out of Stock, Please Remove the item.</span></p>
                      <p v-if="cart.stock_status == 0 && cart.stock_quantity != null"><span style="color:red;">Out of stock, Only {{cart.stock_quantity}} items available, Please decrease the quantity.</span></p>
                    </div>
                  </div>
                </div>
                <div class="qty-sec">
                  <div class="qty-container">
                    <button class="btn btn-min" @click.prevent="decrementCartItem(cart.id,cart.product.item_id)"><i class='bx bx-minus' ></i></button>
                    <input type="number" class="form-control" id="" placeholder="" :value="cart.product.quantity">
                    <button class="btn btn-add" @click.prevent="incrementCartItem(cart.id,cart.product.item_id)"><i class='bx bx-plus' ></i></button>
                  </div>
                  <a href="" @click.prevent="removeCartItem(cart.id,cart.product.item_id)" class="link">Remove</a>
                </div>
                <div class="total-price">
                  <p class="total">AED {{parseFloat(+cart.product.total + +cart.addon_charge + +cart.stringing_charge).toFixed(2)}}</p>
                </div>
              </div>
            </div>
            <div>
             <p v-if="is_delivery == false">Shop AED {{balance_free_delivery_amount}} more to your cart for <span style="color:green;">FREE DELIVERY</span></p>
             <p v-else>You are eligible for <span style="color:green;">FREE DELIVERY</span></p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="price-deatils">
              <table class="table price-table">
                <tbody>
                  <tr>
                      <td>Sub Total</td>
                      <td>AED {{total_cost}}</td>
                  </tr>
                  <tr>
                    <td>Delivery</td>
                    <td v-if="delivery_charge != '0.00'">AED {{delivery_charge}}</td>
                    <td v-else style="color:green;">FREE</td>
                  </tr>
                  <tr>
                    <td>Tax(VAT 5%)</td>
                    <td>AED {{tax}}</td>
                  </tr>
                  <tr>
                    <th>Grand Total</th>
                    <th>AED {{grand_total}}</th>
                  </tr>
                </tbody>
              </table>
                <router-link class="btn btn-primary checkout-btn" to="/checkout">
                Checkout <i class='bx bx-right-arrow-alt' ></i></router-link>
              <div class="payment-note">
                <p class="text"><i class='bx bx-lock-alt' ></i> 100% Secure Payments</p>
              </div>
            </div>
          </div>
        </div>



        <!-- Shimmer Loading -->
        <div class="row" v-else-if="showLoading">
          <div class="col-lg-8">
            <div class="cart-list-sec">
              <div class="products-list top-sec">
                <div class="product-item" style="display: block;">
                  <paragraph-shimmer :lines="1"/>
                </div>
                <div class="qty-sec">
                  <paragraph-shimmer :lines="1"/>
                </div>
                <div class="total-price">
                  <paragraph-shimmer :lines="1"/>
                </div>
              </div>
              <div class="products-list">
                <div class="product-item">
                  <div class="product-img">
                    <free-style-shimmer
                      height="80px"
                      width="80px"
                      border-radius="10px"
                      color="#bdbdbd"
                    />
                  </div>
                  <div class="product-info" style="width: 50%">
                    <paragraph-shimmer :lines="4"/>
                  </div>
                </div>
                <div class="qty-sec">
                    <free-style-shimmer
                      height="30px"
                      width="80%"
                      border-radius="10px"
                      color="#bdbdbd"
                    />
                </div>
                <div class="total-price">
                    <paragraph-shimmer :lines="1"/>
                </div>
              </div>
              <div class="products-list">
                <div class="product-item">
                  <div class="product-img">
                    <free-style-shimmer
                      height="80px"
                      width="80px"
                      border-radius="10px"
                      color="#bdbdbd"
                    />
                  </div>
                  <div class="product-info" style="width: 50%">
                    <paragraph-shimmer :lines="4"/>
                  </div>
                </div>
                <div class="qty-sec">
                    <free-style-shimmer
                      height="30px"
                      width="80%"
                      border-radius="10px"
                      color="#bdbdbd"
                    />
                </div>
                <div class="total-price">
                    <paragraph-shimmer :lines="1"/>
                </div>
              </div>
              <div class="products-list">
                <div class="product-item">
                  <div class="product-img">
                    <free-style-shimmer
                      height="80px"
                      width="80px"
                      border-radius="10px"
                      color="#bdbdbd"
                    />
                  </div>
                  <div class="product-info" style="width: 50%">
                    <paragraph-shimmer :lines="4"/>
                  </div>
                </div>
                <div class="qty-sec">
                    <free-style-shimmer
                      height="30px"
                      width="80%"
                      border-radius="10px"
                      color="#bdbdbd"
                    />
                </div>
                <div class="total-price">
                    <paragraph-shimmer :lines="1"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="price-deatils" style="background: #f5f5f5;">
              <table class="table price-table">
                <tbody>
                  <tr>
                      <td><paragraph-shimmer :lines="1"/></td>
                      <td><paragraph-shimmer :lines="1"/></td>
                  </tr>
                  <tr>
                    <td><paragraph-shimmer :lines="1"/></td>
                    <td><paragraph-shimmer :lines="1"/></td>
                  </tr>
                  <tr>
                    <td><paragraph-shimmer :lines="1"/></td>
                    <td><paragraph-shimmer :lines="1"/></td>
                  </tr>
                  <tr>
                    <td><paragraph-shimmer :lines="1"/></td>
                    <td><paragraph-shimmer :lines="1"/></td>
                  </tr>
                </tbody>
              </table>
                    <free-style-shimmer
                      height="40px"
                      width="100%"
                      border-radius="0px"
                      color="#bdbdbd"
                    />
                    <br>
                    <paragraph-shimmer :lines="1"/>
            </div>
          </div>
        </div>
        
        <div class="row" v-else>
          <div class="empty-stat-wrap">
            <img src=".././assets/cart_empty_cart_co35.png" alt="" class="img-empty">
            <h3>Your cart is empty!</h3>
          </div>
        </div>
      </div>
    </div>

    
  <div class="bestselling">
    <div class="container">
      <div class="top-sec">
        <!--<h3 class="title">You may like also</h3>-->
      </div>
    </div>
    <!--<ProductCarousel :Listdata="productLists"/>-->
  </div>
    
    <div class="brand-carousel">
      <div class="container">
      </div>
      <!--<brandsCarousel :Listdata="brands"/>-->
    </div>
  </div>
</template>

<script>
// import brandsCarousel from '@/components/brandsCarousel.vue'
import StarRating from 'vue-star-rating'
// import ProductCarousel from '@/components/ProductCarousel.vue'
import axios from 'axios'
import { mapActions } from "vuex";
export default({
  name: 'productList',
  components: {
      // brandsCarousel,
      // ProductCarousel,
      StarRating
  },
  data (){

    return{
      showLoading: false,
      count: 1,
      count1: 1,
      count2: 1,
      brands :[],
      productLists :[],
      productImages :[],
      cart_count:0,
      carts:[],
      grand_total:0.00,
      delivery_charge:0.00,
      total_cost:0.00,
      token:'',
      tax:'',
      is_delivery:'',
      balance_free_delivery_amount:''
    }
  },
  created(){
    // this.getProducts();
    this.getCart();
  },
  methods: {
    ...mapActions(["CartCount"]),
    // getProducts(){
    //   axios.post('https://admin.badmintonsouq.com/api/v1/get-best-selling-products', {})
    //   .then((response) => {
    //       this.productLists = response.data.data.best_selling
    //   })
    //   .catch(function (error) {
    //       console.log(error);
    //   })
    // },
    async getCart(){
      this.showLoading = true;
      this.token = this.$store.state.auth.token
      await axios.post('https://admin.badmintonsouq.com/api/v1/get-user-carts',{}, {
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
          this.carts = response.data.data.carts
          this.total_cost = parseFloat(response.data.data.total_cost).toFixed(2)
          this.delivery_charge = parseFloat(response.data.data.delivery_charge).toFixed(2)
          this.grand_total = parseFloat(response.data.data.grand_total).toFixed(2)
          this.tax = parseFloat(response.data.data.total_cost*5/100).toFixed(2)
          this.is_delivery = response.data.data.is_delivery_free
          this.balance_free_delivery_amount = response.data.data.balance_free_delivery_amount
      })
      .catch(function (error) {
          console.log(error);
        this.showLoading = false;
      })
      this.showLoading = false;
    },
    decrementCartItem(cart_id,product_id)
    {
      axios.post('https://admin.badmintonsouq.com/api/v1/cart/decrement', {
          item_id : product_id,
          cart_id:cart_id
      },
      {
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
          this.CartCount(response.data.data.count)
          this.getCart()
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    incrementCartItem(cart_id,product_id){
      axios.post('https://admin.badmintonsouq.com/api/v1/cart/increment', {
          item_id: product_id,
          cart_id:cart_id
      },
      {
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
        if(response.data.success == true)
        {
          this.CartCount(response.data.data.count)
          this.getCart()
        }
        else{
          this.$toast.show(response.data.message,{
            type: 'error',
            position: 'top-right',
            duration: '2000'
          })
        }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
    removeCartItem(cart_id,product_id){
      axios.post('https://admin.badmintonsouq.com/api/v1/remove-one-product-in-cart', {
          item_id: product_id,
          cart_id:cart_id
      },
      {
        headers: {
            Authorization  : 'Bearer ' + this.token
        }
      })
      .then((response) => {
        if(response.data.success == true)
        {
          this.CartCount(response.data.data.count)
          this.getCart()
        }
        else{
          this.$swal.fire(response.data.message,'',"error")
        }
      })
      .catch(function (error) {
          console.log(error);
      })
    },
  }
})
</script>
