<template>
  <div class="list-page-wrap service-page-wrap">
    <!-- <h1>This is an ProductList</h1> -->
    <div class="breadcrumb" style="background: url('https://picsum.photos/id/1035/1920/400')">
      <div class="container">
        <h1 class="title">{{title}}</h1>
        <!--<p class="text">The racket was developed with the new "triple speed system frame" which combines exceptional power and great handling, and overall improve execution with power and more control. Designed with compact frame, which increases the speed in the swing, and overall improve execution with power and more control.</p>-->
      </div>
    </div>
    <div class="actions-wrap">
      <div class="container">
        <div class="filter-sort-wrap">
          <div class="form-col">
            <label>Sort By</label>
            <select class="form-select" aria-label="Default select example" v-model="select1" v-on:change="getServices(fromPage,'sort_by',this.select1)">
              <option selected>Sort By</option>
              <option value="1">Most Rated</option>
              <option value="2">Near Me</option>
            </select>
          </div>
          <div class="form-col">
            <label>Min Distance</label>
            <input type="number" step="0.01" class="form-control" v-model="min_distance" v-on:change="getServices(fromPage,'min_distance',this.min_distance)">
          </div>
          <div class="form-col">
            <label>Max Distance</label>
            <input type="number" step="0.01" class="form-control" v-model="max_distance" v-on:change="getServices(fromPage,'max_distance',this.max_distance)">
          </div>
          <div class="form-col" v-if="current_path == '/coaching-centres'">
            <label>Type</label>
            <select class="form-select" aria-label="Default select example" v-model="select7" v-on:change="getServices(fromPage,'centre_type',this.select7)">
              <option selected>Type</option>
              <option value="is_training">Courts</option>
              <option value="is_academy">Academy</option>
            </select>
          </div>
          <div class="form-col" v-if="current_path == '/sports-clinics-doctors'">
            <label>Type</label>
            <select class="form-select" aria-label="Default select example" v-model="select2" v-on:change="getServices(fromPage,'clinic_type',this.select2)">
              <option selected>Type</option>
              <option v-for="types in clinic_types" :key="types.id" v-bind:value="types.id">{{types.name}}</option>
            </select>
          </div>
          <div class="form-col" v-if="current_path == '/polling-agents'">
            <label>Level</label>
            <select class="form-select" aria-label="Default select example" v-model="select3" v-on:change="getServices(fromPage,'agent_type',this.select3)">
              <option selected>Level</option>
              <option v-for="types in agent_types" :key="types.id" v-bind:value="types.id">{{types.name}}</option>
            </select>
          </div>
          <!--<div class="form-col" v-if="current_path == '/polling-agents'">
            <select class="form-select" aria-label="Default select example" v-model="select4" v-on:change="getServices(fromPage,'available_agent',this.select4)">
              <option selected>Sort By</option>
              <option value="1">Available</option>
            </select>
          </div>
          <div class="form-col" v-if="current_path == '/polling-agents'">
            <input type="date" class="form-control" aria-label="Default select example" v-model="select5" v-on:change="getServices(fromPage,'expiry_date',this.select5)">
          </div>
          <div class="form-col" v-if="current_path == '/polling-agents'">
            <input type="time" class="form-control" aria-label="Default select example" v-model="select6" v-on:change="getServices(fromPage,'time',this.select6)">
          </div>-->
        </div>
      </div>
    </div>

    <ServiceList :Listdata="ServiceLists"  :loadingdata ="showLoading"/>

    <div class="container mb-5" v-if="ServiceLists.length == 0" style="text-align:center;"><h3>No {{title}} Found</h3></div>
    <div class="container mb-5" v-if="total>1">
      <v-pagination
        v-model="fromPage"
        :pages="total"
        :range-size="1"
        active-color="#DCEDFF"
        @update:modelValue="getServices"
      />
    </div>
    <div class="brand-carousel">
      <!--<brandsCarousel :Listdata="brands"/>-->
    </div>
  </div>
</template>

<script>
// import brandsCarousel from '@/components/brandsCarousel.vue'
import ServiceList from '@/components/ServiceList.vue'
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import axios from 'axios'
// import {mapMutations } from 'vuex';
// import {
//     LOADING_SPINNER_SHOW_MUTATION,
// } from '../store/storeconstants';

export default({
  name: 'ServicetList',
  components: {
      // brandsCarousel,
      ServiceList,
      VPagination
  },
  data (){
    return{
      showLoading: false,
      brands :[],
      ServiceLists :[],
      title:'',
      current_path:'',
      perPage:20,
      fromPage:1,
      total:0,
      select1:'Sort By',
      select2:'Type',
      select3:'Level',
      // select4:'Sort By',
      // select5:'',
      // select6:'',
      select7:'Type',
      is_training:'',
      is_academy:'',
      min_distance:1,
      max_distance:1000,
      most_rated:0,
      agent_types:[],
      clinic_types:[],
      agent_type_id:'',
      clinic_type_id:'',
      // is_available:0,
      // expiry_date:'',
      // time:''
    }
  },
  created(){
    // this.getBrands();
    this.getServices();
    this.getFilters();
  },
  methods:{
    // getBrands(){
    //   axios.post('https://admin.badmintonsouq.com/api/v1/get-partners', {})
    //   .then((response) => {
    //       this.brands = response.data.data.partners
    //   })
    //   .catch(function (error) {
    //       console.log(error);
    //   })
    // },
    // ...mapMutations({
    //     showLoading: LOADING_SPINNER_SHOW_MUTATION,
    // }),
    async getServices(page=1,key,value){
        // this.showLoading(true);
        this.showLoading = true;
        this.current_path = this.$route.fullPath
        if(key == 'sort_by'){
          this.most_rated     = value
          if(value == 'Sort By' || value == '2'){
            this.most_rated   = 0
          }
        }
        if(key == 'clinic_type'){
          this.clinic_type_id    = value
          if(value == 'Type'){
            this.clinic_type_id  = ''
          }
        }
        if(key == 'centre_type'){
          if(value == 'Type'){
            this.is_academy  = ''
            this.is_training  = ''
          }
          else if(value == 'is_training'){
            this.is_academy  = ''
            this.is_training  = 1
          }
          else if(value == 'is_academy'){
            this.is_academy  = 1
            this.is_training  = ''
          }
        }
        if(key == 'agent_type'){
          this.agent_type_id     = value
          if(value == 'Level'){
            this.agent_type_id   = ''
          }
        }
        // if(key == 'available_agent'){
        //   this.is_available      = value
        //   if(value == 'Sort By'){
        //     this.is_available    = ''
        //   }
        // }
        // if(key == 'expiry_date'){
        //   this.expiry_date      = value
        // }
        // if(key == 'time'){
        //   this.time             = value
        // }
        if(this.current_path == '/coaching-centres')
        {
            await axios.post('https://admin.badmintonsouq.com/api/v1/get-coaching-centers', {
              page: page,
              per_page: this.perPage,
              latitude: this.$store.state.auth.latitude,
              longitude: this.$store.state.auth.longitude,
              min_distance: this.min_distance,
              max_distance: this.max_distance,
              most_rated: this.most_rated,
              is_training:this.is_training,
              is_academy:this.is_academy
            })
            .then((response) => {
                this.fromPage     = page;
                this.ServiceLists = response.data.data.centers.centers
                this.total        = response.data.data.centers.total_pages != undefined ? response.data.data.centers.total_pages : 0
                this.title        = 'Courts & Academies'
            })
            .catch(function (error) {
                console.log(error);
                // this.showLoading(false);
                this.showLoading = false;
            })
        }
        else if(this.current_path == '/personal-trainers')
        {
            await axios.post('https://admin.badmintonsouq.com/api/v1/get-personal-trainers', {
              page: page,
              per_page: this.perPage,
              latitude: this.$store.state.auth.latitude,
              longitude: this.$store.state.auth.longitude,
              most_rated: this.most_rated,
              min_distance: this.min_distance,
              max_distance: this.max_distance
            })
            .then((response) => {
                this.fromPage     = page;
                this.ServiceLists = response.data.data.trainers.trainers
                this.total        = response.data.data.trainers.total_pages != undefined ? response.data.data.trainers.total_pages : 0
                this.title        = 'Personal Trainers'
            })
            .catch(function (error) {
                console.log(error);
                // this.showLoading(false);
          this.showLoading = false;
            })
        }
        else if(this.current_path == '/polling-agents')
        {
            await axios.post('https://admin.badmintonsouq.com/api/v1/get-queuing-agents', {
              page: page,
              per_page: this.perPage,
              latitude: this.$store.state.auth.latitude,
              longitude: this.$store.state.auth.longitude,
              agent_type_id: this.agent_type_id,
              // expiry_date: this.expiry_date,
              // is_available: this.is_available,
              min_distance: this.min_distance,
              most_rated: this.most_rated,
              max_distance: this.max_distance,
              // time: this.time
            })
            .then((response) => {
                this.fromPage     = page;
                this.ServiceLists = response.data.data.agents.agents
                this.total        = response.data.data.agents.total_pages != undefined ? response.data.data.agents.total_pages : 0
                this.title        = 'Queuing/Polling'
            })
            .catch(function (error) {
                console.log(error);
                // this.showLoading(false);
          this.showLoading = false;
            })
        }
        else
        {
            await axios.post('https://admin.badmintonsouq.com/api/v1/get-sports-clinics', {
              page: page,
              per_page: this.perPage,
              latitude: this.$store.state.auth.latitude,
              longitude : this.$store.state.auth.longitude,
              min_distance: this.min_distance,
              max_distance: this.max_distance,
              most_rated: this.most_rated,
              clinic_type_id: this.clinic_type_id
            })
            .then((response) => {
                this.fromPage     = page;
                this.ServiceLists = response.data.data.clinics.clinics
                this.total        = response.data.data.clinics.total_pages != undefined ? response.data.data.clinics.total_pages : 0
                this.title        = 'Sports Clinics & Doctors'
            })
            .catch(function (error) {
                console.log(error);
                // this.showLoading(false);
          this.showLoading = false;
            })
        }
        // this.showLoading(false);
          this.showLoading = false;
      },
      getFilters(){
        axios.post('https://admin.badmintonsouq.com/api/v1/get-service-filters', {})
        .then((response) => {
            this.agent_types  = response.data.data.agent_types
            this.clinic_types = response.data.data.clinic_types
        })
        .catch(function (error) {
            console.log(error);
        })
      }
  }
})
</script>
