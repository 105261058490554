<template>
  <div class="login-page">
    <div class="container">
      <div class="login-form mb-3">
        <form @submit.prevent="loginSubmit">
          <p id="error">{{login_error_message}}</p>
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Email Address/Phone</label>
            <input type="text" class="form-control" id="exampleFormControlInput1" v-model="form.phone">
          </div>
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Password</label>
            <input type="password" class="form-control" id="exampleFormControlInput1" v-model="form.password">
          </div>
          <button class="btn btn-primary login-btn" type="submit">Login</button><br>
          <a href="#" class="link">Forgot Password?</a>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: 'Header',
  data (){

    return{
      form:{
        password:'',
        phone: '',
        login_error_message:''
      }
    }
  },
  mounted(){
  },
  methods:{
    ...mapActions(["LogIn"]),
    async loginSubmit() {
      const User = new FormData();
      User.append("phone", this.form.phone);
      User.append("password", this.form.password);
      try {
          await this.LogIn(User);
          if(this.$store.state.auth.token == null)
          {
            this.login_error_message = "Oops!, Please check your credentials"
          }
          else{
            window.location.href= '/'
          }
      } catch (error) {
        console.log(error);
      }
    }
  }
}
</script>

<style>
#error{
  color:red;
}
</style>